import React from "react";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Code from "@material-ui/icons/Code";
import NavbarSearch from "views/organisms/NavbarSearch/NavbarSearch";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import Description from "@material-ui/icons/Description";
import Favorite from "@material-ui/icons/Favorite";
import Group from "@material-ui/icons/Group";
import defaultAvatar from "../../../assets/img/placeholder.jpg";
import classNames from "classnames";
import { connect, useDispatch } from "react-redux";
import { setTokens, setUserData } from "../../../store/authenticationSlice";
import { openGuestCodeDialog } from "../../../store/dialogsSlice";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useHistory } from "react-router-dom";
import Paths from "../../../staticData/Paths";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import libraryPageStyle from "../../ArticlesView/LibraryPageStyle";

const useStyles = makeStyles(headersStyle);
const useLibraryStyles = makeStyles(libraryPageStyle);

function UserMenu({
  authTokens,
  setTokens,
  userData,
  setUserData,
  needsUsernameUpdate,
}) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const history = useHistory();
  const libraryClasses = useLibraryStyles();

  const { profilePicture, userName } = userData;

  if (authTokens) {
    return (
      <List className={classes.list + " " + classes.mlAuto}>
        <NavbarSearch />

        <ListItem className={classes.listItem}>
          <Link to={Paths.explore} className={classes.navLink}>
            Explore
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to={Paths.following} className={classes.navLink}>
            Following
          </Link>
        </ListItem>
        {needsUsernameUpdate && (
          <ListItem className={classes.listItem}>
            <Tooltip
              id="tooltip-top"
              title={"Username needs to be updated"}
              placement="top"
              classes={{ tooltip: libraryClasses.tooltip }}
            >
              <ErrorOutlineIcon style={{ display: "inline", color: "red" }} />
            </Tooltip>
          </ListItem>
        )}
        <ListItem className={classes.listItem}>
          <div>
            <CustomDropdown
              noLiPadding
              navDropdown
              disablePortal
              hoverColor={"primary"}
              buttonText={userName || "User Menu"}
              buttonProps={{
                className: classNames(classes.navLink, classes.userMenu),
                color: "transparent",
              }}
              buttonIcon={() => (
                <img
                  style={{
                    width: "46px",
                    height: "46px",
                    position: "absolute",
                    left: "10px",
                    borderRadius: "50%",
                  }}
                  src={profilePicture || defaultAvatar}
                  alt={`User Menu Icon`}
                />
              )}
              dropdownList={[
                <Link
                  key={"boards"}
                  to={Paths.boards}
                  className={classes.dropdownLink}
                >
                  <DeveloperBoardIcon className={classes.dropdownIcons} />{" "}
                  Boards
                </Link>,
                <Link
                  key={"library"}
                  to={Paths.library}
                  className={classes.dropdownLink}
                >
                  <Description className={classes.dropdownIcons} /> Library
                </Link>,
                <Link
                  key={"favorites"}
                  to={Paths.favorites}
                  className={classes.dropdownLink}
                >
                  <Favorite className={classes.dropdownIcons} /> Favorites
                </Link>,
                <Link
                  key={"connections"}
                  to={Paths.connections.people}
                  className={classes.dropdownLink}
                >
                  <Group className={classes.dropdownIcons} /> Connections
                </Link>,
                <Link
                  key={"profile"}
                  to={Paths.myAccount}
                  className={classes.dropdownLink}
                >
                  <AccountBoxIcon className={classes.dropdownIcons} /> My
                  Account
                </Link>,
                <div
                  key={"guestCode"}
                  className={classes.dropdownLink}
                  onClick={() => dispatch(openGuestCodeDialog())}
                >
                  <Code className={classes.dropdownIcons} /> Guest Code
                </div>,
                <div
                  key={"home"}
                  className={classes.dropdownLink}
                  onClick={() => {
                    setTokens();
                    setUserData({});

                    setTimeout(() => {
                      history.push(Paths.root);
                    }, 0);
                  }}
                >
                  <ExitToAppIcon className={classes.dropdownIcons} /> Logout
                </div>,
              ]}
            />
          </div>
        </ListItem>
      </List>
    );
  }

  return (
    <List className={classes.list + " " + classes.mlAuto}>
      {/*{presentationLink}*/}
      <ListItem className={classes.listItem}>
        <Link to={Paths.login} className={classes.navLink}>
          Login
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to={Paths.register} className={classes.navLink}>
          Register
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <div
          className={classes.navLink}
          style={{ cursor: "pointer" }}
          onClick={() => dispatch(openGuestCodeDialog())}
        >
          Guest Code
        </div>
      </ListItem>
    </List>
  );
}

const mapStateToProps = (state) => ({
  authTokens: state.authentication.authTokens,
  userData: state.authentication.userData,
  needsUsernameUpdate: state.authentication.needsUsernameUpdate,
});

const mapDispatchToProps = { setTokens, setUserData };

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);

UserMenu.propTypes = {
  authTokens: PropTypes.string,
  setTokens: PropTypes.func,
  setUserData: PropTypes.func,
  userData: PropTypes.shape({
    userName: PropTypes.string,
    profilePicture: PropTypes.string,
  }),
  needsUsernameUpdate: PropTypes.bool,
};
