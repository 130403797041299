import React from "react";
import StandardPage from "components/StandardPage/StandardPage";
import NavPills from "../../../components/NavPills/NavPills";
import Description from "@material-ui/icons/Description";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import PublicPostsView from "../../newViews/posts/PublicPostsView/PublicPostsView";
import PublicBoardsView from "../../newViews/boards/PublicBoardsView/PublicBoardsView";
import { Route, Switch } from "react-router-dom";
import Paths from "../../../staticData/Paths";
import Endpoints from "../../../staticData/Endpoints";
import FullCalendar from "../../organisms/FullCalendar/FullCalendar";

export default function ExplorePage() {
  return (
    <StandardPage title={"Explore"}>
      <Switch>
        <Route
          path={Paths.exploreCalendar}
          component={() => (
            <FullCalendar endpoint={Endpoints.exploreCalendar} />
          )}
        />

        <Route
          path={Paths.explore}
          component={() => (
            <NavPills
              alignCenter
              color="primary"
              tabs={[
                {
                  tabButton: "Posts",
                  tabIcon: Description,
                  tabContent: <PublicPostsView />,
                },
                {
                  tabButton: "Boards",
                  tabIcon: DeveloperBoardIcon,
                  tabContent: <PublicBoardsView />,
                },
              ]}
            />
          )}
        />
      </Switch>
    </StandardPage>
  );
}
