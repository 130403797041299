import React from "react";
import StandardPage from "components/StandardPage/StandardPage";
import ConnectionsGroups from "../../organisms/ConnectionsGroups/ConnectionsGroups";
import ConnectionsPeople from "../../organisms/ConnectionsPeople/ConnectionsPeople";
import NavPills from "../../../components/NavPills/NavPills";
import Person from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Paths from "../../../staticData/Paths";
import { useRouteMatch } from "react-router-dom";

export default function ConnectionsPage() {
  const dispatch = useDispatch();

  const isPeopleRoute = useRouteMatch(Paths.connections.people)?.isExact;
  const isGroupsRoute = useRouteMatch(Paths.connections.groups)?.isExact;

  return (
    <StandardPage title={"Connections"}>
      <NavPills
        alignCenter
        color={"primary"}
        active={isPeopleRoute ? 0 : isGroupsRoute ? 1 : 0}
        tabs={[
          {
            tabButton: "People",
            tabIcon: Person,
            tabContent: <ConnectionsPeople />,
            onClick: () => dispatch(push(Paths.connections.people)),
          },
          {
            tabButton: "Groups",
            tabIcon: PeopleIcon,
            tabContent: <ConnectionsGroups />,
            onClick: () => dispatch(push(Paths.connections.groups)),
          },
        ]}
      />
    </StandardPage>
  );
}
