import Button from "../../../../components/CustomButtons/Button";
import Dialog from "../../../../components/Dialog/Dialog";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removePost } from "../../../../store/generalPostsSlice";
import {
  closeRemoveArticleFromBoardDialog,
  dialogsSelectors,
} from "../../../../store/dialogsSlice";
import { useRouteMatch } from "react-router-dom";
import Paths from "../../../../staticData/Paths";
import { removePostFromBoard } from "../../../../store/generalBoardsSlice";

export default function RemovePostFromBoardDialog() {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeRemoveArticleFromBoardDialog());
  };

  const { isOpen } = useSelector(
    dialogsSelectors.getRemoveArticleFromBoardDialogState
  );
  const { id } = useSelector(dialogsSelectors.getContextItem);

  const [isLoading, setIsLoading] = useState(false);
  const boardDetailsRoute = useRouteMatch(Paths.boardDetails);

  const handleSubmit = () => {
    const boardId = boardDetailsRoute.params.id;

    setIsLoading(true);

    dispatch(removePostFromBoard({ postId: id, boardId }))
      .then((result) => {
        if (result.data) {
          setIsLoading(false);
          dispatch(removePost(id));

          handleClose();
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      isOpen={isOpen}
      isLoading={isLoading}
      title={"Remove Post from Board"}
      onClose={handleClose}
      actions={() => (
        <Button
          round
          color="danger"
          style={{ marginLeft: "1rem" }}
          onClick={handleSubmit}
        >
          Remove
        </Button>
      )}
    >
      <br />
      <span>Are you sure you want to remove this post from the board?</span>
      <br />
    </Dialog>
  );
}
