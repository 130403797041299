import React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../../../components/CustomInput/FormikInput";
import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import { useSelector } from "react-redux";
import { otherUserSelectors } from "../../../../store/otherUserSlice";

export default function OtherUserProfileView() {
  const profile = useSelector(otherUserSelectors.profile);

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Formik
            enableReinitialize
            initialValues={{
              firstName: profile.firstName || "",
              lastName: profile.lastName || "",
              userBio: profile.bio || "",
            }}
            validationSchema={Yup.object({
              firstName: Yup.string(),
              lastName: Yup.string(),
              userBio: Yup.string(),
            })}
            onSubmit={() => {}}
          >
            <Form>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <FormikInput
                    disabled
                    id={"profile-firstName-input"}
                    name={"firstName"}
                    type={"text"}
                    placeholder={"First Name"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormikInput
                    disabled
                    id={"profile-lastName-input"}
                    name={"lastName"}
                    type={"text"}
                    placeholder={"Last Name"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormikInput
                    disabled
                    id={"profile-userBio-input"}
                    name={"userBio"}
                    type={"text"}
                    placeholder={"Bio"}
                    multiline
                    rowsMax={10}
                  />
                </GridItem>
              </GridContainer>
            </Form>
          </Formik>
        </GridItem>
      </GridContainer>
    </div>
  );
}
