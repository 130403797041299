import React, { useState } from "react";
import Media from "../../../components/Media/Media";
import defaultAvatar from "../../../assets/img/placeholder.jpg";
import CommentAttachments from "../CommentAttachments/CommentAttachments";
import Button from "../../../components/CustomButtons/Button";
import postCommentsStyles from "../PostComments/PostComments.style";
import Reply from "@material-ui/icons/Reply";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { deleteComment, removeComment } from "../../../store/generalPostsSlice";
import { adminRemoveContent } from "../../../store/generalActions";
import { makeStyles } from "@material-ui/core/styles";
import CommentCreate from "../CommentCreate/CommentCreate";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import ReplyComponent from "../CommentReply/CommentReply";
import CommentEdit from "../CommentEdit/CommentEdit";
import { authenticationSelectors } from "../../../store/authenticationSlice";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";
import Paths from "../../../staticData/Paths";

const useStyles = makeStyles(postCommentsStyles);

function CommentDetails({
  comment,
  deleteComment,
  removeComment,
  userId,
  postId,
  adminRemoveContent,
}) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [showCreateReply, setShowCreateReply] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const userRole = useSelector(authenticationSelectors.userRole);

  const userIsAdmin = userRole === "Admin";
  const userIsSuperAdmin = userRole === "SuperAdmin";

  const { text, email, files, id, replies, user } = comment;

  const handleDeleteComment = (id) => () => deleteComment({ id });

  const handleAdminRemoveComment = (id) => async () => {
    const success = await adminRemoveContent({ contentType: "comment", id });

    if (success) {
      removeComment({ id });
      toastr.success("", "Comment removed successfully.");
    }
  };

  const renderCommentReplies = () => {
    return replies?.map((reply) => {
      return (
        <div key={`${reply.id}`}>
          <hr />
          <ReplyComponent reply={reply} postId={postId} />
        </div>
      );
    });
  };

  const formatAttachmentFiles = (arr) =>
    arr?.map((file) => ({ url: file, type: "file" }));

  const navigateToUserProfile = (event) => {
    event.preventDefault();

    user?.id && dispatch(push(Paths.otherUserAccount.replace(":id", user.id)));
  };

  return (
    <Media
      avatar={user?.profilePicture || defaultAvatar}
      avatarOnClick={navigateToUserProfile}
      title={
        <span
          onClick={navigateToUserProfile}
          style={{
            cursor: user?.id ? "pointer" : "default",
          }}
        >
          {user?.userName || email}
        </span>
      }
      body={
        editMode ? (
          <CommentEdit
            comment={comment}
            closeCommentEdit={() => setEditMode(false)}
          />
        ) : (
          <div>
            <span dangerouslySetInnerHTML={{ __html: text }} />

            <CommentAttachments
              attachments={formatAttachmentFiles(files)}
              displayMode={"view"}
            />

            <div className={classes.actionsContainer}>
              {!!userId && userId === user?.id && (
                <>
                  <Button
                    color="primary"
                    simple
                    onClick={() => setEditMode(true)}
                  >
                    <EditIcon /> Edit
                  </Button>
                  <Button
                    color="primary"
                    simple
                    onClick={handleDeleteComment(id)}
                  >
                    <DeleteForeverIcon /> Delete
                  </Button>
                </>
              )}
              <Button
                color="primary"
                simple
                onClick={() => setShowCreateReply((val) => !val)}
              >
                <Reply /> Reply
              </Button>
              {(userIsAdmin || userIsSuperAdmin) && (
                <Button
                  color="primary"
                  simple
                  onClick={handleAdminRemoveComment(id)}
                >
                  <HighlightOffIcon /> Remove Comment
                </Button>
              )}
            </div>
          </div>
        )
      }
      footer={
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.mlAuto}>
            {showCreateReply && (
              <CommentCreate
                postId={postId}
                parentId={id}
                closeCommentCreate={() => setShowCreateReply(false)}
              />
            )}
            {renderCommentReplies()}
          </GridItem>
        </GridContainer>
      }
    />
  );
}

CommentDetails.propTypes = {
  userId: PropTypes.string,
  postId: PropTypes.number,
  deleteComment: PropTypes.func,
  adminRemoveContent: PropTypes.func,
  removeComment: PropTypes.func,
  isChildComment: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  userId: state.authentication.userData.userId,
});

const mapDispatchToProps = { deleteComment, adminRemoveContent, removeComment };

export default connect(mapStateToProps, mapDispatchToProps)(CommentDetails);
