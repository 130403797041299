import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import libraryPageStyle from "../../../Library/LibraryPageStyle";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import { postValidationSchema } from "../../../../utils/PostValidationSchema";
import { Form, Formik } from "formik";
import FormikSelect from "../../../../components/CustomInput/FormikSelect";
import { connect, useDispatch, useSelector } from "react-redux";
import PostAttachments from "../../../organisms/PostAttachments/PostAttachments";
import {
  closeEditArticleDialog,
  dialogsSelectors,
} from "../../../../store/dialogsSlice";
import {
  endChallenge,
  getPostDetails,
  getTagSuggestions,
  editPost,
  setPostDetails as setPostDetailsInStore,
  editAndNotifyPost,
} from "../../../../store/generalPostsSlice";
import Loading from "../../../../components/Loading/Loading";
import { validateEditorTextLength } from "../../../../utils/editor";
import { toastr } from "react-redux-toastr";
import { postTypes, postPrivacyTypes } from "staticData/Posts";
import { postIsEvent, postIsQuestion } from "utils/posts";
import moment from "moment";
import FormikInput from "../../../../components/CustomInput/FormikInput";
import FormikDateTime from "../../../../components/CustomInput/FormikDateTime";
import FormikLocationSearch from "../../../../components/CustomInput/FormikGoogleLocationSearch";
import { replace } from "connected-react-router";
import Paths from "../../../../staticData/Paths";
import { useRouteMatch, useLocation } from "react-router-dom";

const useStyles = makeStyles(libraryPageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

function EditPostDialog({ closeEditArticleDialog, contextItem }) {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(dialogsSelectors.getEditPostDialogState);

  const classes = useStyles();
  const { id: contextItemId } = contextItem;

  const isPostDetailsRoute = useRouteMatch(Paths.postDetails)?.isExact;
  const location = useLocation();

  const [tags, setTags] = useState([]);
  const [postDetails, setPostDetails] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isLoading, setIsLoading] = useState(false);

  const createEditorInitialState = useCallback((html) => {
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    return EditorState.createWithContent(contentState);
  }, []);

  const filesConvertingInProgress = useMemo(() => {
    return attachments
      ?.filter((file) => typeof file === "object" && file.conversionInProgress)
      .map((file) => file.url);
  }, [attachments]);

  const hasProcessingUploads = filesConvertingInProgress?.length > 0;

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);

      dispatch(getTagSuggestions()).then((data) => {
        setIsLoading(false);
        setTags(data);
      });
    }
  }, [isOpen, dispatch]);

  useEffect(() => {
    if (contextItemId && isOpen) {
      setIsLoading(true);

      dispatch(getPostDetails(contextItemId)).then((data) => {
        setIsLoading(false);

        setPostDetails(data);
        setEditorState(createEditorInitialState(data.body));
        setAttachments(data.attachments);
      });
    }
  }, [dispatch, contextItemId, createEditorInitialState, isOpen]);

  const getTagOptions = (tags) =>
    tags.map((tag) => ({ text: tag, value: tag.slice(1), url: "#" }));

  async function handleEditPost(formValues) {
    setIsLoading(true);

    const editorHasContent = validateEditorTextLength({
      state: editorState,
      minLength: 1,
    });

    if (!editorHasContent) {
      toastr.error("", "Post content can not be empty");
      return setIsLoading(false);
    }

    let success;

    if (saveWithNotify) {
      success = await dispatch(
        editAndNotifyPost({ formValues, attachments, editorState })
      );
    } else {
      success = await dispatch(
        editPost({ formValues, attachments, editorState })
      );
    }

    setSaveWithNotify(false);
    setIsLoading(false);

    if (success) {
      closeEditArticleDialog();

      if (!isPostDetailsRoute) {
        dispatch(replace(`${location.pathname}${location.search}`));
      } else {
        dispatch(getPostDetails(contextItemId)).then((data) => {
          dispatch(setPostDetailsInStore(data?.singleArticleResponse || data));
        });
      }
    }
  }

  const cancelEditPost = () => {
    closeEditArticleDialog();
  };

  const getStateWithMaxLengthEnsured = (newState, maxLength) => {
    const contentState = newState.getCurrentContent();
    const oldContent = editorState.getCurrentContent();
    if (
      contentState === oldContent ||
      contentState.getPlainText().length <= maxLength
    ) {
      return newState;
    } else {
      return EditorState.undo(
        EditorState.push(
          editorState,
          ContentState.createFromText(oldContent.getPlainText()),
          "delete-character"
        )
      );
    }
  };

  const onEditorStateChange = (postType) => (editorState) => {
    if (postIsQuestion(postType)) {
      setEditorState(getStateWithMaxLengthEnsured(editorState, 300));
    } else {
      setEditorState(editorState);
    }
  };

  const [saveWithNotify, setSaveWithNotify] = useState(false);

  return (
    <Dialog
      classes={{ root: classes.modalRoot, paper: classes.modal }}
      open={isOpen}
      fullWidth
      TransitionComponent={Transition}
      onClose={() => {}}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <Loading isLoading={isLoading}>
        <div className={classes.container}>
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={cancelEditPost}
              disabled={hasProcessingUploads}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h3
              className={classes.modalTitle}
              style={{
                fontWeight: "bold",
              }}
            >
              Edit Post
            </h3>
            <hr />
          </DialogTitle>
          <Formik
            enableReinitialize
            initialValues={{
              isPrivate: postDetails.private ? "true" : "false" || "",
              type: postDetails.type || "",
              eventTitle: postDetails.eventData?.title || "",
              location: {
                address: postDetails.eventData?.address || "",
                latitude: postDetails.eventData?.latitude || null,
                longitude: postDetails.eventData?.longitude || null,
              },
              startTime:
                (postDetails.eventData?.startDate &&
                  moment(`${postDetails.eventData?.startDate}Z`).local()) ||
                "",
              endTime:
                (postDetails.eventData?.endDate &&
                  moment(`${postDetails.eventData?.endDate}Z`).local()) ||
                "",
              phoneNumber: postDetails.eventData?.phoneNumber || "",
              webinarLink: postDetails.eventData?.webinarLink || "",
            }}
            validationSchema={postValidationSchema}
            onSubmit={handleEditPost}
          >
            {(props) => (
              <Form>
                <DialogContent>
                  <GridContainer justify="center">
                    <GridItem sm={12} md={6}>
                      <FormikSelect
                        id={"editPost-isPrivate-select"}
                        name={"isPrivate"}
                        placeholder={"Visibility"}
                        options={postPrivacyTypes}
                        disabled
                      />
                    </GridItem>
                    <GridItem sm={12} md={6}>
                      <FormikSelect
                        id={"editPost-type-select"}
                        name={"type"}
                        placeholder={"Type"}
                        options={postTypes}
                        disabled
                      />
                    </GridItem>
                    {postIsEvent(props.values.type) && (
                      <>
                        <GridItem>
                          <FormikInput
                            id={"editPost-title-input"}
                            name={"eventTitle"}
                            type={"text"}
                            labelText="Event Title"
                            maxLength={100}
                            autoComplete={"off"}
                          />
                        </GridItem>
                        <GridItem sm={12} md={6}>
                          <FormikDateTime
                            id={"editPost-startTime-picker"}
                            name={"startTime"}
                            placeholder={"Start Time"}
                          />
                        </GridItem>
                        <GridItem sm={12} md={6}>
                          <FormikDateTime
                            id={"editPost-endTime-picker"}
                            name={"endTime"}
                            placeholder={"End Time"}
                          />
                        </GridItem>
                        <GridItem sm={12} md={6}>
                          <FormikInput
                            id={"editPost-phoneNumber-picker"}
                            name={"phoneNumber"}
                            type={"text"}
                            labelText="Phone Number"
                            maxLength={100}
                            autoComplete={"off"}
                          />
                        </GridItem>
                        <GridItem sm={12} md={6}>
                          <FormikInput
                            id={"editPost-webinarLink-input"}
                            name={"webinarLink"}
                            type={"text"}
                            labelText="Webinar Link"
                            maxLength={100}
                            autoComplete={"off"}
                          />
                        </GridItem>
                        <GridItem>
                          <FormikLocationSearch
                            id={"editPost-location-input"}
                            name={"location"}
                          />
                        </GridItem>
                      </>
                    )}
                  </GridContainer>
                  <Editor
                    editorState={editorState}
                    stripPastedStyles
                    toolbarStyle={{ backgroundColor: "#eeeeee" }}
                    onEditorStateChange={onEditorStateChange(props.values.type)}
                    wrapperClassName={classes.wrapperClassName}
                    editorClassName={classes.editorClassName}
                    editorStyle={
                      postIsQuestion(props.values.type)
                        ? { minHeight: "15vh" }
                        : {}
                    }
                    toolbar={{
                      options: ["inline", "list"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered", "ordered"],
                      },
                    }}
                    mention={{
                      separator: " ",
                      trigger: "#",
                      suggestions: getTagOptions(tags),
                    }}
                    hashtag={{
                      separator: " ",
                      trigger: "#",
                    }}
                  />
                  <br />
                  <PostAttachments
                    attachments={attachments}
                    setAttachments={setAttachments}
                    displayMode={"edit"}
                  />
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                  <GridContainer justify={"center"}>
                    <GridItem xs={12}>
                      <div>
                        <Button
                          round
                          onClick={cancelEditPost}
                          color="primary"
                          style={{
                            margin: "1rem 0 0 1rem",
                          }}
                          disabled={hasProcessingUploads}
                        >
                          Cancel
                        </Button>
                        <Button
                          round
                          type={"submit"}
                          color="success"
                          style={{
                            margin: "1rem 0 0 1rem",
                          }}
                          disabled={hasProcessingUploads}
                        >
                          Submit
                        </Button>
                        <Button
                          round
                          type={"submit"}
                          onClick={() => setSaveWithNotify(true)}
                          color="success"
                          style={{
                            margin: "1rem 0 0 1rem",
                          }}
                          disabled={hasProcessingUploads}
                        >
                          Submit Notify
                        </Button>
                      </div>
                    </GridItem>
                  </GridContainer>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </div>
      </Loading>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  contextItem: state.dialogs.contextItem,
  dialogState: state.dialogs.editArticleDialog,
});

const mapDispatchToProps = {
  closeEditArticleDialog,
  endChallenge,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPostDialog);
