import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authenticationSelectors } from "store/authenticationSlice";
import { toastr } from "react-redux-toastr";
import Paths from "staticData/Paths";
import { push } from "connected-react-router";
import {
  setContextBoard,
  openShareBoardDialog,
  openEditBoardDialog,
  openDeleteBoardDialog,
  openUnfollowBoardDialog,
} from "store/dialogsSlice";
import { adminRemoveContent } from "store/generalActions";
import { removeBoard } from "../../store/generalBoardsSlice";
import BoardCard from "../../views/organisms/BoardCard/BoardCard";

export const boardActionTypes = {
  shareBoard: "Share",
  editBoard: "Edit",
  deleteBoard: "Delete",
  unfollowBoard: "Unfollow",
  removeContent: "Remove Content",
};

export const generalBoardActionTypes = [
  boardActionTypes.shareBoard,
  boardActionTypes.editBoard,
  boardActionTypes.deleteBoard,
];

export function useBoardCard(options = []) {
  const dispatch = useDispatch();

  const authTokens = useSelector(authenticationSelectors.authTokens);
  const { userId } = useSelector(authenticationSelectors.userData);
  const userRole = useSelector(authenticationSelectors.userRole);

  const userIsAdmin = userRole === "Admin";
  const userIsSuperAdmin = userRole === "SuperAdmin";

  const getOptionsList = useCallback(
    (elem) => {
      let opts = [];
      const currentUserIsOwner = elem.user?.id === userId;

      if (options) {
        opts = opts.concat(options);
      }

      if (!currentUserIsOwner) {
        opts = opts.filter(
          (x) =>
            ![
              boardActionTypes.deleteBoard,
              boardActionTypes.editBoard,
            ].includes(x)
        );
      }

      if (userIsAdmin || userIsSuperAdmin) {
        opts.push(boardActionTypes.removeContent);
      }

      return opts;
    },
    [userId, userIsAdmin, userIsSuperAdmin, options]
  );

  const handleAdminRemoveContent = async (id) => {
    const success = dispatch(adminRemoveContent({ contentType: "board", id }));

    if (success) {
      dispatch(removeBoard(id));
      toastr.success("", "Board was successfully removed.");
    }
  };

  const handleActions = ({ elem }) => ({ option, event }) => {
    event.stopPropagation();

    if (!authTokens) {
      dispatch(push(Paths.login));
      return;
    }

    dispatch(setContextBoard(elem));

    switch (option) {
      case boardActionTypes.shareBoard: {
        dispatch(openShareBoardDialog());
        break;
      }
      case boardActionTypes.editBoard: {
        dispatch(openEditBoardDialog());
        break;
      }
      case boardActionTypes.deleteBoard: {
        dispatch(openDeleteBoardDialog());
        break;
      }
      case boardActionTypes.unfollowBoard: {
        dispatch(openUnfollowBoardDialog());
        break;
      }
      case boardActionTypes.removeContent: {
        handleAdminRemoveContent(elem.id);
        break;
      }
      default: {
        console.log(option);
      }
    }
  };

  const boardCardRenderer = (elem) => {
    return (
      <BoardCard
        key={elem.id}
        board={elem}
        handleActions={handleActions({ elem })}
        optionsList={getOptionsList(elem)}
      />
    );
  };

  return { getOptionsList, handleActions, boardCardRenderer };
}
