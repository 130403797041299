import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import libraryPageStyle from "../../../ArticlesView/LibraryPageStyle";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { getOtherUserPosts } from "store/generalPostsSlice";
import SizedStackGrid from "components/SizedStackGrid/SizedStackGrid";
import {
  generalPostActionTypes,
  usePostCard,
} from "utils/hooks/postCardOptions";
import { useRouteMatch } from "react-router-dom";
import Paths from "../../../../staticData/Paths";
import { useInfiniteLoading } from "../../../../utils/hooks/infiniteLoading";
import { routerSelectors } from "../../../../store/routerActions";
import {
  clearOtherUserPosts,
  otherUserSelectors,
} from "../../../../store/otherUserSlice";

const useStyles = makeStyles(libraryPageStyle);

export default function OtherUserPostsView() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const locationKey = useSelector(routerSelectors.getLocationKey);
  const posts = useSelector(otherUserSelectors.posts);

  const userIdFromUrl = useRouteMatch(Paths.otherUserAccount)?.params.id;

  const apiCall = useCallback(
    (data) => getOtherUserPosts({ ...data, userIdFromUrl }),
    [userIdFromUrl]
  );

  const {
    bottomBoundaryNode,
    resetPagination,
    cancelOngoingCall,
  } = useInfiniteLoading({ apiCall });

  useEffect(() => {
    return () => {
      resetPagination();
      cancelOngoingCall();
      dispatch(clearOtherUserPosts());
    };
  }, [
    dispatch,
    resetPagination,
    cancelOngoingCall,
    userIdFromUrl,
    locationKey,
  ]);

  const { postCardRenderer } = usePostCard(generalPostActionTypes);

  return (
    <div className={classes.container} style={{ paddingTop: "30px" }}>
      <SizedStackGrid>{posts.map(postCardRenderer)}</SizedStackGrid>
      {bottomBoundaryNode}
    </div>
  );
}
