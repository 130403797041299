import React, { createRef, useCallback, useEffect, useState } from "react";
import Dialog from "../../../../components/Dialog/Dialog";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Button from "../../../../components/CustomButtons/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  dialogsSelectors,
  closeAddUsersToGroupDialog,
} from "../../../../store/dialogsSlice";
import {
  addUsersToGroup,
  getPeopleForGroups,
} from "../../../../store/connectionsSlice";
import FormikUsersMultiselect from "../../../../components/CustomInput/FormikUsersMultiselect";

function AddUsersToGroupDialog() {
  const dispatch = useDispatch();
  const formRef = createRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [usersOptions, setUsersOptions] = React.useState([]);
  const {
    isOpen,
    data: { id },
  } = useSelector(dialogsSelectors.getAddUsersToGroupDialogState);

  const handleClose = useCallback(() => {
    dispatch(closeAddUsersToGroupDialog());
  }, [dispatch]);

  useEffect(() => {
    if (!isOpen) {
      setUsersOptions([]);
    }
  }, [isOpen]);

  async function handleAddUsersToGroup(values) {
    setIsLoading(true);

    const usersId = values.users.map((x) => x.id);

    const success = await dispatch(addUsersToGroup({ usersId, id }));
    setIsLoading(false);

    if (success) {
      handleClose();
    }
  }

  async function handleUsersInputChange(event, value, reason) {
    if (value.length && reason === "input") {
      setIsFetchingUsers(true);

      const data = await dispatch(
        getPeopleForGroups({
          groupId: id,
          value: value.trim().replace(" ", "&nbsp;"),
        })
      );

      setIsFetchingUsers(false);

      if (data) {
        setUsersOptions(data);
      }
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      isLoading={isLoading}
      title={"Add Users to Group"}
      actions={() => (
        <Button
          round
          color="primary"
          style={{ marginLeft: "1rem" }}
          onClick={() => formRef.current.submitForm()}
        >
          Add Users
        </Button>
      )}
      onClose={handleClose}
    >
      <Formik
        innerRef={formRef}
        initialValues={{ users: [] }}
        validationSchema={Yup.object({
          users: Yup.array(Yup.object()).required(
            "Please select at least one user"
          ),
        })}
        onSubmit={handleAddUsersToGroup}
      >
        <Form>
          <GridContainer justify="center">
            <GridItem sm={12}>
              <FormikUsersMultiselect
                id={"addToGroup-users-multiselect"}
                name={"users"}
                placeholder={"Users"}
                handleInputChange={handleUsersInputChange}
                options={usersOptions}
                isLoading={isFetchingUsers}
              />
            </GridItem>
          </GridContainer>
        </Form>
      </Formik>
    </Dialog>
  );
}

export default AddUsersToGroupDialog;
