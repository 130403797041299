import React from "react";
import StandardPage from "components/StandardPage/StandardPage";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";
import errorPageStyle from "../../../assets/jss/material-kit-pro-react/views/errorPageStyles";
import { primaryColor } from "assets/jss/material-kit-pro-react.js";

const useStyles = makeStyles(errorPageStyle);

export default function NotFoundPage() {
  const classes = useStyles();

  return (
    <StandardPage>
      <div className={classes.contentCenter}>
        <GridContainer>
          <GridItem md={12}>
            <h1 className={classes.title} style={{ color: primaryColor[0] }}>
              404
            </h1>
            <h2 className={classes.subTitle} style={{ color: primaryColor[0] }}>
              Page not found :(
            </h2>
            <h4
              className={classes.description}
              style={{ color: primaryColor[0] }}
            >
              Ooooups! Looks like you got lost.
            </h4>
          </GridItem>
        </GridContainer>
      </div>
    </StandardPage>
  );
}
