import React from "react";
import StandardPage from "components/StandardPage/StandardPage";
import NavPills from "../../../components/NavPills/NavPills";
import Description from "@material-ui/icons/Description";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import PublicPostsView from "../../newViews/posts/PublicPostsView/PublicPostsView";
import PublicBoardsView from "../../newViews/boards/PublicBoardsView/PublicBoardsView";

export default function HomePage() {
  return (
    <StandardPage title={"Explore"}>
      <NavPills
        alignCenter
        color="primary"
        tabs={[
          {
            tabButton: "Posts",
            tabIcon: Description,
            tabContent: <PublicPostsView />,
          },
          {
            tabButton: "Boards",
            tabIcon: DeveloperBoardIcon,
            tabContent: <PublicBoardsView />,
          },
        ]}
      />
    </StandardPage>
  );
}
