import React, { useState } from "react";
import Button from "../../../../components/CustomButtons/Button";
import Dialog from "../../../../components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { getBoardDetails, unfollowBoard } from "../../../../store/generalBoardsSlice";
import {
  closeUnfollowBoardDialog,
  dialogsSelectors,
} from "../../../../store/dialogsSlice";
import { removeFollowingBoard } from "../../../../store/followingSlice";

export default function UnfollowBoardDialog() {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(dialogsSelectors.getUnfollowBoardDialogState);
  const { id } = useSelector(dialogsSelectors.getContextBoard);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => dispatch(closeUnfollowBoardDialog());

  const handleUnfollowBoard = async () => {
    setIsLoading(true);
    const success = await dispatch(unfollowBoard({ id }));
    setIsLoading(false);

    if (success) {
      handleClose();

      dispatch(getBoardDetails({ id }));
      dispatch(removeFollowingBoard(id));
    }
  };

  return (
    <Dialog
      title={"Confirm Unfollow"}
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={handleClose}
      actions={() => (
        <Button
          round
          color="success"
          style={{ marginLeft: "1rem" }}
          onClick={handleUnfollowBoard}
        >
          Confirm
        </Button>
      )}
    >
      <p>Are you sure you want to unfollow this board?</p>
    </Dialog>
  );
}
