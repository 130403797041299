import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { authenticationSelectors } from "../../store/authenticationSlice";

export default function PrivateRoute({ component: Component, ...rest }) {
  const authTokens = useSelector(authenticationSelectors.authTokens);

  return (
    <Route
      {...rest}
      render={(props) =>
        authTokens ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}
