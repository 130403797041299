import React, { useState, useEffect, useCallback } from "react";
import { Editor } from "react-draft-wysiwyg";
import CommentAttachments from "../CommentAttachments/CommentAttachments";
import Button from "../../../components/CustomButtons/Button";
import Reply from "@material-ui/icons/Reply";
import Close from "@material-ui/icons/Close";
import Media from "../../../components/Media/Media";
import { makeStyles } from "@material-ui/core/styles";
import postCommentsStyles from "../PostComments/PostComments.style";
import PropTypes from "prop-types";
import { ContentState, EditorState } from "draft-js";
import { editComment } from "../../../store/generalPostsSlice";
import { connect } from "react-redux";
import htmlToDraft from "html-to-draftjs";
import Loading from "../../../components/Loading/Loading";
import { useEditorState } from "utils/editor";

const useStyles = makeStyles(postCommentsStyles);

function CommentEdit({ editComment, closeCommentEdit, comment }) {
  const classes = useStyles();
  const { id } = comment;
  const {
    tagOptions,
    handleEditorChange,
    editorState,
    setEditorState,
    clearEditor,
  } = useEditorState();
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formatAttachmentFiles = (arr) => arr?.map((file) => ({ url: file }));

  const handleEditComment = (editorState) => async () => {
    setIsLoading(true);

    const success = await editComment({
      editorState,
      id,
      attachments,
    });

    setIsLoading(false);

    if (success === true) {
      setAttachments([]);
      clearEditor();
      closeCommentEdit();
    }
  };

  const createEditorInitialState = useCallback((html) => {
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    return EditorState.createWithContent(contentState);
  }, []);

  useEffect(() => {
    setEditorState(createEditorInitialState(comment.text));
    setAttachments(formatAttachmentFiles(comment.files));
  }, [comment, setEditorState, createEditorInitialState]);

  return (
    <Loading isLoading={isLoading}>
      <Media
        body={
          <div>
            <Editor
              editorState={editorState}
              stripPastedStyles
              toolbarHidden
              onEditorStateChange={handleEditorChange}
              editorStyle={{
                minHeight: "25vh",
                border: `1px solid rgba(0,0,0,0.1)`,
                padding: `5px`,
              }}
              toolbar={{}}
              mention={{
                separator: " ",
                trigger: "#",
                suggestions: tagOptions,
              }}
              hashtag={{
                separator: " ",
                trigger: "#",
              }}
            />
            <CommentAttachments
              attachments={attachments}
              setAttachments={setAttachments}
              displayMode={"edit"}
            />
          </div>
        }
        footer={
          <div className={classes.actionsContainer}>
            <Button color="primary" onClick={() => closeCommentEdit()}>
              <Close /> Cancel
            </Button>
            <Button color="primary" onClick={handleEditComment(editorState)}>
              <Reply /> Submit
            </Button>
          </div>
        }
      />
    </Loading>
  );
}

CommentEdit.propTypes = {
  editComment: PropTypes.func,
  closeCommentEdit: PropTypes.func,
};

CommentEdit.defaultProps = {
  closeCommentEdit: () => {},
};

const mapDispatchToProps = { editComment };

export default connect(null, mapDispatchToProps)(CommentEdit);
