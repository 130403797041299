import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { routerSelectors } from "../../store/routerActions";

export function useInfiniteLoading({ apiCall }) {
  const dispatch = useDispatch();

  const bottomBoundaryRef = useRef(null);
  const locationKey = useSelector(routerSelectors.getLocationKey);

  const [loadMoreItems, setLoadMoreItems] = React.useState(true);
  const [lastUpdatedOn, setLastUpdatedOn] = React.useState(null);
  const [isFetching, setIsFetching] = React.useState(false);
  const [areMore, setAreMore] = React.useState(true);

  const cancelToken = useMemo(() => {
    console.log(locationKey);

    return axios.CancelToken.source();
  }, [locationKey]);

  const scrollObserver = useCallback(
    (node) => {
      new IntersectionObserver((entries) => {
        entries.forEach((en) => {
          if (en.intersectionRatio > 0 && !isFetching) {
            setLoadMoreItems(true);
          }
        });
      }).observe(node);
    },
    [isFetching]
  );

  const resetPagination = useCallback(() => {
    setLastUpdatedOn(null);
    setLoadMoreItems(true);
    setAreMore(true);
  }, []);

  const cancelOngoingCall = useCallback(() => {
    cancelToken.cancel();
  }, [cancelToken]);

  const bottomBoundaryNode = (
    <div
      ref={bottomBoundaryRef}
      style={{ height: "6rem", textAlign: "center", padding: "2rem" }}
    >
      {loadMoreItems && areMore ? "Loading more items. Please wait." : ""}
      {!areMore ? "All items are loaded." : ""}
    </div>
  );

  useEffect(() => {
    if (bottomBoundaryRef.current) {
      scrollObserver(bottomBoundaryRef.current);
    }
  }, [scrollObserver, bottomBoundaryRef]);

  useEffect(() => {
    if (loadMoreItems && areMore) {
      (async () => {
        setIsFetching(true);

        const response = await dispatch(
          apiCall({ lastUpdatedOn, cancelToken })
        );

        setIsFetching(false);

        setLoadMoreItems(false);

        if (
          typeof response === "object" &&
          response !== null &&
          response !== undefined
        ) {
          const { lastUpdatedOn, areMore } = response;

          setAreMore(areMore);
          setLastUpdatedOn(lastUpdatedOn);
        } else {
          setLastUpdatedOn(response);
        }
      })();
    }
  }, [lastUpdatedOn, loadMoreItems, dispatch, apiCall, cancelToken, areMore]);

  return {
    isFetching,
    bottomBoundaryNode,
    setLoadMoreItems,
    setLastUpdatedOn,
    setLastLoadedItem: setLastUpdatedOn,
    resetPagination,
    cancelOngoingCall,
  };
}
