import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import classNames from "classnames";
import { MoreVert } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlined from "@material-ui/icons/LockOutlined";
import libraryPageStyle from "../../Library/LibraryPageStyle";
import PostType from "../../molecules/PostType/PostType";
import PostAttachments from "../../organisms/PostAttachments/PostAttachments";
import PostComments from "../../organisms/PostComments/PostComments";
import CardBody from "components/Card/CardBody";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Card from "components/Card/Card";
import defaultAvatar from "assets/img/placeholder.jpg";
import {
  getPostDetails,
  setPostDetails,
  generalPostsSelectors,
} from "../../../store/generalPostsSlice";
import PostAttachmentsCarousel from "../../organisms/PostAttachmentsCarousel/PostAttachmentsCarousel";
import EventDetails from "../../molecules/EventDetails/EventDetails";
import Paths from "../../../staticData/Paths";
import {
  generalPostActionTypes,
  usePostCard,
} from "../../../utils/hooks/postCardOptions";

import { container } from "assets/jss/material-kit-pro-react.js";

const useStyles = makeStyles((theme) => ({
  ...libraryPageStyle(theme),
  container: {
    paddingTop: "30px",
    ...container,
  },
}));

export default function PostDetailsPage() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const postDetails = useSelector(generalPostsSelectors.postDetails);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getPostDetails(id)).then((data) => {
      dispatch(setPostDetails(data?.singleArticleResponse || data));
    });
  }, [dispatch, id]);

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluidView,
    classes.imgPosition
  );

  const createdDateTime = new Date(`${postDetails?.createdOn}Z`).toLocaleString(
    "en-GB",
    {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }
  );

  const { handleActions, getOptionsList } = usePostCard(
    generalPostActionTypes,
    true
  );
  const handleActionsByDetails = handleActions({ post: postDetails });

  return (
    <div className={classes.container}>
      <Card blog>
        <CardBody>
          <div
            style={{
              display: "flex",
              padding: "0.5rem 0",
            }}
          >
            <div style={{ display: "flex", flexShrink: 0 }}>
              <Link
                to={Paths.otherUserAccount.replace(
                  ":id",
                  postDetails?.user?.id
                )}
              >
                <img
                  src={postDetails?.user?.profilePicture || defaultAvatar}
                  alt="User profile"
                  className={imageClasses}
                />
              </Link>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <h4
                className={classes.title}
                style={{
                  cursor: "pointer",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
                onClick={() =>
                  dispatch(
                    push(
                      Paths.otherUserAccount.replace(
                        ":id",
                        postDetails?.user?.id
                      )
                    )
                  )
                }
              >
                {postDetails?.user?.userName}
              </h4>

              <div style={{ display: "flex", height: "20px" }}>
                <PostType postData={postDetails} />

                {postDetails?.private && (
                  <LockOutlined
                    fontSize={"small"}
                    style={{ marginRight: "0.3rem" }}
                  />
                )}

                <span style={{ height: "20px", marginLeft: "5px" }}>
                  {createdDateTime}
                </span>
              </div>
            </div>

            <div
              style={{
                position: "relative",
                top: "-10px",
                justifyContent: "flex-end",
                display: "flex",
                flex: "1",
              }}
            >
              <CustomDropdown
                caret={false}
                hoverColor="primary"
                buttonText={<MoreVert />}
                onClick={(option) => {
                  handleActionsByDetails({ option });
                }}
                dropPlacement={"bottom-end"}
                buttonProps={{
                  className: classes.imageDropdownButton,
                  color: "transparent",
                  justIcon: true,
                }}
                dropdownList={getOptionsList(postDetails)}
              />
            </div>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {postDetails?.eventData && (
              <EventDetails eventData={postDetails?.eventData} />
            )}

            <div
              dangerouslySetInnerHTML={{
                __html: postDetails?.body,
              }}
            />

            <PostAttachmentsCarousel
              attachments={postDetails?.attachments}
              previewOnly
            />

            {postDetails?.attachments?.length > 0 && (
              <div>
                <hr />
                <PostAttachments
                  attachments={postDetails?.attachments}
                  displayMode={"view"}
                />
              </div>
            )}
          </div>
          <hr />
          <PostComments />
        </CardBody>
      </Card>
    </div>
  );
}
