import React, { useState } from "react";
import CustomDropdown from "../../../../components/CustomDropdown/CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import { authenticationSelectors } from "../../../../store/authenticationSlice";
import Button from "../../../../components/CustomButtons/Button";
import Dialog from "../../../../components/Dialog/Dialog";
import {
  addToAdmin,
  addToPremium,
  followUser,
  removeFromAdmin,
  removeFromPremium,
  unfollowUser,
} from "../../../../store/followingSlice";
import { useRouteMatch } from "react-router-dom";
import Paths from "../../../../staticData/Paths";
import { makeStyles } from "@material-ui/core/styles";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getOtherUserProfile,
  otherUserSelectors,
} from "../../../../store/otherUserSlice";

export const adminOptionsTypes = [
  { id: "follow", name: "Follow" },
  { id: "unfollow", name: "Unfollow" },
  { id: "addPremium", name: "Add to Premium" },
  { id: "removePremium", name: "Remove from Premium" },
];

export const superAdminOptionsTypes = [
  { id: "follow", name: "Follow" },
  { id: "unfollow", name: "Unfollow" },
  { id: "addPremium", name: "Add to Premium" },
  { id: "removePremium", name: "Remove from Premium" },
  { id: "addAdmin", name: "Add to Admin" },
  { id: "removeAdmin", name: "Remove from Admin" },
];

const useStyles = makeStyles(profilePageStyle);

export default function OtherUserOptions() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const profile = useSelector(otherUserSelectors.profile);

  const loggedUserData = useSelector(authenticationSelectors.userData);
  const loggedUserRole = useSelector(authenticationSelectors.userRole);

  const displayedUserIsFollowed = profile.isFollowing === true;
  const displayedUserIsPremium = profile.role === "Premium";
  const displayedUserIsAdmin = profile.role === "Admin";

  const userIsAdmin = loggedUserRole === "Admin";
  const userIsSuperAdmin = loggedUserRole === "SuperAdmin";

  const userId = useRouteMatch(Paths.otherUserAccount).params?.id;

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const refreshOtherUserProfile = () => {
    dispatch(getOtherUserProfile({ userId }));
  };

  const handleFollow = async () => {
    setIsLoading(true);
    const success = await dispatch(followUser({ userId }));
    setIsLoading(false);

    if (success) {
      refreshOtherUserProfile();
    }
  };

  const handleConfirmUnfollow = () => {
    setIsConfirmOpen(true);
  };

  const handleUnfollow = async () => {
    setIsLoading(true);
    const success = await dispatch(unfollowUser({ userId }));
    setIsLoading(false);

    if (success) {
      refreshOtherUserProfile();
      setIsConfirmOpen(false);
    }
  };

  const makeUserPremium = async () => {
    setIsLoading(true);
    const success = await dispatch(addToPremium({ userId }));
    setIsLoading(false);

    if (success) {
      refreshOtherUserProfile();
    }
  };

  const removeUserPremium = async () => {
    setIsLoading(true);
    const success = await dispatch(removeFromPremium({ userId }));
    setIsLoading(false);

    if (success) {
      refreshOtherUserProfile();
    }
  };

  const makeUserAdmin = async () => {
    setIsLoading(true);
    const success = await dispatch(addToAdmin({ userId }));
    setIsLoading(false);

    if (success) {
      refreshOtherUserProfile();
    }
  };

  const removeUserAdmin = async () => {
    setIsLoading(true);
    const success = await dispatch(removeFromAdmin({ userId }));
    setIsLoading(false);

    if (success) {
      refreshOtherUserProfile();
    }
  };

  const handleUserOptionSelect = (option) => {
    switch (option?.id) {
      case "follow": {
        handleFollow();
        break;
      }
      case "unfollow": {
        handleConfirmUnfollow();
        break;
      }
      case "addPremium": {
        makeUserPremium();
        break;
      }
      case "removePremium": {
        removeUserPremium();
        break;
      }
      case "addAdmin": {
        makeUserAdmin();
        break;
      }
      case "removeAdmin": {
        removeUserAdmin();
        break;
      }
      default: {
        console.log(option);
      }
    }
  };

  const dropdownOptions = () => {
    if (userIsAdmin) {
      let adminOptions = adminOptionsTypes;

      if (displayedUserIsFollowed) {
        adminOptions = adminOptions.filter((x) => x.id !== "follow");
      }
      if (!displayedUserIsFollowed) {
        adminOptions = adminOptions.filter((x) => x.id !== "unfollow");
      }

      if (displayedUserIsPremium) {
        adminOptions = adminOptions.filter((x) => x.id !== "addPremium");
      }
      if (!displayedUserIsPremium) {
        adminOptions = adminOptions.filter((x) => x.id !== "removePremium");
      }

      return adminOptions;
    }

    if (userIsSuperAdmin) {
      let superAdminOptions = superAdminOptionsTypes;

      if (displayedUserIsFollowed) {
        superAdminOptions = superAdminOptions.filter((x) => x.id !== "follow");
      }
      if (!displayedUserIsFollowed) {
        superAdminOptions = superAdminOptions.filter(
          (x) => x.id !== "unfollow"
        );
      }

      if (displayedUserIsPremium) {
        superAdminOptions = superAdminOptions.filter(
          (x) => x.id !== "addPremium"
        );
      }
      if (!displayedUserIsPremium) {
        superAdminOptions = superAdminOptions.filter(
          (x) => x.id !== "removePremium"
        );
      }

      if (displayedUserIsAdmin) {
        superAdminOptions = superAdminOptions.filter(
          (x) => x.id !== "addAdmin"
        );
      }
      if (!displayedUserIsAdmin) {
        superAdminOptions = superAdminOptions.filter(
          (x) => x.id !== "removeAdmin"
        );
      }

      return superAdminOptions;
    }

    return [];
  };

  const renderUnfollowDialog = () => {
    return (
      <Dialog
        title={"Confirm Unfollow"}
        isLoading={isLoading}
        isOpen={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        actions={() => (
          <Button
            round
            color="success"
            style={{ marginLeft: "1rem" }}
            onClick={handleUnfollow}
          >
            Confirm
          </Button>
        )}
      >
        <p>
          Are you sure you want to unfollow user{" "}
          <strong>{profile.userName}</strong>?
        </p>
      </Dialog>
    );
  };

  if (loggedUserData.userId !== userId) {
    if (userIsAdmin || userIsSuperAdmin) {
      return (
        <div className={classes.follow}>
          <CustomDropdown
            caret
            buttonText={"Options"}
            hoverColor="primary"
            onClick={handleUserOptionSelect}
            dropPlacement={"bottom"}
            buttonProps={{
              color: "primary",
              round: true,
              className: classes.followButton,
            }}
            dropdownList={dropdownOptions()}
          />
          {renderUnfollowDialog()}
        </div>
      );
    } else {
      return (
        <div className={classes.follow}>
          <Tooltip
            id="tooltip-top"
            title={
              profile.isFollowing ? "Unfollow this user" : "Follow this user"
            }
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              round
              color={profile.isFollowing ? "rose" : "primary"}
              className={classes.followButton}
              onClick={
                profile.isFollowing ? handleConfirmUnfollow : handleFollow
              }
              disabled={isLoading}
            >
              {profile.isFollowing ? (
                <Remove className={classes.followIcon} />
              ) : (
                <Add className={classes.followIcon} />
              )}
              {profile.isFollowing ? "Unfollow" : "Follow"}
            </Button>
          </Tooltip>
          {renderUnfollowDialog()}
        </div>
      );
    }
  } else {
    return null;
  }
}
