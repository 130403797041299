import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import Button from "components/CustomButtons/Button";
import PropTypes from "prop-types";
import React from "react";
import libraryPageStyle from "views/ArticlesView/LibraryPageStyle";

const useStyles = makeStyles(libraryPageStyle);

function FollowUnfollow({
  isLoading,
  isFollowing,
  tooltipMessages,
  onFollowClick,
  onUnfollowClick,
}) {
  const classes = useStyles();
  const { forFollow, forUnfollow } = tooltipMessages;

  return (
    <Tooltip
      id="tooltip-top"
      title={isFollowing ? forUnfollow : forFollow}
      placement="top"
      classes={{ tooltip: classes.tooltip }}
    >
      <Button
        round
        color={isFollowing ? "rose" : "primary"}
        className={classes.followButton}
        style={{ marginLeft: "1rem" }}
        disabled={isLoading}
        onClick={isFollowing ? onUnfollowClick : onFollowClick}
      >
        {isFollowing ? (
          <Remove className={classes.followIcon} />
        ) : (
          <Add className={classes.followIcon} />
        )}
        {isFollowing ? "Unfollow" : "Follow"}
      </Button>
    </Tooltip>
  );
}

FollowUnfollow.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isFollowing: PropTypes.bool.isRequired,
  tooltipMessages: PropTypes.shape({
    forFollow: PropTypes.string.isRequired,
    forUnfollow: PropTypes.string.isRequired,
  }).isRequired,
  onFollowClick: PropTypes.func.isRequired,
  onUnfollowClick: PropTypes.func.isRequired,
};

export default FollowUnfollow;
