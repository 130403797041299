import React, { useMemo, useState } from "react";
import Button from "../../../../components/CustomButtons/Button";
import Dialog from "../../../../components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  closeUnfollowTagDialog,
  dialogsSelectors,
} from "../../../../store/dialogsSlice";
import { setIsFollowing, unfollowTag } from "../../../../store/followingSlice";
import { routerSelectors } from "../../../../store/routerActions";

export default function UnfollowTagDialog() {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(dialogsSelectors.getUnfollowTagDialogState);

  const tagParams = useSelector(routerSelectors.getQueryTagParams);
  const tags = useMemo(() => (tagParams?.length ? tagParams.split(",") : []), [
    tagParams,
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => dispatch(closeUnfollowTagDialog());

  async function handleUnfollow() {
    const tagName = `#${tags[0]}`;

    setIsLoading(true);
    const success = await dispatch(unfollowTag({ tagName }));
    setIsLoading(false);

    if (success) {
      handleClose();
      dispatch(setIsFollowing(false));
    }
  }

  return (
    <Dialog
      title={"Confirm Unfollow"}
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={handleClose}
      actions={() => (
        <Button
          round
          color="success"
          style={{ marginLeft: "1rem" }}
          onClick={handleUnfollow}
        >
          Confirm
        </Button>
      )}
    >
      <p>Are you sure you want to unfollow this tag?</p>
    </Dialog>
  );
}
