import axios from "axios";
import Endpoints from "../staticData/Endpoints";
import { toastr } from "react-redux-toastr";
import { displayValidationMessage } from "../utils/validationDisplayHelper";

export const removeUserShareAccess = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { email, entityType, entityId } = data;

  return axios({
    method: "DELETE",
    url: Endpoints.removeUserShareAccess
      .replace(":entityType", entityType)
      .replace(":userEmail", email)
      .replace(":entityId", entityId),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        toastr.success("", "User share access was removed successfully.");
        return true;
      }
    })
    .catch((error) => {
      displayValidationMessage(error, dispatch);
      return false;
    });
};

export const removeBoardShareAccess = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { entityType, groupId, entityId } = data;

  return axios({
    method: "DELETE",
    url: Endpoints.removeGroupShareAccess
      .replace(":entityType", entityType)
      .replace(":groupId", groupId)
      .replace(":entityId", entityId),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        toastr.success("", "Group share access was removed successfully.");
        return true;
      }
    })
    .catch((error) => {
      displayValidationMessage(error, dispatch);
      return false;
    });
};

export const getEntityByGuestCode = ({ token }) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  return axios({
    method: "GET",
    url: Endpoints.getByToken,
    params: { token },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status, data } = result;

      if (status === 200) {
        return data;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const adminRemoveContent = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const { contentType, id } = data;

  return axios({
    method: "DELETE",
    url: Endpoints.removeContent,
    params: { contentType, id },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status, data } = result;

      if (status === 200) {
        return data;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getCalendarEvents = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const { start, end, endpoint } = data;

  return axios({
    method: "GET",
    url: endpoint,
    params: {
      start,
      end,
    },
    headers: {
      "content-type": "application/json",
      ...(authTokens && { authorization: `Bearer ${authTokens}` }),
    },
  });
};
