import { mlAuto, mrAuto, title } from "assets/jss/material-kit-pro-react.js";
import tooltips from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

export default {
  floatRight: {
    float: "right",
  },
  textCenter: {
    textAlign: "center",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "flex-end",

    "& button": {
      marginLeft: "10px",
      padding: "10px 10px",
    },
  },
  ...tooltips,
  title,
  mrAuto,
  mlAuto,
};
