import React from "react";
import Button from "../../../../components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import Card from "../../../../components/Card/Card";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardHeader from "../../../../components/Card/CardHeader";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import CardBody from "../../../../components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import Slide from "@material-ui/core/Slide";
import { useDispatch } from "react-redux";
import { loginUser, /*loginWithFacebook*/ } from "../../../../store/authenticationSlice";
import { push } from "connected-react-router";
import Paths from "../../../../staticData/Paths";
// import FacebookLoginWithButton from "react-facebook-login";
import LoginForm from "../../../molecules/LoginForm/LoginForm";
// import LinkedInSignInImage from "../../../../assets/png/Sign-in-Large---Default.png";

import "./LoginDialog.styles.scss";

const useStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function LoginDialog() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleClose = () => {
    dispatch(push(Paths.root));
  };

  const handleLogin = async (data) => {
    await dispatch(loginUser(data));
  };

  // const handleFacebookResponse = async (data) => {
  //   await dispatch(loginWithFacebook(data));
  // };

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal + " " + classes.modalLogin,
      }}
      open={true}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="login-modal-slide-title"
      aria-describedby="login-modal-slide-description"
    >
      <Card plain className={classes.modalLoginCard}>
        <DialogTitle
          id="login-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <CardHeader
            plain
            color="primary"
            className={classes.textCenter + " " + classes.cardLoginHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={handleClose}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h3 className={classes.cardTitleWhite}>Log in</h3>
          </CardHeader>
        </DialogTitle>

        <DialogContent
          id="login-modal-slide-description"
          className={classes.modalBody}
        >
          <CardBody className={classes.cardLoginBody}>
            <LoginForm
              onSubmit={handleLogin}
              submitButton={() => (
                <Button round type={"submit"} color="primary" id={"get-started"}>
                  Get started
                </Button>
              )}
            />

            {/*<div className={"social-media-login-container"}>*/}
            {/*  <div className={"item facebook"}>*/}
            {/*    <FacebookLoginWithButton*/}
            {/*      appId="333159967989991"*/}
            {/*      size={"medium"}*/}
            {/*      fields="name,email,picture"*/}
            {/*      callback={handleFacebookResponse}*/}
            {/*      icon="fa-facebook"*/}
            {/*    />*/}
            {/*  </div>*/}

            {/*  <div className={"item linkedin"}>*/}
            {/*    <a*/}
            {/*      href={*/}
            {/*        "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=123456789&scope=r_liteprofile%20r_emailaddress&client_id=784rr6o8vmfyao&redirect_uri=https%3A%2F%2Ftestsano.com%2Flinkedin-login"*/}
            {/*      }*/}
            {/*    >*/}
            {/*      <img src={LinkedInSignInImage} alt={"LinkedIn Login"} />*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </CardBody>
        </DialogContent>
      </Card>
    </Dialog>
  );
}
