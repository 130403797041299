import React, { useState } from "react";
import Media from "../../../components/Media/Media";
import defaultAvatar from "../../../assets/img/placeholder.jpg";
import Button from "../../../components/CustomButtons/Button";
import postCommentsStyles from "../PostComments/PostComments.style";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { deleteComment, removeReply } from "../../../store/generalPostsSlice";
import { makeStyles } from "@material-ui/core/styles";
import CommentEdit from "../CommentEdit/CommentEdit";
import CommentAttachments from "../CommentAttachments/CommentAttachments";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { authenticationSelectors } from "../../../store/authenticationSlice";
import { toastr } from "react-redux-toastr";
import { adminRemoveContent } from "../../../store/generalActions";
import { push } from "connected-react-router";
import Paths from "../../../staticData/Paths";

const useStyles = makeStyles(postCommentsStyles);

function CommentComponent({
  reply,
  deleteComment,
  removeReply,
  userId,
  adminRemoveContent,
}) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);

  const userRole = useSelector(authenticationSelectors.userRole);

  const userIsAdmin = userRole === "Admin";
  const userIsSuperAdmin = userRole === "SuperAdmin";

  const { text, email, id, files, parentId, user } = reply;

  const handleDeleteComment = (id) => () => deleteComment({ id, parentId });

  const handleAdminRemoveReply = (id) => async () => {
    const success = await adminRemoveContent({ contentType: "comment", id });

    if (success) {
      removeReply({ id, parentId });
      toastr.success("", "Comment removed successfully.");
    }
  };

  const formatAttachmentFiles = (arr) => arr?.map((file) => ({ url: file }));

  const navigateToUserProfile = (event) => {
    event.preventDefault();

    user?.id && dispatch(push(Paths.otherUserAccount.replace(":id", user.id)));
  };

  return (
    <Media
      avatar={user?.profilePicture || defaultAvatar}
      avatarOnClick={navigateToUserProfile}
      title={
        <span
          onClick={navigateToUserProfile}
          style={{
            cursor: user?.id ? "pointer" : "default",
          }}
        >
          {user?.userName || email}
        </span>
      }
      body={
        editMode ? (
          <CommentEdit
            comment={reply}
            closeCommentEdit={() => setEditMode(false)}
          />
        ) : (
          <div>
            <span dangerouslySetInnerHTML={{ __html: text }} />

            <CommentAttachments
              attachments={formatAttachmentFiles(files)}
              displayMode={"view"}
            />

            <div className={classes.actionsContainer}>
              {!!userId && userId === user?.id && (
                <>
                  <Button
                    color="primary"
                    simple
                    onClick={() => setEditMode(true)}
                  >
                    <EditIcon /> Edit
                  </Button>
                  <Button
                    color="primary"
                    simple
                    onClick={handleDeleteComment(id)}
                  >
                    <DeleteForeverIcon /> Delete
                  </Button>
                </>
              )}
              {(userIsAdmin || userIsSuperAdmin) && (
                <Button
                  color="primary"
                  simple
                  onClick={handleAdminRemoveReply(id)}
                >
                  <HighlightOffIcon /> Remove Comment
                </Button>
              )}
            </div>
          </div>
        )
      }
    />
  );
}

CommentComponent.propTypes = {
  userId: PropTypes.string,
  postId: PropTypes.number,
  deleteComment: PropTypes.func,
  adminRemoveContent: PropTypes.func,
  removeReply: PropTypes.func,
  isChildComment: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  userId: state.authentication.userData.userId,
});

const mapDispatchToProps = { deleteComment, removeReply, adminRemoveContent };

export default connect(mapStateToProps, mapDispatchToProps)(CommentComponent);
