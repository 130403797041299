import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import CustomInput from "./CustomInput";
import { useField } from "formik";

const FormikLocationSearch = (props) => {
  const [field, meta, helpers] = useField(props);
  const { address } = field.value;

  const handleChange = (addressText) => {
    helpers.setValue({
      address: addressText,
      latitude: null,
      longitude: null,
    });
  };

  const handleSelect = (textLocation) => {
    geocodeByAddress(textLocation)
      .then((results) => {
        return getLatLng(results[0]).then(
          ({ lat: latitude, lng: longitude }) => ({
            address: results[0].formatted_address,
            latitude,
            longitude,
          })
        );
      })
      .then(({ address, latitude, longitude }) => {
        helpers.setValue({ address, latitude, longitude });
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <CustomInput
            labelText={"Location"}
            inputProps={{ ...getInputProps({}) }}
            formControlProps={{ fullWidth: true }}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={suggestion.placeId}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>

          {meta.touched && meta.error ? (
            <p
              style={{
                fontSize: "12px",
                color: "red",
                position: "relative",
              }}
            >
              {meta.error}
            </p>
          ) : null}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default FormikLocationSearch;
