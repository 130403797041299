import Carousel from "react-slick";
import AttachmentPreview from "../../../components/molecules/AttachmentPreview";
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./PostAttachmentsCarousel.scss";
import { openFilePreviewDialog } from "../../../store/dialogsSlice";
import { useDispatch } from "react-redux";

function PostAttachmentsCarousel({ attachments, selectedSlide, previewOnly }) {
  const dispatch = useDispatch();

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  const sliderRef = useRef(null);

  function handleOpenFilePreviewDialog(url) {
    dispatch(openFilePreviewDialog({ file: url, attachments }));
  }

  useEffect(() => {
    sliderRef.current.slickGoTo(selectedSlide);
  }, [selectedSlide]);

  return (
    <Carousel {...carouselSettings} ref={sliderRef}>
      {attachments?.map((attachment) => {
        return (
          <AttachmentPreview
            key={attachment.url}
            attachment={attachment}
            previewOnly={previewOnly}
            openFilePreview={handleOpenFilePreviewDialog}
          />
        );
      })}
    </Carousel>
  );
}

PostAttachmentsCarousel.defaultProps = {
  attachments: [],
  selectedSlide: null,
};

PostAttachmentsCarousel.propTypes = {
  selectedSlide: PropTypes.number,
  previewOnly: PropTypes.bool,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["file", "link"]),
      url: PropTypes.string,
    })
  ).isRequired,
};

export default PostAttachmentsCarousel;
