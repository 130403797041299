import React from "react";
import Loader from "react-loader-spinner";
import { primaryColor } from "../../assets/jss/material-kit-pro-react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  loadContainer: {
    position: "relative",
  },
  spinnerContainer: {
    position: "absolute",
    // width: "100%",
    // height: "100%",
    top: "-10px",
    left: "-10px",
    right: "-10px",
    bottom: "-10px",
    backgroundColor: "rgba(0,0,0,0.1)",
    zIndex: 2,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

function Loading({ isLoading, children, size }) {
  const classes = useStyles();

  function dimensions() {
    switch (size) {
      case "sm": {
        return 40;
      }
      case "lg": {
        return 100;
      }
      default: {
        return 70;
      }
    }
  }

  return (
    <div className={classes.loadContainer}>
      {isLoading && (
        <div className={classes.spinnerContainer}>
          <Loader
            type="TailSpin"
            color={primaryColor[0]}
            height={dimensions()}
            width={dimensions()}
          />
        </div>
      )}
      {children}
    </div>
  );
}

Loading.defaultProps = {
  isLoading: false,
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.oneOf(["sm", "lg"]),
};

export default Loading;
