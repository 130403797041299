import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { reducer as toastrReducer } from "react-redux-toastr";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import authenticationReducer from "./authenticationSlice";
import dialogsReducer from "./dialogsSlice";
import postsReducer from "./generalPostsSlice";
import generalBoardsReducer from "./generalBoardsSlice";
import boardsReducer from "./boardsSlice";
import connectionsReducer from "./connectionsSlice";
import favoritesReducer from "./favouritesSlice";
import followingReducer from "./followingSlice";
import libraryReducer from "./librarySlice";
import otherUserReducer from "./otherUserSlice";
import myAccountReducer from "./myAccountSlice";
import { gaTrackingMiddleware } from "utils/googleAnalytics/gaTrackingMiddleware";

const history = createBrowserHistory();

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware()
    .concat(routerMiddleware(history))
    .concat(gaTrackingMiddleware);

const rootReducer = (history) =>
  combineReducers({
    authentication: authenticationReducer,
    dialogs: dialogsReducer,
    posts: postsReducer,
    generalBoards: generalBoardsReducer,
    boards: boardsReducer,
    library: libraryReducer,
    favorites: favoritesReducer,
    following: followingReducer,
    connections: connectionsReducer,
    otherUser: otherUserReducer,
    myAccount: myAccountReducer,
    toastr: toastrReducer,
    router: connectRouter(history),
  });

const store = configureStore({
  reducer: rootReducer(history),
  middleware: middleware,
});

export { store, history };
