import React, { useMemo, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import libraryPageStyle from "../../../ArticlesView/LibraryPageStyle";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPosts,
  generalPostsSelectors,
  getPostsByKeyword,
  getPostsByTags,
} from "store/generalPostsSlice";
import SizedStackGrid from "components/SizedStackGrid/SizedStackGrid";
import { useInfiniteLoading } from "utils/hooks/infiniteLoading";
import {
  generalPostActionTypes,
  usePostCard,
} from "utils/hooks/postCardOptions";
import { routerSelectors } from "store/routerActions";

const useStyles = makeStyles(libraryPageStyle);

export default function SearchPagePostsView() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const locationKey = useSelector(routerSelectors.getLocationKey);
  const tag = useSelector(routerSelectors.getQueryTagParams);
  const key = useSelector(routerSelectors.getQueryKeyParams);
  const posts = useSelector(generalPostsSelectors.posts);

  const tagList = useMemo(() => tag?.split(",").map((tag) => `#${tag}`), [tag]);

  const apiCall = useCallback(
    (data) => {
      if (tagList) {
        return getPostsByTags({ ...data, tagList });
      } else {
        return getPostsByKeyword({ ...data, searchText: key });
      }
    },
    [tagList, key]
  );

  const {
    isFetching,
    resetPagination,
    cancelOngoingCall,
    bottomBoundaryNode,
  } = useInfiniteLoading({ apiCall });

  useEffect(() => {
    dispatch(clearPosts());
    resetPagination();

    return () => cancelOngoingCall();
  }, [dispatch, tagList, key, resetPagination, cancelOngoingCall, locationKey]);

  const { postCardRenderer } = usePostCard(generalPostActionTypes);

  return (
    <div className={classes.container} style={{ paddingTop: "30px" }}>
      <SizedStackGrid>{posts.map(postCardRenderer)}</SizedStackGrid>
      {bottomBoundaryNode}

      <div style={{ textAlign: "center", padding: "2rem" }}>
        {!posts.length && !isFetching ? "No Posts found." : ""}
      </div>
    </div>
  );
}
