import React, { createRef, useEffect, useState } from "react";
import Button from "../../../../components/CustomButtons/Button";
import Dialog from "../../../../components/Dialog/Dialog";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../../../components/CustomInput/FormikInput";
import InputAdornmentIcon from "../../../../components/InputAdornments/InputAdornmentIcon";
import FormikUsersMultiselect from "../../../../components/CustomInput/FormikUsersMultiselect";
import { useDispatch, useSelector } from "react-redux";
import {
  activateGuestCodeForBoard,
  deactivateGuestCodeForBoard,
  getSharedForBoards,
  getShareSuggestionsForBoards,
  shareBoard,
} from "../../../../store/generalBoardsSlice";
import {
  closeShareBoardDialog,
  dialogsSelectors,
} from "../../../../store/dialogsSlice";
import SharedToUsers from "../../../organisms/SharedToUsers/SharedToUsers";
import SharedToGroups from "../../../organisms/SharedToGroups/SharedToGroups";
import FormikCustomCheckboxInput from "../../../../components/CustomInput/FormikCustomCheckboxInput";
import { authenticationSelectors } from "../../../../store/authenticationSlice";

function ShareBoardDialog() {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(dialogsSelectors.getShareBoardDialogState);
  const { userId } = useSelector(authenticationSelectors.userData);
  const { id: boardId, user: boardOwner } = useSelector(
    dialogsSelectors.getContextBoard
  );

  const currentUserIsOwner = () => {
    return userId && userId === boardOwner?.id;
  };

  const formRef = createRef();
  const [sharedWithUsers, setSharedWithUsers] = React.useState([]);
  const [sharedWithGroups, setSharedWithGroups] = useState([]);
  const [guestCode, setGuestCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => dispatch(closeShareBoardDialog());

  useEffect(() => {
    if (!isOpen) {
      setSharedWithUsers([]);
      setSharedWithGroups([]);
      setGuestCode("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (boardId && isOpen) {
      (async () => {
        const data = await dispatch(getSharedForBoards({ boardId }));

        if (data) {
          const { users, groups, publicToken } = data;

          setSharedWithUsers(users);
          setSharedWithGroups(groups);
          setGuestCode(publicToken);
        }
      })();
    }
  }, [boardId, isOpen, dispatch]);

  async function handleShareBoard(formValues) {
    setIsLoading(true);
    const success = await dispatch(shareBoard({ boardId, formValues }));
    setIsLoading(false);

    if (success) {
      handleClose();
    }
  }

  const [usersOptions, setUsersOptions] = React.useState([]);
  const [usersSearchLoading, setUsersSearchLoading] = React.useState(false);

  async function handleUserInputChange(event, value, reason) {
    if (reason === "input" && value?.trim().length) {
      setUsersSearchLoading(true);

      const data = await dispatch(
        getShareSuggestionsForBoards({
          boardId,
          key: value.trim().replace(" ", "&nbsp;"),
        })
      );

      setUsersSearchLoading(false);

      if (data) {
        const convertedData = data.map((x) => ({
          ...x,
          userName: x.name,
          profilePicture: x.profilePictureUrl,
        }));

        setUsersOptions(convertedData);
      }
    }
  }

  const handleGuestCodeToggle = (event) => {
    const isChecked = event.target.checked;

    (async () => {
      let success;

      if (isChecked) {
        success = await dispatch(activateGuestCodeForBoard({ boardId }));
      } else {
        success = await dispatch(deactivateGuestCodeForBoard({ boardId }));
      }

      if (success !== undefined) {
        setGuestCode(success);
      }
    })();
  };

  return (
    <Dialog
      isOpen={isOpen}
      isLoading={isLoading}
      title={"Share Board"}
      onClose={handleClose}
      actions={() => (
        <Button
          round
          color="primary"
          style={{ marginLeft: "1rem" }}
          onClick={() => formRef.current.submitForm()}
        >
          Share
        </Button>
      )}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          usersAndGroups: [],
          message: "",
          shareTokenToggle: !!guestCode,
          tokenValue: guestCode,
        }}
        validationSchema={Yup.object({
          usersAndGroups: Yup.array(Yup.object()).required(
            "Please select at least one user or group."
          ),
          message: Yup.string(),
        })}
        onSubmit={handleShareBoard}
      >
        <Form>
          <FormikUsersMultiselect
            id={"share-users-multiselect"}
            name={"usersAndGroups"}
            placeholder={"Users and Groups"}
            handleInputChange={handleUserInputChange}
            options={usersOptions}
            isLoading={usersSearchLoading}
          />
          <FormikInput
            id={"share-message-input"}
            name={"message"}
            type={"text"}
            placeholder="Message"
            multiline
            rowsMax={10}
            endAdornment={<InputAdornmentIcon icon={"message"} />}
          />{" "}
          {currentUserIsOwner() && (
            <FormikCustomCheckboxInput
              id={"share-tokenToggle-checkbox"}
              name={"shareTokenToggle"}
              label={"Guest Code"}
              onChange={handleGuestCodeToggle}
            />
          )}
          {guestCode && (
            <FormikInput
              id={"share-tokenValue-input"}
              name={"tokenValue"}
              disabled={true}
            />
          )}
          <SharedToUsers
            users={sharedWithUsers}
            setUsers={setSharedWithUsers}
            onUserClick={handleClose}
            entityId={boardId}
            entityType={"board"}
          />
          <SharedToGroups
            groups={sharedWithGroups}
            setGroups={setSharedWithGroups}
            onGroupClick={handleClose}
            entityId={boardId}
            entityType={"board"}
          />
        </Form>
      </Formik>
    </Dialog>
  );
}

export default ShareBoardDialog;
