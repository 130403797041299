import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../CustomButtons/Button";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import MuiDialog from "@material-ui/core/Dialog";
import javascriptStyles from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Loading from "../../components/Loading/Loading";

const useDialogStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Dialog({
  isOpen,
  title,
  onClose,
  withCancel,
  children,
  actions,
  isLoading,
}) {
  const dialogClasses = useDialogStyles();

  const handleDialogClose = () => {
    onClose();
  };

  return (
    <MuiDialog
      open={isOpen}
      onClose={handleDialogClose}
      TransitionComponent={Transition}
      classes={{ root: dialogClasses.modalRoot, paper: dialogClasses.modal }}
    >
      <Loading isLoading={isLoading}>
        <div className={dialogClasses.container}>
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={dialogClasses.modalHeader}
          >
            <Button
              simple
              className={dialogClasses.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={handleDialogClose}
            >
              <Close className={dialogClasses.modalClose} />
            </Button>
            <h3
              className={dialogClasses.modalTitle}
              style={{ fontWeight: "bold" }}
            >
              {title}
            </h3>
            <hr />
          </DialogTitle>
          <DialogContent className={dialogClasses.modalBody}>
            {children}
          </DialogContent>
          <DialogActions className={dialogClasses.modalFooter}>
            <GridContainer justify={"center"}>
              <GridItem sm={12}>
                <div style={{ marginBottom: "1rem" }}>
                  {withCancel && (
                    <Button
                      round
                      color="primary"
                      onClick={handleDialogClose}
                      style={{ marginLeft: "1rem" }}
                    >
                      Cancel
                    </Button>
                  )}
                  {actions()}
                </div>
              </GridItem>
            </GridContainer>
          </DialogActions>
        </div>
      </Loading>
    </MuiDialog>
  );
}

Dialog.defaultProps = {
  isOpen: false,
  isLoading: false,
  withCancel: true,
  title: "",
  onClose: () => {},
  actions: () => {},
};

Dialog.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  withCancel: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  actions: PropTypes.func,
};

export default Dialog;
