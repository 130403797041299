import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import defaultAvatar from "../../../assets/img/placeholder.jpg";
import { makeStyles } from "@material-ui/core/styles";
import Paths from "../../../staticData/Paths";
import { blackColor, hexToRgb, title } from "assets/jss/material-kit-pro-react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";
import { MoreVert } from "@material-ui/icons";

PersonCard.propTypes = {
  person: PropTypes.shape({
    profilePicture: PropTypes.string,
    id: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.shape({
    optionsList: PropTypes.arrayOf(PropTypes.string),
    handleActions: PropTypes.func,
  }),
};

PersonCard.defaultProps = {
  options: {},
};

const useStyles = makeStyles({
  userAvatar: {
    boxShadow:
      "0 5px 15px -8px rgba(" +
      hexToRgb(blackColor) +
      ", 0.24), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    borderRadius: "50% !important",
    maxWidth: "100%",
    height: "100px",
    width: "100px",
    minWidth: "100px",
    cursor: "pointer",
  },
  title: {
    ...title,
    minHeight: "unset",
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
  imageDropdownButton: {
    padding: "0px",
    borderRadius: "50%",
    marginLeft: "5px",
  },
  superCentered: {
    display: "grid",
    placeItems: "center",
  },
  dropdownIcons: {
    width: "24px",
    height: "24px",
    marginRight: "5px",
    opacity: "0.7",
    marginTop: "-4px",
    verticalAlign: "middle",
    fontSize: "24px",
    position: "relative",
  },
  cardOptions: {
    position: "absolute",
    top: "-10px",
    right: "-10px",
    justifyContent: "flex-end",
    display: "flex",
    flex: "1",
  },
  userContentContainer: {
    display: "grid",
    gridGap: "1rem",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  cardContainer: {
    display: "grid",
    position: "relative",
    gridTemplateColumns: "1fr",
  },
});

export default function PersonCard({
  options: { optionsList, handleActions },
  person: { profilePicture, id, userName },
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const userProfileUrl = Paths.otherUserAccount.replace(":id", id);
  const handleRedirectToUserProfile = () => dispatch(push(userProfileUrl));

  const handleSelectAction = ({ option }) => handleActions?.({ option });

  const getDropdownList = useCallback(() => optionsList, [optionsList]);

  return (
    <Card blog style={{ margin: "0" }}>
      <CardBody>
        <div className={classes.cardContainer}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={classes.superCentered}>
              <img
                src={profilePicture || defaultAvatar}
                alt="user avatar"
                className={classes.userAvatar}
                onClick={handleRedirectToUserProfile}
              />
            </div>
            <div className={classes.superCentered}>
              <h3
                className={classes.title}
                onClick={handleRedirectToUserProfile}
              >
                {userName}
              </h3>
            </div>
          </div>

          <div className={classes.cardOptions}>
            {optionsList && handleActions && (
              <CustomDropdown
                caret={false}
                hoverColor="primary"
                buttonText={<MoreVert />}
                onClick={(option) => handleSelectAction({ option })}
                dropPlacement={"bottom-end"}
                buttonProps={{
                  className: classes.imageDropdownButton,
                  color: "transparent",
                  justIcon: true,
                }}
                dropdownList={getDropdownList()}
              />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
