import Button from "../../../../components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import Card from "../../../../components/Card/Card";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardHeader from "../../../../components/Card/CardHeader";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Email from "@material-ui/icons/Email";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import Slide from "@material-ui/core/Slide";
import { Form, Formik } from "formik";
import { recoverPassword } from "../../../../store/authenticationSlice";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Paths from "../../../../staticData/Paths";
import * as Yup from "yup";
import FormikInput from "../../../../components/CustomInput/FormikInput";
import InputAdornmentIcon from "../../../../components/InputAdornments/InputAdornmentIcon";
import CardBody from "../../../../components/Card/CardBody";

const useStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

function RecoverPasswordDialog({ recoverPassword, push }) {
  const classes = useStyles();

  const handleClose = () => {
    push(Paths.root);
  };

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal + " " + classes.modalLogin,
      }}
      open={true}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="login-modal-slide-title"
      aria-describedby="login-modal-slide-description"
    >
      <Card plain className={classes.modalLoginCard}>
        <DialogTitle
          id="login-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <CardHeader
            plain
            color="primary"
            className={classes.textCenter + " " + classes.cardLoginHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={handleClose}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h3 className={classes.cardTitleWhite}>Recover Password</h3>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="login-modal-slide-description"
          className={classes.modalBody}
        >
          <CardBody className={classes.cardLoginBody}>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("Please use a valid email format.")
                  .required("Email is required."),
              })}
              onSubmit={recoverPassword}
            >
              <Form>
                <FormikInput
                  id={"recoverPassword-email-input"}
                  name={"email"}
                  type={"text"}
                  placeholder={"Email"}
                  endAdornment={<InputAdornmentIcon icon={Email} />}
                />
                <div className={classes.textCenter}>
                  <Button round color="primary" type={"submit"}>
                    Email Me
                  </Button>
                </div>
              </Form>
            </Formik>
          </CardBody>
        </DialogContent>
      </Card>
    </Dialog>
  );
}

RecoverPasswordDialog.propTypes = {
  recoverPassword: PropTypes.func,
  push: PropTypes.func,
};

const mapDispatchToProps = { recoverPassword, push };

export default connect(null, mapDispatchToProps)(RecoverPasswordDialog);
