import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import CustomInput from "./CustomInput";
import React from "react";
import { useField } from "formik";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles(javascriptStyles);

const FormikCustomEmailInput = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const { id, labelText, ...restProps } = props;

  const classes = useStyles();

  const [field, meta] = useField(props);

  return (
    <>
      <CustomInput
        id={id}
        formControlProps={{
          fullWidth: true,
        }}
        labelText={labelText || ""}
        inputProps={{
          ...field,
          ...restProps,
          type: `${showPassword ? "text" : "password"}`,
          endAdornment: (
            <InputAdornment position="end" className={classes.inputAdornment}>
              <IconButton
                className={classNames(
                  classes.inputIconsColor,
                  classes.inputAdornmentPadding
                )}
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((val) => !val)}
                onMouseDown={() => setShowPassword((val) => !val)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          autoComplete: "off",
        }}
      />
      {meta.error ? (
        <p
          style={{
            fontSize: "12px",
            color: "red",
            position: "relative",
            top: "-15px",
          }}
        >
          {meta.error}
        </p>
      ) : null}
    </>
  );
};

export default FormikCustomEmailInput;
