import React, { useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../../../components/CustomInput/FormikInput";
import Button from "../../../../components/CustomButtons/Button";
import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../store/authenticationSlice";
import { myAccountSelectors } from "../../../../store/myAccountSlice";

const useStyles = makeStyles(javascriptStyles);

export default function MyAccountProfileView() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const userData = useSelector(myAccountSelectors.profile);

  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateProfile = async (formValues) => {
    setIsLoading(true);
    await dispatch(updateProfile({ formValues }));
    setIsLoading(false);
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              firstName: userData.firstName || "",
              lastName: userData.lastName || "",
              userName: userData.userName || "",
              email: userData.email || "",
              userBio: userData.bio || "",
            }}
            validationSchema={Yup.object({
              firstName: Yup.string(),
              lastName: Yup.string(),
              userName: Yup.string().required("Username is required."),
              email: Yup.string().required("Email is required"),
              userBio: Yup.string(),
            })}
            onSubmit={handleUpdateProfile}
          >
            <Form>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <FormikInput
                    id={"profile-firstName-input"}
                    name={"firstName"}
                    type={"text"}
                    placeholder={"First Name"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormikInput
                    id={"profile-lastName-input"}
                    name={"lastName"}
                    type={"text"}
                    placeholder={"Last Name"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormikInput
                    id={"profile-userName-input"}
                    name={"userName"}
                    type={"text"}
                    placeholder={"Username"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormikInput
                    id={"profile-email-input"}
                    name={"email"}
                    type={"text"}
                    placeholder={"Email"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormikInput
                    id={"profile-userBio-input"}
                    name={"userBio"}
                    type={"text"}
                    placeholder={"Bio"}
                    multiline
                    rowsMax={10}
                  />
                </GridItem>
                <div className={classes.textCenter}>
                  <Button
                    round
                    type={"submit"}
                    color="primary"
                    disabled={isLoading}
                  >
                    Update Profile
                  </Button>
                </div>
              </GridContainer>
            </Form>
          </Formik>
        </GridItem>
      </GridContainer>
    </div>
  );
}
