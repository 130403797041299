import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  connectionsSelectors,
  getGroups,
  resetGroups,
} from "../../../store/connectionsSlice";
import GroupCard from "../GroupCard/GroupCard";
import { useInfiniteLoading } from "../../../utils/hooks/infiniteLoading";
import SizedStackGrid from "../../../components/SizedStackGrid/SizedStackGrid";

export default function ConnectionsGroups() {
  const dispatch = useDispatch();

  const groups = useSelector(connectionsSelectors.groups);

  useEffect(() => {
    return () => dispatch(resetGroups());
  }, [dispatch]);

  const apiCall = useCallback((data) => getGroups(data), []);

  const { bottomBoundaryNode } = useInfiniteLoading({ apiCall });

  return (
    <div style={{ padding: "1rem 0" }}>
      <SizedStackGrid>
        {groups.map((group) => (
          <GroupCard key={group.id} group={group} />
        ))}
      </SizedStackGrid>
      {!groups.length ? (
        <div style={{ display: "grid", placeItems: "center" }}>
          <p>Please create or join a group in order to see it here.</p>
        </div>
      ) : null}
      {bottomBoundaryNode}
    </div>
  );
}
