import Button from "../../../../components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import Card from "../../../../components/Card/Card";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardHeader from "../../../../components/Card/CardHeader";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import CardBody from "../../../../components/Card/CardBody";
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import Slide from "@material-ui/core/Slide";
import { closeSaveArticleDialog } from "../../../../store/dialogsSlice";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { savePostToBoard } from "../../../../store/dialogsSlice";
import { push } from "connected-react-router";

import FormikSelect from "../../../../components/CustomInput/FormikSelect";
import Paths from "../../../../staticData/Paths";
import { getBoardSuggestions } from "../../../../store/generalPostsSlice";
const useStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

function SavePostDialog({
  push,
  authTokens,
  contextItem,
  dialogState,
  closeSaveArticleDialog,
  savePostToBoard,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { id: itemId, private: isPostPrivate } = contextItem;
  const { isOpen } = dialogState;
  const [boards, setBoards] = useState([]);

  const handleClose = () => {
    closeSaveArticleDialog();
  };

  const constructBoardOptions = useCallback(
    (data) => {
      const myBoards = data.filter((elem) => elem.isMine);
      const sharedBoards = data.filter((elem) => !elem.isMine);

      let response = [
        {
          header: true,
          name: "Create New Board",
          id: "createBoard",
          onClick: () => {
            closeSaveArticleDialog();
            push(Paths.createBoard, { postId: itemId });
          },
        },
      ];

      if (myBoards.length) {
        response = [
          ...response,
          { header: true, name: "My Boards", id: "myBoards" },
          ...myBoards,
        ];
      }

      if (sharedBoards.length) {
        response = [
          ...response,
          { header: true, name: "Shared", id: "shared" },
          ...sharedBoards,
        ];
      }

      return response;
    },
    [itemId, closeSaveArticleDialog, push]
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getBoardSuggestions({
          isPostPrivate,
          itemId,
        })
      ).then((result) => setBoards(constructBoardOptions(result.data)));
    }
  }, [
    dispatch,
    authTokens,
    isOpen,
    constructBoardOptions,
    isPostPrivate,
    itemId,
  ]);

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth
      maxWidth={"md"}
      aria-labelledby="login-modal-slide-title"
      aria-describedby="login-modal-slide-description"
    >
      <Card plain className={classes.modalLoginCard}>
        <DialogTitle
          id="login-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <CardHeader
            plain
            color="primary"
            className={classes.textCenter + " " + classes.cardLoginHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={handleClose}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h3 className={classes.cardTitleWhite}>Save</h3>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="login-modal-slide-description"
          className={classes.modalBody}
        >
          <CardBody className={classes.cardLoginBody}>
            <br />
            <Formik
              initialValues={{
                boards: "",
              }}
              validationSchema={Yup.object({
                boards: Yup.number().required("Please select a board."),
              })}
              onSubmit={savePostToBoard}
            >
              <Form>
                <FormikSelect
                  id={"savePost-boards-select"}
                  name={"boards"}
                  placeholder={"Save to Board"}
                  options={boards}
                />
                <br />
                <div className={classes.textCenter} style={{ margin: "1rem" }}>
                  <Button round color="primary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    style={{ marginLeft: "1rem" }}
                    round
                    type={"submit"}
                    color="primary"
                  >
                    Save to Board
                  </Button>
                </div>
              </Form>
            </Formik>
          </CardBody>
        </DialogContent>
      </Card>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  authTokens: state.authentication.authTokens,
  contextItem: state.dialogs.contextItem,
  dialogState: state.dialogs.saveArticleDialog,
});

const mapDispatchToProps = {
  closeSaveArticleDialog,
  savePostToBoard,
  push,
};

export default connect(mapStateToProps, mapDispatchToProps)(SavePostDialog);
