import React from "react";
import Button from "../../../../components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import Card from "../../../../components/Card/Card";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardHeader from "../../../../components/Card/CardHeader";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import CardBody from "../../../../components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import Slide from "@material-ui/core/Slide";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikCustomPasswordInput from "../../../../components/CustomInput/FormikCustomPasswordInput";
import { resetPassword } from "store/authenticationSlice";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import Paths from "../../../../staticData/Paths";
import { routerSelectors } from "../../../../store/routerActions";

const useStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function ResetPasswordDialog() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const token = useSelector(routerSelectors.getQueryParamsByName("token"));

  const handleClose = () => dispatch(push(Paths.root));

  const handleResetPassword = (data) => {
    const { token, newUserPass } = data;

    dispatch(resetPassword({ token: `${token}=`, password: newUserPass }));
  };

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal + " " + classes.modalLogin,
      }}
      open={true}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="login-modal-slide-title"
      aria-describedby="login-modal-slide-description"
    >
      <Card plain className={classes.modalLoginCard}>
        <DialogTitle
          id="login-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <CardHeader
            plain
            color="primary"
            className={classes.textCenter + " " + classes.cardLoginHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={handleClose}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h3 className={classes.cardTitleWhite}>Reset Password</h3>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="login-modal-slide-description"
          className={classes.modalBody}
        >
          <CardBody className={classes.cardLoginBody}>
            <Formik
              initialValues={{
                token: token || "",
                newUserPass: "",
              }}
              enableReinitialize
              validationSchema={Yup.object({
                newUserPass: Yup.string()
                  .required("Password is required.")
                  .test(
                    "password",
                    "Password must contain at least one lowercase letter.",
                    (val) => /[a-z]/.test(val)
                  )
                  .test(
                    "password",
                    "Password must contain at least one uppercase letter.",
                    (val) => /[A-Z]/.test(val)
                  )
                  .test(
                    "password",
                    "Password must contain at least one number.",
                    (val) => /[\d]/.test(val)
                  )
                  .test(
                    "password",
                    "Password must contain at least one non-alphanumeric character.",
                    (val) => /[\W]/.test(val)
                  )
                  .min(8, "Password must have at least 8 characters"),
              })}
              onSubmit={handleResetPassword}
            >
              <Form autoComplete={"off"}>
                <FormikCustomPasswordInput
                  id={"resetPassword-password-input"}
                  name={"newUserPass"}
                  placeholder={"New Password"}
                />
                <div className={classes.textCenter}>
                  <Button round color="primary" type={"submit"}>
                    Submit
                  </Button>
                </div>
              </Form>
            </Formik>
          </CardBody>
        </DialogContent>
      </Card>
    </Dialog>
  );
}
