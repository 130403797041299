import * as React from "react";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import libraryPageStyle from "../../../ArticlesView/LibraryPageStyle";
import SizedStackGrid from "../../../../components/SizedStackGrid/SizedStackGrid";
import {
  clearPosts,
  getBoardDetailsPosts,
  generalPostsSelectors,
} from "../../../../store/generalPostsSlice";
import {
  generalPostActionTypes,
  postActionTypes,
  usePostCard,
} from "../../../../utils/hooks/postCardOptions";
import { useInfiniteLoading } from "../../../../utils/hooks/infiniteLoading";
import { routerSelectors } from "../../../../store/routerActions";
import { dialogsSelectors } from "../../../../store/dialogsSlice";

const useStyles = makeStyles(libraryPageStyle);

export default function BoardDetailsPostsView({ boardId }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const locationKey = useSelector(routerSelectors.getLocationKey);
  const posts = useSelector(generalPostsSelectors.posts);
  const boardData = useSelector(dialogsSelectors.getContextBoard);

  const apiCall = useCallback(
    (data) => getBoardDetailsPosts({ ...data, boardId }),
    [boardId]
  );

  const {
    isFetching,
    resetPagination,
    cancelOngoingCall,
    bottomBoundaryNode,
  } = useInfiniteLoading({ apiCall });

  useEffect(() => {
    dispatch(clearPosts());
    resetPagination();

    return () => cancelOngoingCall();
  }, [dispatch, resetPagination, cancelOngoingCall, boardId, locationKey]);

  const { postCardRenderer } = usePostCard([
    ...generalPostActionTypes,
    postActionTypes.removeFromBoard,
  ], false, boardData?.user?.id);

  return (
    <div className={classes.container} style={{ paddingTop: "30px" }}>
      <SizedStackGrid>{posts.map(postCardRenderer)}</SizedStackGrid>
      {bottomBoundaryNode}

      <div style={{ height: "6rem", textAlign: "center", padding: "2rem" }}>
        {!posts.length && !isFetching
          ? "Create or Save a post in this board in order to see it here."
          : ""}
      </div>
    </div>
  );
}
