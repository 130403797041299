import { useSelector } from "react-redux";
import { authenticationSelectors } from "store/authenticationSlice";
import { postTypes } from "staticData/Posts";

export function useCreatePostOptions() {
  const userRole = useSelector(authenticationSelectors.userRole);

  const userIsPremiumOrAdmin = ["Premium", "Admin", "SuperAdmin"].includes(
    userRole
  );

  let options = postTypes;

  if (!userIsPremiumOrAdmin) {
    options = options.filter((x) => x.id !== "challenge");
  }

  return { options };
}
