import React from "react";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import InputAdornment from "@material-ui/core/InputAdornment";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles(javascriptStyles);

function InputAdornmentIcon(props) {
  const classes = useStyles();

  let icon = null;
  switch (typeof props.icon) {
    case "string":
      icon = <Icon color={"disabled"}>{props.icon}</Icon>;
      break;
    default:
      icon = <props.icon />;
      break;
  }

  return (
    <InputAdornment
      position={props.position || "end"}
      className={classNames(classes.inputAdornment, props.adornmentClassName)}
    >
      <IconButton
        disabled={true}
        color={"primary"}
        className={classNames(classes.inputAdornmentPadding)}
      >
        {icon}
      </IconButton>
    </InputAdornment>
  );
}

export default InputAdornmentIcon;
