import React, { useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StandardPage from "../../../components/StandardPage/StandardPage";
import { getBoardDetails } from "../../../store/generalBoardsSlice";
import BoardDetailsPostsView from "../../newViews/posts/BoardDetailsPostsView/BoardDetailsPostsView";
import FollowUnfollowBoard from "../../organisms/FollowUnfollowBoard/FollowUnfollowBoard";
import { dialogsSelectors } from "../../../store/dialogsSlice";

export default function BoardDetailsPage() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const boardData = useSelector(dialogsSelectors.getContextBoard);

  useEffect(() => {
    dispatch(getBoardDetails({ id }));
  }, [dispatch, id]);

  return (
    <StandardPage
      title={boardData.title}
      headerUrl={boardData.backgroundImage}
      description={boardData.description}
    >
      <FollowUnfollowBoard />

      <BoardDetailsPostsView boardId={id} />
    </StandardPage>
  );
}
