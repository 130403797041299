import React, { useState } from "react";
import PropTypes from "prop-types";
import Paths from "../../../staticData/Paths";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { removeBoardShareAccess } from "../../../store/generalActions";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import CancelIcon from "@material-ui/icons/Cancel";
import Chip from "@material-ui/core/Chip";
import Dialog from "../../../components/Dialog/Dialog";
import Button from "../../../components/CustomButtons/Button";

function GroupShared({
  group,
  onGroupClick,
  entityId,
  entityType,
  onRemoveShareAccess,
}) {
  const dispatch = useDispatch();

  const { id: groupId, name } = group;
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const groupDetailsPath = Paths.groupDetails.replace(":id", groupId);

  const handleOnClick = () => {
    onGroupClick();
    dispatch(push(groupDetailsPath));
  };

  const handleShareAccessRemoval = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const success = await dispatch(
      removeBoardShareAccess({ entityType, entityId, groupId })
    );

    setIsLoading(false);

    if (success) {
      onRemoveShareAccess(groupId);
    }
  };

  const deleteIcon = (
    <Tooltip title={"Remove share access"} placement={"top"}>
      {isLoading ? <CircularProgress size={20} /> : <CancelIcon />}
    </Tooltip>
  );

  return (
    <div
      style={{
        margin: "0.4rem",
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Chip
        variant={"outlined"}
        label={name}
        clickable
        onClick={handleOnClick}
        deleteIcon={deleteIcon}
        onDelete={() => setIsConfirmDialogOpen(true)}
      />
      <Dialog
        title={"Remove Share Access"}
        isLoading={isLoading}
        isOpen={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        actions={() => (
          <Button
            round
            color="success"
            style={{ marginLeft: "1rem" }}
            onClick={handleShareAccessRemoval}
          >
            Confirm
          </Button>
        )}
      >
        <p>
          Are you sure you want to remove share access for group{" "}
          <strong>{name}</strong>?
        </p>
      </Dialog>
    </div>
  );
}

function SharedToGroups({ groups, setGroups, ...restProps }) {
  function onRemoveShareAccess(id) {
    setGroups((data) => data.filter((x) => x.id !== id));
  }

  if (groups.length) {
    return (
      <div>
        <div style={{ marginTop: "1rem", fontWeight: "bold" }}>
          Shared with groups:
        </div>
        {groups.map((group) => (
          <GroupShared
            key={group.id}
            group={group}
            onRemoveShareAccess={onRemoveShareAccess}
            {...restProps}
          />
        ))}
      </div>
    );
  } else {
    return null;
  }
}

export default SharedToGroups;

const entityIdProps = PropTypes.number.isRequired;
const groupProps = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

GroupShared.propTypes = {
  group: groupProps.isRequired,
  entityId: entityIdProps,
  entityType: PropTypes.oneOf(["post", "board"]).isRequired,
  onGroupClick: PropTypes.func.isRequired,
  onRemoveShareAccess: PropTypes.func.isRequired,
};

SharedToGroups.propTypes = {
  groups: PropTypes.arrayOf(groupProps).isRequired,
  setGroups: PropTypes.func,
  entityId: entityIdProps,
  entityType: PropTypes.oneOf(["post", "board"]).isRequired,
  onGroupClick: PropTypes.func,
};

SharedToGroups.defaultProps = {
  groups: [],
  setGroups: () => {},
  onGroupClick: () => {},
  entityId: 0,
};
