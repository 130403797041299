import React from "react";
import PropTypes from "prop-types";
import Badge from "../../../components/Badge/Badge";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  badge: {
    marginLeft: "1rem",
  },
  challengeStatus: { marginLeft: "0.3rem" },
});

export default function PostType({ postData }) {
  const classes = useStyles();

  const { type, active, isActive } = postData;

  return (
    <>
      {type === "article" && (
        <Badge className={classes.badge} color="primary">
          Article
        </Badge>
      )}
      {type === "case" && (
        <Badge className={classes.badge} color="success">
          Case
        </Badge>
      )}
      {type === "question" && (
        <Badge className={classes.badge} color="danger">
          Question
        </Badge>
      )}
      {type === "challenge" && (
        <>
          <Badge className={classes.badge} color="warning">
            Challenge
          </Badge>
          {active || isActive ? (
            <Badge className={classes.challengeStatus} color="success">
              Open
            </Badge>
          ) : (
            <Badge className={classes.challengeStatus} color="danger">
              Closed
            </Badge>
          )}
        </>
      )}
      {type === "event" && (
        <Badge className={classes.badge} color="gray">
          Event
        </Badge>
      )}
    </>
  );
}

PostType.defaultProps = {
  postData: {},
};

PostType.propTypes = {
  postData: PropTypes.object.isRequired,
};
