import CustomInput from "components/CustomInput/CustomInput";
import React, { useEffect, useState, useRef } from "react";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle";
import dropdownStyles from "assets/jss/material-kit-pro-react/components/customDropdownStyle.js";
import Button from "components/CustomButtons/Button";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useRouteMatch } from "react-router-dom";
import { getTagSuggestions } from "store/generalPostsSlice";
import Paths from "../../../staticData/Paths";
import MenuItem from "@material-ui/core/MenuItem";
import classNames from "classnames";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";

const useStyles = makeStyles(navbarsStyle);
const useDropdownStyles = makeStyles(dropdownStyles);

function NavbarSearch() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dropdownClasses = useDropdownStyles();

  const anchor = useRef(null);
  const menuRef = useRef(null);

  const [tags, setTags] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const isInTagSearch = searchValue.match(/^(#\w+$)|\s(#\w+$)/);
  const isSearchRoute = useRouteMatch(Paths.search)?.isExact;

  useEffect(() => {
    if (isInTagSearch) {
      setAnchorEl(anchor.current);
    } else {
      setAnchorEl(null);
    }
  }, [isInTagSearch]);

  useEffect(() => {
    !isSearchRoute && setSearchValue("");
  }, [isSearchRoute]);

  useEffect(() => {
    dispatch(getTagSuggestions()).then((data) => !!data && setTags(data));
  }, [dispatch]);

  const generateTagList = (regExpMatchArray) => {
    return regExpMatchArray.reduce((acc, elem, index) => {
      const formattedTagTag = elem.trim().replace("#", "");

      if (index === 0) {
        return acc.concat(formattedTagTag);
      } else {
        return acc.concat(",", formattedTagTag);
      }
    }, "");
  };

  const handleSearch = () => {
    const regExpMatchArray = searchValue.match(
      /^#([a-zA-Z0-9]+)\s*?|\s#([a-zA-Z0-9]+)\s*?/g
    );

    if (!!regExpMatchArray) {
      const tagList = generateTagList(regExpMatchArray);

      dispatch(push(Paths.searchByTag.replace(":tagParam", tagList)));

      setSearchValue((val) => val.concat(" "));
    } else {
      dispatch(push(Paths.searchByKeyword.replace(":keyParam", searchValue)));
    }
  };

  const handleOptionSelect = (option) => () => {
    setAnchorEl(null);

    const inputText = isInTagSearch?.input;
    const index = isInTagSearch?.index;
    const isStringStartMatch = !!isInTagSearch?.[1];

    const newSearchValue =
      inputText.slice(0, index) + `${isStringStartMatch ? "" : " "}${option} `;

    setSearchValue(newSearchValue);

    anchor.current.firstChild.focus();
  };

  const handleMenuClose = () => {
    setSearchValue((val) => val.concat(" "));

    setAnchorEl(null);
  };

  const handleInputChange = (e) => setSearchValue(e.target.value);

  const handleInputKeyDown = (e) => {
    switch (e.keyCode) {
      case 13:
        handleSearch();
        break;
      case 40:
        e.preventDefault();
        e.stopPropagation();

        menuRef.current &&
          menuRef.current.firstChild &&
          menuRef.current.firstChild.focus();
        break;
      default:
        break;
    }
  };

  const filteredTags = tags.filter((option) => {
    return (
      option.includes(isInTagSearch?.[1]) || option.includes(isInTagSearch?.[2])
    );
  });

  return (
    <ListItem className={classes.listItem} style={{ padding: "0 1rem" }}>
      <div style={{ display: "flex" }}>
        <CustomInput
          white
          inputRootCustomClasses={classes.inputRootCustomClasses}
          formControlProps={{
            className: classes.formControl,
          }}
          inputProps={{
            placeholder: "Search",
            inputProps: {
              "aria-label": "Search",
              className: classes.searchInput,
            },
            value: searchValue,
            ref: anchor,
            onChange: handleInputChange,
            onKeyDown: handleInputKeyDown,
          }}
        />
        <Button color="white" justIcon round onClick={handleSearch}>
          <Search className={classes.searchIcon} />
        </Button>
      </div>
      <Popper
        open={Boolean(anchorEl) && Boolean(filteredTags.length)}
        anchorEl={anchorEl}
        transition
        disablePortal={false}
        placement={"bottom-start"}
        className={classNames({
          [dropdownClasses.popperClose]: !anchorEl,
          [dropdownClasses.pooperResponsive]: true,
          [dropdownClasses.pooperNav]: Boolean(anchorEl) && false,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="hashtag-menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={dropdownClasses.dropdown}>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList
                  role="menu"
                  ref={menuRef}
                  className={classNames(
                    dropdownClasses.menuList,
                    dropdownClasses.navbarSearchMenu
                  )}
                >
                  {filteredTags.map((option) => (
                    <MenuItem
                      key={option}
                      onClick={handleOptionSelect(option)}
                      title={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </ListItem>
  );
}

export default NavbarSearch;
