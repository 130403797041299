import React, { createRef, useCallback, useState } from "react";
import Dialog from "../../../../components/Dialog/Dialog";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import FormikInput from "../../../../components/CustomInput/FormikInput";
import Button from "../../../../components/CustomButtons/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  dialogsSelectors,
  closeCreateGroupDialog,
} from "../../../../store/dialogsSlice";
import { createGroup } from "../../../../store/connectionsSlice";

function CreateGroupDialog() {
  const dispatch = useDispatch();
  const formRef = createRef();
  const [isLoading, setIsLoading] = useState(false);
  const dialogState = useSelector(dialogsSelectors.getCreateGroupDialogState);
  const { isOpen } = dialogState;

  const handleClose = useCallback(() => {
    dispatch(closeCreateGroupDialog());
  }, [dispatch]);

  async function handleCreateGroup(values) {
    setIsLoading(true);
    const success = await dispatch(createGroup(values));
    setIsLoading(false);

    if (success) {
      handleClose();
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      isLoading={isLoading}
      title={"Create Group"}
      actions={() => (
        <Button
          round
          color="success"
          style={{ marginLeft: "1rem" }}
          onClick={() => formRef.current.submitForm()}
        >
          Create
        </Button>
      )}
      onClose={handleClose}
    >
      <Formik
        innerRef={formRef}
        initialValues={{ groupName: "" }}
        validationSchema={Yup.object({
          groupName: Yup.string().required("Group name is required."),
        })}
        onSubmit={handleCreateGroup}
      >
        <Form>
          <GridContainer justify="center">
            <GridItem sm={12}>
              <FormikInput
                id={"createGroup-groupName-text"}
                name={"groupName"}
                type={"text"}
                placeholder={"Group Name"}
                maxLength={80}
              />
            </GridItem>
          </GridContainer>
        </Form>
      </Formik>
    </Dialog>
  );
}

export default CreateGroupDialog;
