/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

// pages for this product
// import AboutUsPage from "views/MuiTemplate/AboutUsPage/AboutUsPage.js";
// import BlogPostPage from "views/MuiTemplate/BlogPostPage/BlogPostPage.js";
// import BlogPostsPage from "views/MuiTemplate/BlogPostsPage/BlogPostsPage.js";
// import ComponentsPage from "views/MuiTemplate/ComponentsPage/ComponentsPage.js";
// import ContactUsPage from "views/MuiTemplate/ContactUsPage/ContactUsPage.js";
// import EcommercePage from "views/MuiTemplate/EcommercePage/EcommercePage.js";
// import LandingPage from "views/MuiTemplate/LandingPage/LandingPage.js";
// import LoginPage from "views/MuiTemplate/LoginPage/LoginPage.js";
// import PresentationPage from "views/MuiTemplate/PresentationPage/PresentationPage.js";
// import PricingPage from "views/MuiTemplate/PricingPage/PricingPage.js";
// import ProfilePage from "views/MuiTemplate/ProfilePage/ProfilePage.js";
// import ProductPage from "views/MuiTemplate/ProductPage/ProductPage.js";
// import SectionsPage from "views/MuiTemplate/SectionsPage/SectionsPage.js";
// import ShoppingCartPage from "views/MuiTemplate/ShoppingCartPage/ShoppingCartPage.js";
// import SignupPage from "views/MuiTemplate/SignupPage/SignupPage.js";
// import ErrorPage from "views/MuiTemplate/ErrorPage/ErrorPage.js";

import MainLayout from "views/organisms/MainLayout/MainLayout";
import RecoverPasswordDialog from "./views/newViews/dialogs/RecoverPasswordDialog/RecoverPasswordDialog";
import ResetPasswordDialog from "./views/newViews/dialogs/ResetPasswordDialog/ResetPasswordDialog";
import LoginDialog from "./views/newViews/dialogs/LoginDialog/LoginDialog";
import LinkedInLogin from "./views/atoms/LinkedInLogin/LinkedInLogin";
import RegisterDialog from "./views/newViews/dialogs/RegisterDialog/RegisterDialog";
import MyAccountPage from "./views/pages/MyAccountPage/MyAccountPage";
import LibraryPage from "views/pages/LibraryPage/LibraryPage";
import BoardsPage from "views/pages/BoardsPage/BoardsPage";
import ExplorePage from "./views/pages/ExplorePage/ExplorePage";
import PostDetailsPage from "./views/pages/PostDetailsPage/PostDetailsPage";
import BoardDetailsPage from "./views/pages/BoardDetailsPage/BoardDetailsPage";

import { Provider } from "react-redux";
import { store, history } from "./store/store";

import "./index.css";

import OtherUserPage from "./views/pages/OtherUserPage/OtherUserPage";
import SearchPage from "./views/pages/SearchPage/SearchPage";
import Paths from "./staticData/Paths";
import ConnectionsPage from "./views/pages/ConnectionsPage/ConnectionsPage";
import GroupDetailsPage from "./views/pages/GroupDetailsPage/GroupDetailsPage";
import FavoritesPage from "./views/pages/FavoritesPage/FavoritesPage";
import FollowingPage from "./views/pages/FollowingPage/FollowingPage";
import NotFoundPage from "./views/pages/NotFoundPage/NotFoundPage";
import ButtonsContainer from "./views/organisms/ButtonsContainer/ButtonsContainer";
import Cookies from "./components/CookieConsent/CookieConsent";
import HomePage from "./views/pages/HomePage/HomePage";
import PrivateRoute from "./context/PrivateRoute/PrivateRoute";

const App = () => (
  <>
    <MainLayout>
      <Switch>
        {/*MUI template routes START*/}
        {/*<Route path="/about-us" component={AboutUsPage} />*/}
        {/*<Route path="/blog-post" component={BlogPostPage} />*/}
        {/*<Route path="/blog-posts" component={BlogPostsPage} />*/}
        {/*<Route path="/components" component={ComponentsPage} />*/}
        {/*<Route path="/contact-us" component={ContactUsPage} />*/}
        {/*<Route path="/ecommerce-page" component={EcommercePage} />*/}
        {/*<Route path="/landing-page" component={LandingPage} />*/}
        {/*<Route path="/login-page" component={LoginPage} />*/}
        {/*<Route path="/pricing" component={PricingPage} />*/}
        {/*<Route path="/profile-page" component={ProfilePage} />*/}
        {/*<Route path="/product-page" component={ProductPage} />*/}
        {/*<Route path="/sections" component={SectionsPage} />*/}
        {/*<Route path="/shopping-cart-page" component={ShoppingCartPage} />*/}
        {/*<Route path="/signup-page" component={SignupPage} />*/}
        {/*<Route path="/error-page" component={ErrorPage} />*/}
        {/*<Route path="/presentation" component={PresentationPage} />*/}
        {/*MUI template routes END*/}

        <Route path={Paths.linkedInLogin} component={LinkedInLogin} />

        <Route path={Paths.notFound} component={NotFoundPage} />

        <Route path={Paths.register} component={RegisterDialog} />
        <Route path={Paths.login} component={LoginDialog} />
        <Route path={Paths.recoverPassword} component={RecoverPasswordDialog} />
        <Route path={Paths.resetPassword} component={ResetPasswordDialog} />

        <Route path={Paths.postDetails} component={PostDetailsPage} />
        <Route path={Paths.boardDetails} component={BoardDetailsPage} />
        <Route path={Paths.otherUserAccount} component={OtherUserPage} />

        <PrivateRoute path={Paths.groupDetails} component={GroupDetailsPage} />

        <PrivateRoute path={Paths.myAccount} component={MyAccountPage} />
        <PrivateRoute path={Paths.connections.general} component={ConnectionsPage} />
        <PrivateRoute path={Paths.favorites} component={FavoritesPage} />
        <PrivateRoute path={Paths.library} component={LibraryPage} />
        <PrivateRoute path={Paths.boards} component={BoardsPage} />
        <PrivateRoute path={Paths.following} component={FollowingPage} />
        <PrivateRoute path={Paths.explore} component={ExplorePage} />
        <PrivateRoute path={Paths.search} component={SearchPage} />

        <Route path={Paths.root} component={HomePage} />
      </Switch>
    </MainLayout>

    <Cookies />

    <ButtonsContainer />
  </>
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
