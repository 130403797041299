import { toastr } from "react-redux-toastr";
import { replace } from "connected-react-router";
import Paths from "../staticData/Paths";
import axios from "axios";

export const displayValidationMessage = (error, dispatch) => {
  const errorData = error.response?.data;

  if (axios.isCancel(error)) {
    return;
  }

  if (error.response.status >= 400 && error.response.status < 500) {
    errorData &&
      errorData.forEach((err) => {
        toastr.error("", err);
      });
  }

  if (error.response.status === 401) {
    !!dispatch && dispatch(replace(Paths.root));
  }

  if (error.response.status === 404) {
    !!dispatch && dispatch(replace(Paths.notFound));
  }

  return false;
};
