import React from "react";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import PropTypes from "prop-types";

const Map = withGoogleMap(({ lat, lng }) => (
  <GoogleMap defaultZoom={18} center={{ lat, lng }}>
    <Marker position={{ lat, lng }} />
  </GoogleMap>
));

export default function EventMap({ lat, lng }) {
  if (!!lat && !!lng) {
    return (
      <Map
        lat={lat}
        lng={lng}
        mapElement={<div style={{ height: `250px` }} />}
        containerElement={
          <div
            style={{
              height: `250px`,
              borderRadius: "6px",
              overflow: "hidden",
            }}
          />
        }
      />
    );
  } else {
    return null;
  }
}

EventMap.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};
