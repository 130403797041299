import { createSlice, createSelector } from "@reduxjs/toolkit";
import axios from "axios";
import Endpoints from "../staticData/Endpoints";
import { displayValidationMessage } from "../utils/validationDisplayHelper";

const myAccountSlice = createSlice({
  name: "myAccount",
  initialState: {
    profile: {},
    posts: [],
    boards: [],
  },
  reducers: {
    setMyAccountProfile(state, action) {
      state.profile = action.payload;
    },
    clearMyAccountProfile(state) {
      state.profile = {};
    },

    setMyAccountPosts(state, action) {
      action.payload.forEach((post) => {
        state.posts.push(post);
      });
    },
    clearMyAccountPosts(state) {
      state.posts = [];
    },

    setMyAccountBoards(state, action) {
      action.payload.forEach((post) => {
        state.boards.push(post);
      });
    },
    clearMyAccountBoards(state) {
      state.boards = [];
    },
  },
});

export const {
  setMyAccountProfile,
  clearMyAccountProfile,

  setMyAccountPosts,
  clearMyAccountPosts,

  setMyAccountBoards,
  clearMyAccountBoards,
} = myAccountSlice.actions;

export default myAccountSlice.reducer;

export const myAccountSelectors = {
  profile: createSelector(
    (state) => state.myAccount.profile,
    (profile) => profile
  ),
  posts: createSelector(
    (state) => state.myAccount.posts,
    (posts) => posts
  ),
  boards: createSelector(
    (state) => state.myAccount.boards,
    (boards) => boards
  ),
};

export const getMyAccountProfile = () => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  return axios({
    method: "GET",
    url: Endpoints.getMyAccount,
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      dispatch(setMyAccountProfile(data));
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};
