import React, { useState } from "react";
import "./LibraryEntityContent.scss";
import { Skeleton } from "@material-ui/lab";

export default function LibraryEntityContent({ elem }) {
  return (
    <div className={"entityContent"} style={{ minHeight: "50px" }}>
      <div dangerouslySetInnerHTML={{ __html: elem.intro }} />

      {!!elem.previewImageUrl && <ImageWithSkeleton elem={elem} />}
    </div>
  );
}

export function ImageWithSkeleton({ elem }) {
  const [imgLoaded, setImgLoaded] = useState(false);

  const handleOnLoad = () => setImgLoaded(true);

  const imageStyle = !imgLoaded ? { display: "none" } : {};

  return (
    <>
      {!imgLoaded && (
        <Skeleton variant={"rect"} animation={"wave"} height={"15rem"} />
      )}

      <img
        style={imageStyle}
        src={elem.previewImageUrl}
        alt={"preview of an attachment"}
        onLoad={handleOnLoad}
      />
    </>
  );
}
