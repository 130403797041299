import React, { useEffect, useRef, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation } from "react-router-dom";
import Dialog from "../../../../components/Dialog/Dialog";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../../../components/CustomInput/FormikInput";
import FormikSelect from "../../../../components/CustomInput/FormikSelect";
import ImageUpload from "../../../../components/CustomUpload/ImageUpload";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import {
  createBoard,
  createBoardAndNotify,
} from "../../../../store/generalBoardsSlice";
import { getTagSuggestions } from "../../../../store/generalPostsSlice";
import {
  closeCreateBoardDialog,
  dialogsSelectors,
} from "../../../../store/dialogsSlice";
import { replace } from "connected-react-router";
import { routerSelectors } from "../../../../store/routerActions";

const isPrivateOptions = [
  { id: false, name: "Public" },
  { id: true, name: "Private" },
];

export default function CreateBoardDialog() {
  const dispatch = useDispatch();

  const formRef = useRef();

  const { isOpen } = useSelector(dialogsSelectors.getCreateBoardDialogState);
  const locationState = useSelector(routerSelectors.getLocationState);

  const location = useLocation();
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [saveWithNotify, setSaveWithNotify] = useState(false);

  const clearEditor = () => setEditorState(EditorState.createEmpty());

  const handleClose = () => {
    clearEditor();
    dispatch(closeCreateBoardDialog());
  };

  const handleSubmit = (withNotify) => () => {
    if (withNotify) {
      setSaveWithNotify(true);
    }

    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleCreateBoard = async (formValues) => {
    setIsLoading(true);

    let success;

    if (saveWithNotify) {
      success = await dispatch(
        createBoardAndNotify({
          formValues,
          editorState,
          postId: locationState?.postId,
          backgroundImage,
        })
      );
    } else {
      success = await dispatch(
        createBoard({
          formValues,
          editorState,
          postId: locationState?.postId,
          backgroundImage,
        })
      );
    }

    setIsLoading(false);
    setSaveWithNotify(false);

    if (success) {
      handleClose();
      dispatch(replace(`${location.pathname}${location.search}`));
    }
  };

  const [tags, setTags] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    setEditorState(getStateWithMaxLengthEnsured(editorState, 160));
  };

  const getStateWithMaxLengthEnsured = (newState, maxLength) => {
    const contentState = newState.getCurrentContent();
    const oldContent = editorState.getCurrentContent();
    if (
      contentState === oldContent ||
      contentState.getPlainText().length <= maxLength
    ) {
      return newState;
    } else {
      return EditorState.undo(
        EditorState.push(
          editorState,
          ContentState.createFromText(oldContent.getPlainText()),
          "delete-character"
        )
      );
    }
  };

  const getTagOptions = (tags) =>
    tags.map((tag) => ({
      text: tag,
      value: tag.slice(1),
      url: "",
    }));

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);

      dispatch(getTagSuggestions()).then((data) => {
        setIsLoading(false);
        setTags(data);
      });
    }
  }, [dispatch, isOpen]);

  return (
    <Dialog
      isOpen={isOpen}
      isLoading={isLoading}
      title={"Create Board"}
      onClose={handleClose}
      actions={() => (
        <>
          <Button
            round
            color="success"
            disabled={isLoading}
            onClick={handleSubmit()}
            style={{ marginLeft: "1rem" }}
          >
            Create
          </Button>
          <Button
            round
            color="success"
            disabled={isLoading}
            onClick={handleSubmit(true)}
            style={{ marginLeft: "1rem" }}
          >
            Create Notify
          </Button>
        </>
      )}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          isPrivate: false,
          title: "",
        }}
        validationSchema={Yup.object({
          isPrivate: Yup.boolean().required("Board visibility is required."),
          title: Yup.string().required("Board title is required."),
        })}
        onSubmit={handleCreateBoard}
      >
        <Form>
          <FormikSelect
            id={"createBoard-isPrivate-select"}
            name={"isPrivate"}
            placeholder={"Visibility"}
            options={isPrivateOptions}
          />
          <FormikInput
            id={"createBoard-title-text"}
            name={"title"}
            type={"text"}
            placeholder={"Title"}
            maxLength={80}
          />

          <p>Details</p>
          <Editor
            editorState={editorState}
            stripPastedStyles
            toolbarHidden
            onEditorStateChange={onEditorStateChange}
            editorStyle={{
              minHeight: "25vh",
              border: `1px solid rgba(0,0,0,0.1)`,
              padding: `5px`,
            }}
            toolbar={{
              options: ["inline", "list"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            mention={{
              separator: " ",
              trigger: "#",
              suggestions: getTagOptions(tags),
            }}
            hashtag={{
              separator: " ",
              trigger: "#",
            }}
          />

          <p style={{ marginTop: "1rem" }}>Background Image:</p>
          <ImageUpload
            onChange={setBackgroundImage}
            acceptedFileType={"image/*"}
            imageStyle={{ maxHeight: "100px", width: "auto" }}
            addButtonProps={{ round: true, size: "sm" }}
            changeButtonProps={{ round: true, size: "sm" }}
            removeButtonProps={{ round: true, size: "sm", color: "danger" }}
          />
        </Form>
      </Formik>
    </Dialog>
  );
}
