import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Paths from "staticData/Paths";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import { push } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";
import { blackColor, hexToRgb, title } from "assets/jss/material-kit-pro-react";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";
import { MoreVert } from "@material-ui/icons";

const useStyles = makeStyles({
  userAvatar: {
    boxShadow:
      "0 5px 15px -8px rgba(" +
      hexToRgb(blackColor) +
      ", 0.24), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    borderRadius: "50% !important",
    maxWidth: "100%",
    height: "100px",
    width: "100px",
    minWidth: "100px",
    cursor: "pointer",
  },
  title: {
    ...title,
    minHeight: "unset",
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
  imageDropdownButton: {
    padding: "0px",
    borderRadius: "50%",
    marginLeft: "5px",
  },
  superCentered: {
    display: "grid",
    placeItems: "center",
  },
  dropdownIcons: {
    width: "24px",
    height: "24px",
    marginRight: "5px",
    opacity: "0.7",
    marginTop: "-4px",
    verticalAlign: "middle",
    fontSize: "24px",
    position: "relative",
  },
  cardOptions: {
    position: "absolute",
    top: "-10px",
    right: "-10px",
    justifyContent: "flex-end",
    display: "flex",
    flex: "1",
  },
  userContentContainer: {
    display: "grid",
    gridGap: "1rem",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  cardContainer: {
    display: "grid",
    position: "relative",
    gridTemplateColumns: "1fr",
  },
});

function TagsCard({ options: { optionsList, handleActions }, tagName }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  function handleTagClick() {
    const strippedTag = tagName?.replace("#", "");

    dispatch(push(Paths.searchByTag.replace(":tagParam", strippedTag)));
  }

  const handleSelectAction = ({ option, event }) =>
    handleActions?.({ option, event });

  const getDropdownList = useCallback(() => optionsList, [optionsList]);

  return (
    <Card blog style={{ margin: "0" }}>
      <CardBody>
        <div className={classes.cardContainer}>
          <h3
            className={classes.title}
            onClick={handleTagClick}
            title={tagName}
          >
            {tagName}
          </h3>

          <div className={classes.cardOptions}>
            {optionsList && handleActions && (
              <CustomDropdown
                caret={false}
                hoverColor="primary"
                buttonText={<MoreVert />}
                onClick={(option, event) =>
                  handleSelectAction({ option, event })
                }
                dropPlacement={"bottom-end"}
                buttonProps={{
                  className: classes.imageDropdownButton,
                  color: "transparent",
                  justIcon: true,
                }}
                dropdownList={getDropdownList()}
              />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default TagsCard;

TagsCard.propTypes = {
  tagName: PropTypes.string.isRequired,
  options: PropTypes.shape({
    optionsList: PropTypes.arrayOf(PropTypes.string),
    handleActions: PropTypes.func,
  }),
};

TagsCard.defaultProps = {
  options: {},
};
