import React, { useState } from "react";
import FollowUnfollow from "../../molecules/FollowUnfollow/FollowUnfollow";
import { dialogsSelectors, openUnfollowBoardDialog } from "../../../store/dialogsSlice";
import { makeStyles } from "@material-ui/core/styles";
import libraryPageStyle from "../../ArticlesView/LibraryPageStyle";
import { useDispatch, useSelector } from "react-redux";
import PrivateBoardIcon from "../../molecules/PrivateBoardIcon/PrivateBoardIcon";
import { followBoard, getBoardDetails } from "../../../store/generalBoardsSlice";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(libraryPageStyle);

function FollowUnfollowBoard() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const classes = useStyles();
  const boardData = useSelector(dialogsSelectors.getContextBoard);
  const { isFollowing = false } = boardData;

  const [isLoading, setIsLoading] = useState(false);

  async function handleFollowBoard() {
    setIsLoading(true);
    const success = await dispatch(followBoard({ id }));
    setIsLoading(false);

    if (success) {
      dispatch(getBoardDetails({ id }));
    }
  }

  return (
    <div className={classes.follow} style={{ position: "relative" }}>
      <div
        className={classes.container}
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "row-reverse",
          top: "-25px",
        }}
      >
        <FollowUnfollow
          isLoading={isLoading}
          isFollowing={isFollowing}
          tooltipMessages={{
            forFollow: "Follow this board",
            forUnfollow: "Unfollow this board",
          }}
          onFollowClick={handleFollowBoard}
          onUnfollowClick={() => dispatch(openUnfollowBoardDialog())}
        />

        <PrivateBoardIcon boardData={boardData} classes={classes} />
      </div>
    </div>
  );
}

export default FollowUnfollowBoard;
