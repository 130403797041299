import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { routerSelectors } from "../../../store/routerActions";
import { loginWithLinkedIn } from "../../../store/authenticationSlice";
import { push } from "connected-react-router";
import Paths from "../../../staticData/Paths";

export default function LinkedInLogin() {
  const dispatch = useDispatch();
  const code = useSelector(routerSelectors.getQueryParamsByName("code"));
  const error = useSelector(routerSelectors.getQueryParamsByName("error"));

  useEffect(() => {
    if (code) {
      dispatch(loginWithLinkedIn({ code }));
    }
  }, [dispatch, code]);

  useEffect(() => {
    if (error) {
      dispatch(push(Paths.root));
    }
  }, [dispatch, error]);

  return <></>;
}
