import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation } from "react-router-dom";
import Dialog from "../../../../components/Dialog/Dialog";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../../../components/CustomInput/FormikInput";
import FormikSelect from "../../../../components/CustomInput/FormikSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  closeEditBoardDialog,
  dialogsSelectors,
} from "../../../../store/dialogsSlice";
import BoardAttachments from "../../../molecules/BoardAttachments/BoardAttachments";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import {
  editBoard,
  getBoardDetails,
} from "../../../../store/generalBoardsSlice";
import { authenticationSelectors } from "../../../../store/authenticationSlice";
import { getTagSuggestions } from "../../../../store/generalPostsSlice";
import { replace } from "connected-react-router";

const isPrivateOptions = [
  { id: false, name: "Public" },
  { id: true, name: "Private" },
];

export default function EditBoardDialog() {
  const dispatch = useDispatch();

  const formRef = useRef();

  const authTokens = useSelector(authenticationSelectors.authTokens);
  const boardData = useSelector(dialogsSelectors.getContextBoard);
  const { isOpen } = useSelector(dialogsSelectors.getEditBoardDialogState);
  const { id } = boardData;

  const location = useLocation();

  const [boardDetails, setBoardDetails] = useState({});
  const [backgroundImage, setBackgroundImage] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const createEditorInitialState = useCallback((html) => {
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    return EditorState.createWithContent(contentState);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setBoardDetails({});
      setBackgroundImage({});
      setEditorState(createEditorInitialState(""));
    }
  }, [isOpen, createEditorInitialState]);

  useEffect(() => {
    if (isOpen && id) {
      setIsLoading(true);

      dispatch(getBoardDetails({ id })).then((data) => {
        setIsLoading(false);

        setBoardDetails(data);
        setEditorState(createEditorInitialState(data.description));
      });
    }
  }, [dispatch, id, authTokens, createEditorInitialState, isOpen]);

  const handleClose = () => dispatch(closeEditBoardDialog());

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleEditBoard = async (formValues) => {
    setIsLoading(true);

    const success = await dispatch(
      editBoard({
        id,
        formValues,
        editorState,
        backgroundImage,
      })
    );

    setIsLoading(false);

    if (success) {
      handleClose();
      dispatch(replace(`${location.pathname}${location.search}`));
    }
  };

  const [tags, setTags] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const getTagOptions = (tags) =>
    tags.map((tag) => ({
      text: tag,
      value: tag.slice(1),
      url: "",
    }));

  useEffect(() => {
    if (isOpen) {
      dispatch(getTagSuggestions()).then((data) => setTags(data));
    }
  }, [dispatch, isOpen]);

  return (
    <Dialog
      isOpen={isOpen}
      isLoading={isLoading}
      title={"Edit Board"}
      onClose={handleClose}
      actions={() => (
        <Button
          round
          color="success"
          disabled={isLoading}
          onClick={handleSubmit}
          style={{ marginLeft: "1rem" }}
        >
          Submit
        </Button>
      )}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          isPrivate: (boardDetails?.private === true ? "true" : "false") || "",
          title: boardDetails?.title || "",
        }}
        validationSchema={Yup.object({
          isPrivate: Yup.boolean().required("Board visibility is required."),
          title: Yup.string().required("Board title is required."),
        })}
        onSubmit={handleEditBoard}
      >
        <Form>
          <FormikSelect
            id={"editBoard-isPrivate-select"}
            name={"isPrivate"}
            placeholder={"Visibility"}
            options={isPrivateOptions}
            disabled
          />

          <FormikInput
            id={"editBoard-title-text"}
            name={"title"}
            type={"text"}
            placeholder={"Title"}
          />

          <p style={{ margin: "0.5rem 0 0" }}>Details</p>
          <Editor
            editorState={editorState}
            stripPastedStyles
            toolbarHidden
            onEditorStateChange={onEditorStateChange}
            editorStyle={{
              minHeight: "25vh",
              border: `1px solid rgba(0,0,0,0.1)`,
              padding: `5px`,
            }}
            toolbar={{
              options: ["inline", "list"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            mention={{
              separator: " ",
              trigger: "#",
              suggestions: getTagOptions(tags),
            }}
            hashtag={{
              separator: " ",
              trigger: "#",
            }}
          />

          <br />
          <BoardAttachments
            key={isOpen}
            initialValue={boardDetails?.backgroundImage || ""}
            onChange={setBackgroundImage}
          />
        </Form>
      </Formik>
    </Dialog>
  );
}
