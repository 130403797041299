export const postPrivacyTypes = [
  { id: false, name: "Public" },
  { id: true, name: "Private" },
];

export const postTypes = [
  { id: "article", name: "Article" },
  { id: "case", name: "Case" },
  { id: "question", name: "Question" },
  { id: "challenge", name: "Challenge" },
  { id: "event", name: "Event" },
];
