import React, { useState } from "react";
import Dialog from "../../../../components/Dialog/Dialog";
import Button from "../../../../components/CustomButtons/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  dialogsSelectors,
  closeDeleteGroupDialog,
} from "../../../../store/dialogsSlice";
import { deleteGroup } from "../../../../store/connectionsSlice";

function DeleteGroupDialog() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {
    isOpen,
    data: { id },
  } = useSelector(dialogsSelectors.getDeleteGroupDialogState);

  const handleClose = () => dispatch(closeDeleteGroupDialog());

  async function handleDeleteGroup() {
    setIsLoading(true);
    const success = await dispatch(deleteGroup(id));
    setIsLoading(false);

    if (success) {
      handleClose();
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      isLoading={isLoading}
      title={"Delete Group"}
      actions={() => (
        <Button
          round
          color="danger"
          style={{ marginLeft: "1rem" }}
          onClick={handleDeleteGroup}
        >
          Delete
        </Button>
      )}
      onClose={handleClose}
    >
      <p>Are you sure you want to delete this group?</p>
      <hr />
    </Dialog>
  );
}

export default DeleteGroupDialog;
