import { createSlice, createSelector } from "@reduxjs/toolkit";
import axios from "axios";
import Endpoints from "../staticData/Endpoints";
import { displayValidationMessage } from "../utils/validationDisplayHelper";

const otherUserSlice = createSlice({
  name: "otherUser",
  initialState: {
    profile: {},
    posts: [],
    boards: [],
  },
  reducers: {
    setOtherUserProfile(state, action) {
      state.profile = action.payload;
    },
    clearOtherUserProfile(state) {
      state.profile = {};
    },

    setOtherUserPosts(state, action) {
      action.payload.forEach((post) => {
        state.posts.push(post);
      });
    },
    clearOtherUserPosts(state) {
      state.posts = [];
    },

    setOtherUserBoards(state, action) {
      action.payload.forEach((post) => {
        state.boards.push(post);
      });
    },
    clearOtherUserBoards(state) {
      state.boards = [];
    },
  },
});

export const {
  setOtherUserProfile,
  clearOtherUserProfile,

  setOtherUserPosts,
  clearOtherUserPosts,

  setOtherUserBoards,
  clearOtherUserBoards,
} = otherUserSlice.actions;

export default otherUserSlice.reducer;

export const otherUserSelectors = {
  profile: createSelector(
    (state) => state.otherUser.profile,
    (profile) => profile
  ),
  posts: createSelector(
    (state) => state.otherUser.posts,
    (posts) => posts
  ),
  boards: createSelector(
    (state) => state.otherUser.boards,
    (boards) => boards
  ),
};
export const getOtherUserProfile = ({ userId }) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  return axios({
    method: "GET",
    url: Endpoints.getUserProfileData,
    params: { userId },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      dispatch(setOtherUserProfile(data));
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};
