import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Person from "@material-ui/icons/Person";
import Settings from "@material-ui/icons/Settings";
import Description from "@material-ui/icons/Description";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import NavPills from "components/NavPills/NavPills.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import { useDispatch } from "react-redux";
import MyAccountProfileIcon from "../../templates/myAccount/MyAccountProfileIcon/MyAccountProfileIcon";
import MyAccountProfileView from "../../templates/myAccount/MyAccountProfileView/MyAccountProfileView";
import MyAccountPostsView from "../../newViews/posts/MyAccountPostsView/MyAccountPostsView";
import MyAccountBoardsView from "../../newViews/boards/MyAccountBoardsView/MyAccountBoardsView";
import {
  clearMyAccountProfile,
  getMyAccountProfile,
} from "../../../store/myAccountSlice";
import MyAccountOptionsView from "../../templates/myAccount/MyAccountOptionsView/MyAccountOptionsView";

const useStyles = makeStyles(profilePageStyle);

export default function MyAccountPage() {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getMyAccountProfile());

    return () => dispatch(clearMyAccountProfile());
  }, [dispatch]);

  return (
    <div style={{ paddingTop: "250px" }}>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <MyAccountProfileIcon />

          <div className={classes.profileTabs}>
            <NavPills
              alignCenter
              color="primary"
              tabs={[
                {
                  tabButton: "Posts",
                  tabIcon: Description,
                  tabContent: <MyAccountPostsView />,
                },
                {
                  tabButton: "Boards",
                  tabIcon: DeveloperBoardIcon,
                  tabContent: <MyAccountBoardsView />,
                },
                {
                  tabButton: "Profile",
                  tabIcon: Person,
                  tabContent: <MyAccountProfileView />,
                },
                {
                  tabButton: "Options",
                  tabIcon: Settings,
                  tabContent: <MyAccountOptionsView />,
                },
              ]}
            />
          </div>
          <Clearfix />
        </div>
      </div>
    </div>
  );
}
