import React from "react";
import { useField } from "formik";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { ListSubheader } from "@material-ui/core";

const useStyles = makeStyles(basicsStyle);

const FormikSelect = (props) => {
  const classes = useStyles();

  const { id, name, placeholder, options, disabled } = props;

  const renderOptions = options.map((option) => {
    const { header, id, name, onClick } = option;

    if (header) {
      return (
        <ListSubheader
          classes={{ root: classes.selectMenuItem }}
          style={{ fontWeight: "bold", fontSize: "large" }}
          key={id}
          onClick={onClick}
        >
          {name}
        </ListSubheader>
      );
    }

    return (
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        key={id}
        value={id}
      >
        {name.length < 100 ? name : `${name.slice(0, 100)}...`}
      </MenuItem>
    );
  });

  const [field, meta] = useField(props);

  return (
    <>
      <FormControl
        fullWidth
        className={classes.selectFormControl}
        disabled={disabled}
      >
        <InputLabel htmlFor={id} className={classes.selectLabel}>
          {placeholder}
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          {...field}
          inputProps={{
            name,
            id,
          }}
        >
          {renderOptions}
        </Select>
      </FormControl>
      {meta.touched && meta.error ? (
        <p
          style={{
            fontSize: "12px",
            color: "red",
            position: "relative",
            top: "-15px",
          }}
        >
          {meta.error}
        </p>
      ) : null}
    </>
  );
};

export default FormikSelect;
