import React from "react";
import Add from "@material-ui/icons/Add";
import Button from "../../../components/CustomButtons/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { openCreateBoardDialog } from "../../../store/dialogsSlice";
import { useDispatch } from "react-redux";

function CreateBoardButton() {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(openCreateBoardDialog());
  };

  return (
    <Tooltip placement={"left"} title={"Create Board"}>
      <Button
        round
        justIcon
        size={"lg"}
        color={"primary"}
        onClick={handleOnClick}
      >
        <Add />
      </Button>
    </Tooltip>
  );
}

export default CreateBoardButton;
