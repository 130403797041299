import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  followingSelectors,
  getFollowingTags,
  unfollowTag,
  removeFollowingTag,
  resetFollowingTags,
} from "store/followingSlice";
import TagsCard from "views/organisms/TagsCard/TagsCard";
import SizedStackGrid from "../../../components/SizedStackGrid/SizedStackGrid";
import Dialog from "../../../components/Dialog/Dialog";
import Button from "../../../components/CustomButtons/Button";

function TagsFollowing() {
  const dispatch = useDispatch();
  const tags = useSelector(followingSelectors.tags);
  const [
    isConfirmUnfollowDialogOpen,
    setIsConfirmUnfollowDialogOpen,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState({});

  useEffect(() => {
    dispatch(getFollowingTags());

    return () => dispatch(resetFollowingTags());
  }, [dispatch]);

  const handleUnfollow = async () => {
    setIsLoading(true);
    const success = await dispatch(unfollowTag({ tagName: selectedTag }));
    setIsLoading(false);

    if (success) {
      setIsConfirmUnfollowDialogOpen(false);
      dispatch(removeFollowingTag(selectedTag));
    }
  };

  const handleActions = ({ tag }) => ({ option, event }) => {
    event.stopPropagation();

    switch (option) {
      case "Unfollow": {
        setSelectedTag(tag);
        setIsConfirmUnfollowDialogOpen(true);
        break;
      }
      default: {
        console.log(option);
      }
    }
  };

  const getDropdownList = useCallback(() => {
    return ["Unfollow"];
  }, []);

  return (
    <div style={{ padding: "1rem 0" }}>
      <SizedStackGrid>
        {tags.map((tag) => {
          return (
            <TagsCard
              key={tag}
              tagName={tag}
              options={{
                optionsList: getDropdownList(),
                handleActions: handleActions({ tag }),
              }}
            />
          );
        })}
      </SizedStackGrid>
      {!tags.length ? (
        <div style={{ display: "grid", placeItems: "center" }}>
          <p>Please follow a Tag in order to see it here.</p>
        </div>
      ) : null}
      <Dialog
        title={"Confirm Unfollow"}
        isLoading={isLoading}
        isOpen={isConfirmUnfollowDialogOpen}
        onClose={() => setIsConfirmUnfollowDialogOpen(false)}
        actions={() => (
          <Button
            round
            color="success"
            style={{ marginLeft: "1rem" }}
            onClick={handleUnfollow}
          >
            Confirm
          </Button>
        )}
      >
        <p>Are you sure you want to unfollow this tag?</p>
      </Dialog>
    </div>
  );
}

export default TagsFollowing;
