import React from "react";
import StandardPage from "components/StandardPage/StandardPage";
import TagsFollowing from "views/organisms/TagsFollowing/TagsFollowing";
import NavPills from "../../../components/NavPills/NavPills";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import UsersFollowing from "../../organisms/UsersFollowing/UsersFollowing";
import FollowingBoards from "../../newViews/boards/FollowingBoards/FollowingBoards";
import Person from "@material-ui/icons/Person";

export default function FollowingPage() {
  return (
    <StandardPage title={"Following"}>
      <NavPills
        alignCenter
        color="primary"
        tabs={[
          {
            tabButton: "Users",
            tabIcon: Person,
            tabContent: <UsersFollowing />,
          },

          {
            tabButton: "Boards",
            tabIcon: DeveloperBoardIcon,
            tabContent: <FollowingBoards />,
          },
          {
            tabButton: "Tags",
            tabIcon: LabelOutlinedIcon,
            tabContent: <TagsFollowing />,
          },
        ]}
      />
    </StandardPage>
  );
}
