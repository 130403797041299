import classNames from "classnames";
import { useSelector } from "react-redux";
import { otherUserSelectors } from "../../../../store/otherUserSlice";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import defaultAvatar from "../../../../assets/img/placeholder.jpg";
import OtherUserOptions from "../OtherUserOptions/OtherUserOptions";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import profilePageStyle from "../../../../assets/jss/material-kit-pro-react/views/profilePageStyle";

const useStyles = makeStyles(profilePageStyle);

export default function OtherUserProfileIcon() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  const profile = useSelector(otherUserSelectors.profile);

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={6}>
        <div className={classes.profile}>
          <div>
            <img
              src={profile.profilePicture || defaultAvatar}
              alt="user profile"
              className={imageClasses}
            />
          </div>
          <div className={classes.name}>
            <h3 className={classes.title}>{profile.userName}</h3>
          </div>
        </div>

        <OtherUserOptions />
      </GridItem>
    </GridContainer>
  );
}
