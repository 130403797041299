const Paths = {
  root: "/",
  notFound: "/pageNotFound",
  following: "/following",
  explore: "/explore",
  exploreCalendar: "/explore/calendar",
  search: "/search",
  searchByTag: "/search?tag=:tagParam",
  searchByKeyword: "/search?key=:keyParam",

  library: "/library",
  libraryCalendar: "/library/calendar",
  createPost: "/library/create",
  postDetails: "/article/:id",
  favorites: "/favorites",

  login: "/login",
  linkedInLogin: "/linkedin-login",
  register: "/register",
  guestCode: "/guest-code",
  resetPassword: "/reset-password",
  recoverPassword: "/recover-password",

  boards: "/boards",
  createBoard: "/boards/create",
  boardDetails: "/board/:id",

  connections: {
    general: "/connections",
    people: "/connections/people",
    groups: "/connections/groups",
  },
  groupDetails: "/group/:id",

  myAccount: "/profile",
  otherUserAccount: "/user/:id",
};

export default Paths;
