import React from "react";
import StackGrid, { transitions } from "react-stack-grid";
import { SizeMe } from "react-sizeme";
import PropTypes from "prop-types";

function SizedStackGrid({ children, ...props }) {
  const { scaleDown } = transitions;

  return (
    <SizeMe>
      {({ size: { width } }) => (
        <StackGrid
          monitorImagesLoaded
          duration={0}
          gutterWidth={16}
          gutterHeight={16}
          appear={scaleDown.appear}
          appeared={scaleDown.appeared}
          enter={scaleDown.enter}
          entered={scaleDown.entered}
          leaved={scaleDown.leaved}
          columnWidth={width <= 550 ? "100%" : width <= 960 ? "50%" : "33%"}
          {...props}
        >
          {children}
        </StackGrid>
      )}
    </SizeMe>
  );
}

SizedStackGrid.propTypes = {
  children: PropTypes.node,
};

export default SizedStackGrid;
