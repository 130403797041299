import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import axios from "axios";
import Endpoints from "../staticData/Endpoints";
import { displayValidationMessage } from "../utils/validationDisplayHelper";
import { toastr } from "react-redux-toastr";

const favouritesSlice = createSlice({
  name: "favorites",
  initialState: {
    posts: [],
  },
  reducers: {
    setFavoritePosts(state, action) {
      action.payload.forEach((post) => {
        state.posts.push(post);
      });
    },
    removeFavouritePost(state, action) {
      state.posts = state.posts.filter(
        (post) => post.id !== action.payload
      );
    },
    clearFavoritePosts(state) {
      state.posts = [];
    },
  },
});

export const {
  setFavoritePosts,
  removeFavouritePost,
  clearFavoritePosts,
} = favouritesSlice.actions;

export default favouritesSlice.reducer;

export const favoritesSelectors = {
  posts: createSelector(
    (state) => state.favorites.posts,
    (posts) => posts
  ),
};

export const removePostFromFavorites = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { id } = data;

  return axios({
    method: "DELETE",
    url: Endpoints.removePostFromFavorites,
    params: { id },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        dispatch(removeFavouritePost(id));
        toastr.success("", "Post successfully removed from Favorites.");

        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};
