import React, { useState } from "react";
import Button from "../../../../components/CustomButtons/Button";
import Dialog from "../../../../components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { deleteBoard } from "../../../../store/generalBoardsSlice";
import {
  closeDeleteBoardDialog,
  dialogsSelectors,
} from "../../../../store/dialogsSlice";

function DeleteBoardDialog() {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(dialogsSelectors.getDeleteBoardDialogState);
  const { id } = useSelector(dialogsSelectors.getContextBoard);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => dispatch(closeDeleteBoardDialog());

  async function handleDeleteBoard() {
    setIsLoading(true);
    const success = await dispatch(deleteBoard({ id }));
    setIsLoading(false);

    if (success) {
      handleClose();
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      isLoading={isLoading}
      title={"Confirm Delete Board"}
      onClose={handleClose}
      actions={() => (
        <Button
          round
          color="danger"
          style={{ marginLeft: "1rem" }}
          onClick={handleDeleteBoard}
        >
          Delete
        </Button>
      )}
    >
      <p>Are you sure you want to delete this board?</p>
      <hr />
    </Dialog>
  );
}

export default DeleteBoardDialog;
