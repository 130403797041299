import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { useField } from "formik";
import debounce from "@material-ui/core/utils/debounce";

import styles from "assets/jss/material-kit-pro-react/components/customInputStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornmentIcon from "../InputAdornments/InputAdornmentIcon";
import Search from "@material-ui/icons/Search";
import Avatar from "@material-ui/core/Avatar";
import defaultAvatar from "assets/img/placeholder.jpg";

const useStyles = makeStyles(styles);

function FormikUsersMultiselect(props) {
  const classes = useStyles();

  const {
    handleInputChange,
    endAdornment,
    isLoading,
    placeholder,
    ...restProps
  } = props;

  const [field, meta, helpers] = useField(restProps);
  const { value } = meta;
  const { setValue } = helpers;

  const handleOnChange = (event, value, reason) => {
    if (reason === "create-option") {
      const newValues = [...value];

      const newOptionText = value[value.length - 1].trim();
      const newOption = {
        userName: newOptionText,
        email: newOptionText,
        userId: "",
        profilePicture: null,
      };

      newValues.pop();
      newValues.push(newOption);

      setValue(newValues);
    } else {
      setValue(value);
    }
  };

  const filterOptions = (options) =>
    options.reduce((acc, option) => {
      const isSelected = value.some((elem) => {
        if (elem.id) {
          return elem.id === option.id;
        } else if (elem.userId) {
          return elem.userId === option.userId;
        } else if (elem.groupId) {
          return elem.groupId === option.groupId;
        } else {
          return false;
        }
      });

      if (!isSelected) {
        acc.push(option);
      }

      return acc;
    }, []);

  return (
    <>
      <div style={{ display: "flex", flex: "1" }}>
        <Autocomplete
          {...field}
          {...restProps}
          fullWidth
          multiple
          freeSolo
          value={value}
          onChange={handleOnChange}
          onInputChange={debounce(handleInputChange, 300)}
          groupBy={(option) => (option.groupId ? "Groups" : "Users")}
          renderOption={(option) => {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  alt={option.userName}
                  src={option.profilePicture || defaultAvatar}
                />
                <span style={{ marginLeft: "1rem" }}>{option.userName}</span>
              </div>
            );
          }}
          filterOptions={filterOptions}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant={"outlined"}
                key={index}
                label={option.userName}
                avatar={
                  <Avatar
                    alt={option.userName}
                    src={option.profilePicture || defaultAvatar}
                  />
                }
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={placeholder || ""}
              variant={"standard"}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color={"inherit"} size={20} />
                    ) : null}
                    <InputAdornmentIcon
                      icon={Search}
                      adornmentClassName={classes.customMuiInputAdornmentRoot}
                    />
                  </>
                ),
              }}
            />
          )}
        />
      </div>

      {meta.touched && meta.error ? (
        <p
          style={{
            fontSize: "12px",
            color: "red",
            position: "relative",
          }}
        >
          {meta.error}
        </p>
      ) : null}
    </>
  );
}

export default FormikUsersMultiselect;
