import React from "react";
import StandardPage from "components/StandardPage/StandardPage";
import BoardsListView from "../../newViews/boards/BoardsListView/BoardsListView";

export default function BoardsPage() {
  return (
    <StandardPage title={"Boards"}>
      <BoardsListView />
    </StandardPage>
  );
}
