import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  followingSelectors,
  getFollowingUsers,
  resetFollowingUsers,
  removeFollowingUser,
  unfollowUser,
} from "../../../store/followingSlice";
import SizedStackGrid from "../../../components/SizedStackGrid/SizedStackGrid";
import { useInfiniteLoading } from "../../../utils/hooks/infiniteLoading";
import PersonCard from "../PersonCard/PersonCard";
import Button from "../../../components/CustomButtons/Button";
import Dialog from "../../../components/Dialog/Dialog";

function UsersFollowing() {
  const dispatch = useDispatch();
  const users = useSelector(followingSelectors.users);
  const [
    isConfirmUnfollowDialogOpen,
    setIsConfirmUnfollowDialogOpen,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    return () => dispatch(resetFollowingUsers());
  }, [dispatch]);

  const apiCall = useCallback((data) => getFollowingUsers(data), []);

  const { bottomBoundaryNode } = useInfiniteLoading({ apiCall });

  const handleUnfollow = async () => {
    const { id: userId } = selectedUser;

    setIsLoading(true);
    const success = await dispatch(unfollowUser({ userId }));
    setIsLoading(false);

    if (success) {
      setIsConfirmUnfollowDialogOpen(false);
      dispatch(removeFollowingUser(userId));
    }
  };

  const handleActions = ({ user }) => ({ option }) => {
    switch (option) {
      case "Unfollow": {
        setSelectedUser(user);
        setIsConfirmUnfollowDialogOpen(true);
        break;
      }
      default: {
        console.log(option);
      }
    }
  };

  const getDropdownList = useCallback(() => {
    return ["Unfollow"];
  }, []);

  return (
    <div style={{ padding: "1rem 0" }}>
      <SizedStackGrid>
        {users.map((user) => {
          const { id, userName, profilePicture } = user;

          return (
            <PersonCard
              key={user.id}
              person={{ id, userName, profilePicture }}
              options={{
                optionsList: getDropdownList(),
                handleActions: handleActions({ user }),
              }}
            />
          );
        })}
      </SizedStackGrid>
      {!users.length ? (
        <div style={{ display: "grid", placeItems: "center" }}>
          <p>Please follow a user in order to see them here.</p>
        </div>
      ) : null}
      {bottomBoundaryNode}
      <Dialog
        title={"Confirm Unfollow"}
        isLoading={isLoading}
        isOpen={isConfirmUnfollowDialogOpen}
        onClose={() => setIsConfirmUnfollowDialogOpen(false)}
        actions={() => (
          <Button
            round
            color="success"
            style={{ marginLeft: "1rem" }}
            onClick={handleUnfollow}
          >
            Confirm
          </Button>
        )}
      >
        <p>
          Are you sure you want to unfollow user{" "}
          <strong>{selectedUser.userName}</strong>?
        </p>
      </Dialog>
    </div>
  );
}

export default UsersFollowing;
