import { createSelector, createSlice } from "@reduxjs/toolkit";

const librarySlice = createSlice({
  name: "library",
  initialState: {
    posts: [],
  },
  reducers: {
    setLibraryPosts(state, action) {
      action.payload.forEach((post) => {
        state.posts.push(post);
      });
    },
    clearLibraryPosts(state) {
      state.posts = [];
    },
  },
});

export const { setLibraryPosts, clearLibraryPosts } = librarySlice.actions;

export default librarySlice.reducer;

export const librarySelectors = {
  posts: createSelector(
    (state) => state.library.posts,
    (posts) => posts
  ),
};
