import React, { useState, createRef, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import postAttachmentsStyles from "./PostAttachments.styles";
import { makeStyles } from "@material-ui/core/styles";
import { FileIcon, defaultStyles } from "react-file-icon";
import { checkConversionStatus, uploadFiles } from "../../../store/generalPostsSlice";
import { openFilePreviewDialog } from "../../../store/dialogsSlice";
import { getFileType } from "utils/files";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";
import Dialog from "../../../components/Dialog/Dialog";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../../components/CustomInput/FormikInput";
import InputAdornmentIcon from "../../../components/InputAdornments/InputAdornmentIcon";
import InsertLink from "@material-ui/icons/InsertLink";
import ReactPlayer from "react-player";
import Loading from "../../../components/Loading/Loading";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import download from "downloadjs";

const useStyles = makeStyles(postAttachmentsStyles);

const attachmentsOptions = [
  { id: "url", name: "Add Link" },
  { id: "file", name: "Pick a file" },
];

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 25 MB

function PostAttachments({
  attachments,
  setAttachments,
  setUploading,
  checkConversionStatus,
  openFilePreviewDialog,
  uploadFiles,
  displayMode,
  label,
}) {
  const classes = useStyles();

  const fileInput = createRef();
  const formRef = createRef();
  const [isAddLinkOpen, setIsAddLinkOpen] = useState(false);

  async function uploadAttachments(files) {
    const placeholderThumbnails = files.map((x, i) => ({
      conversionInProgress: true,
      url: x.name,
      id: `${Date.now()}-${i}`,
      type: "placeholder",
      file: x,
    }));

    const filesArray = placeholderThumbnails.map((x) => ({
      frontendId: x.id,
      file: x.file,
    }));

    setAttachments((x) => [...x, ...placeholderThumbnails]);
    setUploading((x) => true);
    const response = await uploadFiles(filesArray);
    setUploading((x) => false);

    if (response) {
      setAttachments((x) => {
        const attachmentsCopy = x.concat();

        response.forEach((file) => {
          const placeholderIndex = attachmentsCopy.findIndex(
            (placeholder) => placeholder.id === file.frontEndId
          );

          attachmentsCopy.splice(placeholderIndex, 1, {
            ...file,
            type: "file",
          });
        });

        return attachmentsCopy;
      });
    } else {
      setAttachments((x) => {
        const attachmentsCopy = x.concat();

        placeholderThumbnails.forEach((file) => {
          const placeholderIndex = attachmentsCopy.findIndex(
            (placeholder) => placeholder.id === file.id
          );

          attachmentsCopy.splice(placeholderIndex, 1);
        });

        return attachmentsCopy;
      });
    }
  }

  const filesWithConversionInProgress = useMemo(() => {
    return attachments
      ?.filter((file) => file?.conversionInProgress)
      .map((file) => file.url);
  }, [attachments]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const hasProcessingUploads = filesWithConversionInProgress?.length > 0;

      if (hasProcessingUploads) {
        const result = await checkConversionStatus(
          filesWithConversionInProgress
        );

        if (result) {
          const updateConversionStatus = (files) => {
            return files.map((file) => {
              if (typeof file === "object" && !result.includes(file.url)) {
                return { ...file, conversionInProgress: false };
              }
              return file;
            });
          };

          setAttachments(updateConversionStatus);
        }
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [checkConversionStatus, setAttachments, filesWithConversionInProgress]);

  function handleFileChange(e) {
    e.preventDefault();

    const files = [...e.target.files];

    if (!files) return;

    const allFilesAreSupported = files.reduce((acc, x) => {
      const fileType = getFileType(x.name).toLowerCase();
      const isFileTypeSupported = supportedFileTypes.includes(fileType);
      const isFileSizeValid = x.size <= MAX_FILE_SIZE;

      if (!isFileSizeValid) {
        toastr.error("", `File ${x.name} exceeds the maximum size of 100 MB.`);
      }
      if (!isFileTypeSupported) {
        toastr.error("", `File ${x.name} has an unsupported file type.`);
      }

      return isFileTypeSupported && isFileSizeValid && acc;
    }, true);

    if (allFilesAreSupported) {
      uploadAttachments(files);
    }
  }

  function handleAddLink(values) {
    setAttachments((x) => [...x, { type: "link", url: values.link }]);
    setIsAddLinkOpen(false);
  }

  function removeAttachment(fileUrl) {
    setAttachments((files) =>
      files.filter((file) =>
        typeof file === "string" ? file !== fileUrl : file.url !== fileUrl
      )
    );
  }

  const handleThumbnailOnClick = (file) => () => {
    if (displayMode === "edit") return;

    const fileType = getFileType(file);

    if (["doc", "docx", "ppt", "pptx", "xls", "xlsx"].includes(fileType)) {
      download(file);
      return;
    }

    openFilePreviewDialog({ file, attachments });
  };

  const handleDropdownOnClick = (option) => {
    switch (option?.id) {
      case "url": {
        setIsAddLinkOpen(true);
        break;
      }
      case "file": {
        fileInput.current.click();
        break;
      }
      default: {
        console.error("invalid option selected");
      }
    }
  };

  const supportedFileTypes = [
    "jpg",
    "png",
    "jpeg",

    "pdf",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "xls",
    "xlsx",

    "mp4",
    "video",
    "webm",
    "ogg",
    "avi",
    "mkv",
    "vob",
    "ogv",
    "rrc",
    "gifv",
    "mng",
    "mov",
    "qt",
    "wmv",
    "yuv",
    "rm",
    "asf",
    "amv",
    "m4p",
    "m4v",
    "mpg",
    "mp2",
    "mpeg",
    "mpe",
    "mpv",
    "svi",
    "3gp",
    "3g2",
    "mxf",
    "roq",
    "nsv",
    "flv",
    "f4v",
    "f4p",
    "f4a",
    "f4b",
  ];

  const acceptedFileTypes = supportedFileTypes.reduce((acc, elem, index) => {
    const extension = `.${elem}`;

    return acc.concat(index === 0 ? extension : `,${extension}`);
  }, "");

  function renderAttachmentsThumbnails() {
    return (
      <>
        {attachments?.map((attachment, index) => {
          const { url, conversionInProgress, type } = attachment;
          const fileType = getFileType(url)?.toLowerCase();

          if (type === "placeholder") {
            return (
              <div key={index} className={classes.container}>
                <Loading isLoading={conversionInProgress} size={"sm"}>
                  <div className={classes.generalExtensions}>
                    <FileIcon
                      extension={fileType}
                      {...defaultStyles[fileType]}
                    />
                  </div>
                </Loading>
                {renderDeleteAttachmentButton(url)}
              </div>
            );
          }

          if (type === "link") {
            return (
              <div key={`${url}-${index}`} className={classes.container}>
                <div className={classes.generalExtensions}>
                  {ReactPlayer.canPlay(url) ? (
                    <div onClick={handleThumbnailOnClick(url)} title={url}>
                      <FileIcon extension={"video"} {...defaultStyles["mov"]} />
                    </div>
                  ) : (
                    <div onClick={handleThumbnailOnClick(url)} title={url}>
                      <FileIcon extension={"html"} {...defaultStyles["html"]} />
                    </div>
                  )}
                </div>
                {renderDeleteAttachmentButton(url)}
              </div>
            );
          }

          if (
            [
              "pdf",

              "mp4",
              "webm",
              "ogg",

              "video",
              "avi",
              "mkv",
              "vob",
              "ogv",
              "rrc",
              "gifv",
              "mng",
              "mov",
              "qt",
              "wmv",
              "yuv",
              "rm",
              "asf",
              "amv",
              "m4p",
              "m4v",
              "mpg",
              "mp2",
              "mpeg",
              "mpe",
              "mpv",
              "svi",
              "3gp",
              "3g2",
              "mxf",
              "roq",
              "nsv",
              "flv",
              "f4v",
              "f4p",
              "f4a",
              "f4b",

              "doc",
              "docx",
              "ppt",
              "pptx",
              "xls",
              "xlsx",
            ].includes(fileType)
          ) {
            return (
              <div key={`${url}-${index}`} className={classes.container}>
                <Loading isLoading={conversionInProgress} size={"sm"}>
                  <div
                    className={classes.generalExtensions}
                    onClick={handleThumbnailOnClick(url)}
                  >
                    <FileIcon
                      extension={fileType}
                      {...defaultStyles[fileType]}
                    />
                  </div>
                </Loading>
                {renderDeleteAttachmentButton(url)}
              </div>
            );
          }

          if (["jpg", "png", "jpeg"].includes(fileType)) {
            return (
              <div key={`${url}-${index}`} className={classes.container}>
                <div onClick={handleThumbnailOnClick(url)}>
                  <img
                    src={url}
                    alt={"post attachment"}
                    className={classes.imgAttachment}
                  />
                </div>
                {renderDeleteAttachmentButton(url)}
              </div>
            );
          }

          return null;
        })}
      </>
    );
  }

  function renderDeleteAttachmentButton(url) {
    if (displayMode !== "edit") return;

    return (
      <Button
        round
        className={classes.deleteBtn}
        size={"sm"}
        color={"danger"}
        onClick={() => removeAttachment(url)}
      >
        <Close />
        Delete
      </Button>
    );
  }

  return (
    <div>
      {displayMode === "edit" && (
        <div>
          <input
            key={attachments?.length}
            type={"file"}
            multiple={true}
            ref={fileInput}
            onChange={handleFileChange}
            accept={acceptedFileTypes}
            style={{ display: "none" }}
          />
          <CustomDropdown
            caret={false}
            hoverColor="primary"
            buttonText={""}
            buttonIcon={() => <PhotoCamera />}
            onClick={handleDropdownOnClick}
            disablePortal
            dropPlacement={"top-start"}
            buttonProps={{
              color: "primary",
              round: true,
              justIcon: true,
            }}
            dropdownList={attachmentsOptions}
          />
        </div>
      )}

      {attachments?.length > 0 && <p className={classes.label}>{label}</p>}
      <div className={classes.wrapper}>{renderAttachmentsThumbnails()}</div>

      <Dialog
        title={"Add a link"}
        isOpen={isAddLinkOpen}
        onClose={() => setIsAddLinkOpen(false)}
        actions={() => (
          <Button
            style={{ marginLeft: "1rem" }}
            round
            color="primary"
            onClick={() => formRef.current.submitForm()}
          >
            Add Link
          </Button>
        )}
      >
        <Formik
          innerRef={formRef}
          initialValues={{ link: "" }}
          validationSchema={Yup.object({
            link: Yup.string()
              .required("Link is required")
              .url("Please insert a valid URL."),
          })}
          onSubmit={handleAddLink}
        >
          <Form>
            <FormikInput
              id={"login-commentEmail-input"}
              name={"link"}
              type={"text"}
              placeholder="Link"
              endAdornment={<InputAdornmentIcon icon={InsertLink} />}
            />
          </Form>
        </Formik>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authTokens: state.authentication.authTokens,
});

const mapDispatchToProps = {
  checkConversionStatus,
  uploadFiles,
  openFilePreviewDialog,
};

PostAttachments.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["file", "link", "placeholder"]),
      url: PropTypes.string,
    })
  ),
  setAttachments: PropTypes.func,
  checkConversionStatus: PropTypes.func,
  openFilePreviewDialog: PropTypes.func,
  uploadFiles: PropTypes.func,
  displayMode: PropTypes.oneOf(["view", "edit"]),
  label: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostAttachments);
