import { useDispatch, useSelector } from "react-redux";
import React, { createRef } from "react";
import classNames from "classnames";
import { uploadProfilePicture } from "../../../../store/authenticationSlice";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import defaultAvatar from "../../../../assets/img/placeholder.jpg";
import Button from "../../../../components/CustomButtons/Button";
import Edit from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import { myAccountSelectors } from "../../../../store/myAccountSlice";

const useStyles = makeStyles(profilePageStyle);

export default function MyAccountProfileIcon() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const fileInput = createRef();

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  const { profilePicture, userName } = useSelector(myAccountSelectors.profile);

  const handleClick = () => fileInput.current.click();

  const handleImageChange = async (event) => {
    event.preventDefault();

    const file = event.target.files[0];

    await dispatch(uploadProfilePicture({ file }));
  };

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={6}>
        <div className={classes.profile}>
          <div>
            <img
              src={profilePicture || defaultAvatar}
              alt="My account profile avatar"
              className={imageClasses}
            />
            <Button
              color="primary"
              justIcon
              round
              onClick={handleClick}
              style={{
                position: "absolute",
                transform: "translate(-44px, 34px)",
                border: "2px solid white",
              }}
            >
              <Edit />
            </Button>
            <input
              type={"file"}
              ref={fileInput}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </div>
          <div className={classes.name}>
            <h3 className={classes.title}>{userName}</h3>
          </div>
        </div>
      </GridItem>
    </GridContainer>
  );
}
