import React from "react";
import { push } from "connected-react-router";
import DateRange from "@material-ui/icons/DateRange";
import Paths from "../../../staticData/Paths";
import Button from "../../../components/CustomButtons/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch } from "react-redux";

export default function ExploreCalendarButton() {
  const dispatch = useDispatch();

  const handleOnClick = () => dispatch(push(Paths.exploreCalendar));

  return (
    <Tooltip placement={"left"} title={"Show Explore Calendar"}>
      <div>
        <Button
          color={"primary"}
          round
          justIcon
          size={"lg"}
          onClick={handleOnClick}
        >
          <DateRange />
        </Button>
      </div>
    </Tooltip>
  );
}
