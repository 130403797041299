import React from "react";
import PropTypes from "prop-types";
import CustomInput from "../../../components/CustomInput/CustomInput";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import moment from "moment";
import EventMap from "../EventMap/EventMap";

function EventDetails({ eventData }) {
  const {
    title,
    address,
    startDate,
    endDate,
    phoneNumber,
    webinarLink,
    latitude,
    longitude,
  } = eventData;

  return (
    <div>
      <GridContainer justify={"center"}>
        <GridItem>
          <CustomInput
            id={"event-title"}
            labelText={"Title"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: title || " ",
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem sm={12} md={6}>
          <CustomInput
            id={"event-startTime"}
            labelText={"Start Time"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: moment(`${startDate}Z`).format("LLL") || " ",
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem sm={12} md={6}>
          <CustomInput
            id={"event-endTime"}
            labelText={"End Time"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: moment(`${endDate}Z`).format("LLL") || " ",
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem sm={12} md={6}>
          <CustomInput
            id={"event-phoneNumber"}
            labelText={"Phone Number"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: phoneNumber || " ",
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem sm={12} md={6}>
          <CustomInput
            id={"event-webinarLink"}
            labelText={"Webinar Link"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: webinarLink || " ",
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem>
          <CustomInput
            id={"event-address"}
            labelText={"Address"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: address || " ",
              disabled: true,
            }}
          />
        </GridItem>
      </GridContainer>

      <EventMap lat={latitude} lng={longitude} />
      <hr />
    </div>
  );
}

export default EventDetails;

EventDetails.propTypes = {
  eventData: PropTypes.shape({
    title: PropTypes.string,
    address: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    phoneNumber: PropTypes.string,
    webinarLink: PropTypes.string,
  }),
};
