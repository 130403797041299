import React, { useEffect, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import postAttachmentsStyles from "./BoardAttachments.styles";
import { makeStyles } from "@material-ui/core/styles";
import ImageUpload from "../../../components/CustomUpload/ImageUpload";

const useStyles = makeStyles(postAttachmentsStyles);

export default function BoardAttachments({ onChange, initialValue }) {
  const classes = useStyles();

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [backgroundImage, setBackgroundImage] = React.useState(null);

  useEffect(() => {
    initialValue && setUploadedFiles([initialValue]);
  }, [initialValue]);

  useEffect(() => {
    onChange({ backgroundImage, uploadedFiles });
  }, [backgroundImage, uploadedFiles, onChange]);

  function removeAttachment(fileUrl) {
    setUploadedFiles((files) => files.filter((file) => file !== fileUrl));
  }

  function renderUploadedFiles() {
    return uploadedFiles.map((file) => (
      <div key={file} className={classes.container}>
        <img
          src={file}
          onClick={() => window.open(file, "_blank")}
          alt={"post attachment"}
          className={classes.imgAttachment}
        />
        <Button
          round
          size={"sm"}
          color={"danger"}
          onClick={() => removeAttachment(file)}
        >
          <Close />
          Remove
        </Button>
      </div>
    ));
  }

  return (
    <div>
      <p className={classes.label}>Background Image:</p>
      {uploadedFiles.length > 0 && (
        <div className={classes.wrapper}>{renderUploadedFiles()}</div>
      )}
      {uploadedFiles.length === 0 && (
        <ImageUpload
          onChange={setBackgroundImage}
          acceptedFileType={"image/*"}
          imageStyle={{
            maxHeight: "100px",
            width: "auto",
          }}
          addButtonProps={{ round: true, size: "sm" }}
          changeButtonProps={{ round: true, size: "sm" }}
          removeButtonProps={{
            round: true,
            size: "sm",
            color: "danger",
          }}
        />
      )}
    </div>
  );
}
