import React, { useMemo } from "react";
import Button from "../../../components/CustomButtons/Button";
import { push } from "connected-react-router";
import Paths from "../../../staticData/Paths";
import { useDispatch, useSelector } from "react-redux";
import { routerSelectors } from "../../../store/routerActions";
import { makeStyles } from "@material-ui/core/styles";
import { container } from "../../../assets/jss/material-kit-pro-react";

const useStyles = makeStyles({
  container,
  tagBtn: {
    "&:hover": {
      color: "error",
    },
  },
});

export default function TagList() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const tagParams = useSelector(routerSelectors.getQueryTagParams);
  const tags = useMemo(() => (tagParams?.length ? tagParams.split(",") : []), [
    tagParams,
  ]);

  const handleTagClick = (tag) => () => {
    const formatSearchTags = tags.filter((e) => e !== tag).join(",");

    if (!formatSearchTags.length) {
      return dispatch(push(Paths.explore));
    }

    dispatch(push(Paths.searchByTag.replace(":tagParam", formatSearchTags)));
  };

  const renderTags = (tag) => (
    <Button
      color="primary"
      size="sm"
      key={tag}
      className={classes.tagBtn}
      onClick={handleTagClick(tag)}
    >
      {`#${tag}`}
    </Button>
  );

  return <div className={classes.container}>{tags.map(renderTags)}</div>;
}
