import React, { useState } from "react";
import Dialog from "../../../../components/Dialog/Dialog";
import Button from "../../../../components/CustomButtons/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  dialogsSelectors,
  closeRemoveUserFromGroupDialog,
} from "../../../../store/dialogsSlice";
import { removeUserFromGroup } from "../../../../store/connectionsSlice";

function RemoveUserFromGroupDialog() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {
    isOpen,
    data: { groupId, userId, userName },
  } = useSelector(dialogsSelectors.getRemoveUserFromGroupDialogState);

  const handleClose = () => dispatch(closeRemoveUserFromGroupDialog());

  async function handleDeleteGroup() {
    setIsLoading(true);
    const success = await dispatch(removeUserFromGroup({ groupId, userId }));
    setIsLoading(false);

    if (success) {
      handleClose();
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      isLoading={isLoading}
      title={"Remove User from Group"}
      actions={() => (
        <Button
          round
          color="danger"
          style={{ marginLeft: "1rem" }}
          onClick={handleDeleteGroup}
        >
          Remove
        </Button>
      )}
      onClose={handleClose}
    >
      <p>
        Are you sure you want to remove <b>{userName}</b> from this group?
      </p>
      <hr />
    </Dialog>
  );
}

export default RemoveUserFromGroupDialog;
