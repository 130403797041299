import React from "react";
import CardBody from "../../../components/Card/CardBody";
import { Link } from "react-router-dom";
import defaultAvatar from "../../../assets/img/placeholder.jpg";
import PostType from "../../molecules/PostType/PostType";
import LockOutlined from "@material-ui/icons/LockOutlined";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";
import { MoreVert } from "@material-ui/icons";
import LibraryEntityContent from "../../molecules/LibraryEntityContent/LibraryEntityContent";
import Card from "../../../components/Card/Card";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import libraryPageStyle from "../../ArticlesView/LibraryPageStyle";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { postIsEvent } from "../../../utils/posts";
import moment from "moment";
import { routerSelectors } from "../../../store/routerActions";
import Paths from "../../../staticData/Paths";

const useStyles = makeStyles(libraryPageStyle);

function PostCard({ post, handleActions, optionsList }) {
  const dispatch = useDispatch();
  const locationState = useSelector(routerSelectors.getLocationState);

  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.userImageView,
    classes.imgPosition
  );

  const {
    id,
    user,
    type,
    startDate,
    private: isPrivate,
    lastPublicBoardName,
  } = post;
  const { id: userId, profilePicture, userName } = user;

  const renderEventDetails = () => {
    if (postIsEvent(type)) {
      return (
        <>
          <p>{moment(`${startDate}Z`).format("LLL")}</p>
          <hr />
        </>
      );
    } else {
      return null;
    }
  };

  const handleOnClick = () => {
    const locationObject = {
      pathname: Paths.postDetails.replace(":id", id),
      ...(locationState?.guestCode && { state: locationState }),
    };

    dispatch(push(locationObject));
  };

  return (
    <Card blog style={{ margin: "0" }}>
      <CardBody>
        <div style={{ display: "grid", gridTemplateColumns: "auto 1fr auto" }}>
          <div style={{ display: "flex" }}>
            <Link to={Paths.otherUserAccount.replace(":id", userId)}>
              <img
                src={profilePicture || defaultAvatar}
                alt="Post creator avatar"
                className={imageClasses}
              />
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div style={{ width: "100%" }}>
              <h4
                className={classes.title}
                style={{
                  cursor: "pointer",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
                onClick={() =>
                  dispatch(push(Paths.otherUserAccount.replace(":id", userId)))
                }
              >
                {userName}
              </h4>
            </div>

            <div
              style={{
                marginLeft: "1rem",
                whiteSpace: "pre-wrap",
                wordBreak: "break-all",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                lineHeight: "1rem",
                fontSize: "0.8rem",
              }}
            >
              <span>{lastPublicBoardName}</span>
            </div>
            <div
              style={{
                display: "flex",
                margin: "0.3rem 0",
              }}
            >
              <PostType postData={post} />
              {isPrivate && (
                <LockOutlined
                  fontSize={"small"}
                  style={{ marginLeft: "0.3rem" }}
                />
              )}
            </div>
          </div>
          <div
            style={{
              position: "relative",
              top: "-10px",
              justifyContent: "flex-end",
              display: "flex",
              flex: "1",
            }}
          >
            <CustomDropdown
              caret={false}
              hoverColor="primary"
              buttonText={<MoreVert />}
              onClick={(option) => {
                handleActions({ option });
              }}
              dropPlacement={"bottom-end"}
              buttonProps={{
                className: classes.imageDropdownButton,
                color: "transparent",
                justIcon: true,
              }}
              dropdownList={optionsList}
            />
          </div>
        </div>
        <hr />
        <div onClick={handleOnClick} style={{ cursor: "pointer" }}>
          {renderEventDetails()}
          <LibraryEntityContent elem={post} />
        </div>
      </CardBody>
    </Card>
  );
}

export default PostCard;
