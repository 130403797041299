import ReactGA from "react-ga4";
import { getCookieConsentValue } from "react-cookie-consent";

const gaTrackingMiddleware = (store) => (next) => (action) => {
  if (action.type === "@@router/LOCATION_CHANGE") {
    const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;

    trackPage(nextPage, store);
  }
  return next(action);
};

const trackPage = (page, store) => {
  const cookiesAccepted = getCookieConsentValue() === "true";
  const authTokens = store.getState().authentication.authTokens;

  if (cookiesAccepted || !!authTokens) {
    ReactGA.send({ hitType: "page_view", page });
  }
};

export { gaTrackingMiddleware };
