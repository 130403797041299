import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import libraryPageStyle from "../../../ArticlesView/LibraryPageStyle";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { getLibraryPosts } from "store/generalPostsSlice";
import SizedStackGrid from "components/SizedStackGrid/SizedStackGrid";
import {
  generalPostActionTypes,
  usePostCard,
} from "utils/hooks/postCardOptions";
import { useInfiniteLoading } from "../../../../utils/hooks/infiniteLoading";
import { routerSelectors } from "../../../../store/routerActions";
import {
  clearLibraryPosts,
  librarySelectors,
} from "../../../../store/librarySlice";

const useStyles = makeStyles(libraryPageStyle);

export default function LibraryPostsView() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const locationKey = useSelector(routerSelectors.getLocationKey);
  const posts = useSelector(librarySelectors.posts);

  const apiCall = useCallback((data) => getLibraryPosts(data), []);

  const {
    bottomBoundaryNode,
    isFetching,
    resetPagination,
    cancelOngoingCall,
  } = useInfiniteLoading({ apiCall });

  useEffect(() => {
    return () => {
      resetPagination();
      cancelOngoingCall();
      dispatch(clearLibraryPosts());
    };
  }, [dispatch, resetPagination, cancelOngoingCall, locationKey]);

  const { postCardRenderer } = usePostCard(generalPostActionTypes);

  return (
    <div
      className={classes.container}
      style={{ paddingTop: "30px" }}
      key={locationKey}
    >
      <SizedStackGrid>{posts.map(postCardRenderer)}</SizedStackGrid>
      {bottomBoundaryNode}

      <div style={{ height: "6rem", textAlign: "center", padding: "2rem" }}>
        {!posts.length && !isFetching
          ? "Create a post in order to see it here."
          : ""}
      </div>
    </div>
  );
}
