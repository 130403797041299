import React from "react";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import defaultAvatar from "../../../assets/img/placeholder.jpg";
import classNames from "classnames";
import PostType from "../../molecules/PostType/PostType";
import LockOutlined from "@material-ui/icons/LockOutlined";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";
import { MoreVert } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import libraryPageStyle from "../../ArticlesView/LibraryPageStyle";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import Paths from "../../../staticData/Paths";
import {
  primaryColor,
  secondaryColor,
} from "../../../assets/jss/material-kit-pro-react";
import { useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";

const useStyles = makeStyles(libraryPageStyle);
const useCustomStyles = makeStyles({
  imgZoom: {
    boxShadow: "1px 1px 5px #2A3B57",
    transition: "all 0.1s ease-in-out",

    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  optionsBtnZoom: {
    transition: "all 0.1s ease-in-out",

    "&:hover": {
      transform: "scale(1.1)",
      boxShadow: `0 0 4px ${primaryColor[2]}`,
    },
  },
  titleZoom: {
    color: secondaryColor[0],
    transition: "all 0.15s ease-in-out",

    "&:hover": {
      transform: "scale(1.03)",
    },
  },
  tagsContainer: {
    width: "100%",

    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    padding: "2px",

    "& span": {
      padding: "0 10px 0 0",
      color: secondaryColor[0],
      transition: "all 0.1s ease-in-out",
      whiteSpace: "nowrap",

      "&:hover": {
        transform: "scale(1.1)",
        color: primaryColor[4],
      },
    },
  },
});

function BoardCard({ board, handleActions, optionsList }) {
  const dispatch = useDispatch();
  const searchParamsTag = useSelector(
    (state) => state.router.location.query?.tag
  );

  const classes = useStyles();
  const customClasses = useCustomStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.userImageView,
    classes.imgPosition
  );
  const { id, title, tags, backgroundImage, user, private: isPrivate } = board;

  const searchRoute = useRouteMatch("/search");
  const isSearchRoute = searchRoute?.isExact;

  const handleTagsOnClick = (tag) => (event) => {
    event.stopPropagation();

    const strippedTag = tag.tagName.replace("#", "");
    const isIncluded = searchParamsTag?.split(",").includes(strippedTag);

    if (isSearchRoute) {
      if (isIncluded) {
        return dispatch(push(`/search?tag=${searchParamsTag}`));
      } else {
        return dispatch(push(`/search?tag=${strippedTag},${searchParamsTag}`));
      }
    }

    dispatch(push(`/search?tag=${strippedTag}`));
  };

  return (
    <Card blog style={{ margin: "0" }}>
      <CardBody style={{ padding: "0", margin: "0" }}>
        <div
          style={{
            backgroundImage: `linear-gradient(rgba(36,80,183,0.3), rgba(46,59,89,0.2)), url("${encodeURI(backgroundImage)}")`,
            backgroundBlendMode: "normal",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            borderRadius: "6px",
            padding: "1rem",
            color: "#fff",
            cursor: "pointer",
            fontWeight: "bold",
            textShadow: "2px 2px 5px #2A3B57",
          }}
          onClick={() => dispatch(push(Paths.boardDetails.replace(":id", id)))}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <img
                src={user?.profilePicture || defaultAvatar}
                alt="Creator Profile"
                className={imageClasses}
                onClick={(event) => {
                  event.stopPropagation();
                  dispatch(push(Paths.otherUserAccount.replace(":id", user?.id)));
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <h4
                className={classNames(classes.title, customClasses.titleZoom)}
                onClick={(event) => {
                  event.stopPropagation();
                  dispatch(push(Paths.otherUserAccount.replace(":id", user?.id)));
                }}
              >
                {user?.userName}
              </h4>
              <div style={{ display: "flex" }}>
                <PostType postData={board} />
              </div>
            </div>
            <div
              style={{
                position: "relative",
                justifyContent: "flex-end",
                alignItems: "center",
                display: "flex",
                flex: "1",
              }}
            >
              {isPrivate && (
                <LockOutlined
                  fontSize={"small"}
                  style={{ marginLeft: "0.3rem" }}
                />
              )}
              <CustomDropdown
                caret={false}
                hoverColor="primary"
                buttonText={<MoreVert />}
                onClick={(option, event) => handleActions({ option, event })}
                dropPlacement={"bottom-end"}
                buttonProps={{
                  className: classNames(
                    classes.imageDropdownButton,
                    customClasses.optionsBtnZoom
                  ),
                  color: "transparent",
                  justIcon: true,
                }}
                dropdownList={optionsList}
              />
            </div>
          </div>
          <div
            style={{
              minHeight: "150px",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              padding: "1rem 0",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                fontSize: "20px",
                color: "#fff",
                textShadow: "2px 2px 5px #2A3B57",
                width: "100%",
              }}
            >
              <span
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  padding: "2px",
                }}
              >
                {title}
              </span>
            </div>
            <div className={customClasses.tagsContainer}>
              {tags?.map((tag) => {
                const { id, tagName } = tag;

                return (
                  <span key={id} onClick={handleTagsOnClick(tag)}>
                    {tagName}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default BoardCard;

BoardCard.propTypes = {
  handleActions: PropTypes.func.isRequired,
  optionsList: PropTypes.array.isRequired,
  board: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    backgroundImage: PropTypes.string,
    private: PropTypes.bool,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        tagName: PropTypes.string,
      })
    ),
    user: PropTypes.shape({
      id: PropTypes.string,
      userName: PropTypes.string,
      profilePicture: PropTypes.string,
    }),
  }),
};
