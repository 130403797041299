import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import axios from "axios";
import Endpoints from "../staticData/Endpoints";
import { toastr } from "react-redux-toastr";
import { displayValidationMessage } from "../utils/validationDisplayHelper";

const connectionsSlice = createSlice({
  name: "connections",
  initialState: {
    groups: [],
    people: [],
    groupDetails: {},
    groupMembers: [],
  },
  reducers: {
    addGroups(state, action) {
      action.payload.forEach((group) => {
        state.groups.push(group);
      });
    },
    addGroup(state, action) {
      state.groups.unshift(action.payload);
    },
    updateGroup(state, { payload }) {
      const { id } = payload;

      const index = state.groups.findIndex((x) => x.id === id);

      if (index !== -1) {
        state.groups[index] = payload;
      }
    },
    removeGroup(state, { payload }) {
      const { id } = payload;
      state.groups = state.groups.filter((group) => group.id !== id);
    },
    resetGroups(state) {
      state.groups = [];
    },
    addPeople(state, action) {
      action.payload.forEach((user) => {
        state.people.push(user);
      });
    },
    resetPeople(state) {
      state.people = [];
    },
    setGroupDetails(state, { payload }) {
      state.groupDetails = payload;
    },
    resetGroupDetails(state) {
      state.groupDetails = {};
    },
    addGroupMembers(state, action) {
      action.payload.forEach((member) => {
        state.groupMembers.push(member);
      });
    },
    addNewGroupMembers(state, { payload }) {
      payload.forEach((member) => {
        const insertionIndex = state.groupMembers.lastIndexOf(
          (x) => x.orderNumber < member.orderNumber
        );

        state.groupMembers.splice(insertionIndex, 0, member);
      });
    },
    resetGroupMembers(state) {
      state.groupMembers = [];
    },
    removeUserFromGroupMembersList(state, { payload }) {
      const { userId } = payload;
      state.groupMembers = state.groupMembers.filter(
        (member) => member.id !== userId
      );
    },
  },
});

export const {
  addGroups,
  addGroup,
  updateGroup,
  resetGroups,
  addPeople,
  resetPeople,
  removeGroup,
  setGroupDetails,
  resetGroupDetails,
  addGroupMembers,
  addNewGroupMembers,
  resetGroupMembers,
  removeUserFromGroupMembersList,
} = connectionsSlice.actions;

export default connectionsSlice.reducer;

export const connectionsSelectors = {
  groups: createSelector(
    (state) => state.connections.groups,
    (groups) => groups
  ),
  people: createSelector(
    (state) => state.connections.people,
    (people) => people
  ),
  getFilteredPeople: (searchText) => (state) =>
    createSelector(
      connectionsSelectors.people,
      (_, searchText) => searchText,
      (people, searchText) =>
        people.filter((person) => {
          const lowercaseSearchText = searchText.toLowerCase();

          return (
            person.userName?.toLowerCase().includes(lowercaseSearchText) ||
            person.firstName?.toLowerCase().includes(lowercaseSearchText) ||
            person.lastName?.toLowerCase().includes(lowercaseSearchText)
          );
        })
    )(state, searchText),
  groupDetails: createSelector(
    (state) => state.connections.groupDetails,
    (groupDetails) => groupDetails
  ),
  groupMembers: createSelector(
    (state) => state.connections.groupMembers,
    (groupMembers) => groupMembers
  ),
};

export const getGroups = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const { lastUpdatedOn: lastLoadedItem, cancelToken } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getGroupList.replace(":groupId", lastLoadedItem || 0),
    headers: {
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      dispatch(addGroups(data));

      return {
        areMore: data.length === 10,
        lastUpdatedOn: data[data.length - 1]?.id,
      };
    })
    .catch((error) => {
      console.log(error);
    });
};

export const createGroup = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const payload = { groupName: data.groupName };

  return axios({
    method: "POST",
    data: JSON.stringify(payload),
    url: Endpoints.createGroup,
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        dispatch(addGroup(result.data));
        toastr.success("", "Group was successfully created.");
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const editGroup = ({ formValues, id }) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const payload = { groupName: formValues.groupName, id };

  return axios({
    method: "PUT",
    data: JSON.stringify(payload),
    url: Endpoints.editGroup,
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        dispatch(updateGroup(result.data));
        toastr.success("", "Group was updated successfully.");
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteGroup = (id) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  return axios({
    method: "DELETE",
    url: Endpoints.deleteGroup.replace(":groupId", id),
    headers: { authorization: `Bearer ${authTokens}` },
  })
    .then((result) => {
      if (result.status === 200) {
        dispatch(removeGroup({ id }));
        toastr.success("", "Group was successfully deleted.");
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const addUsersToGroup = ({ id, usersId }) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const addedUsersCount = usersId?.length;

  return axios({
    method: "POST",
    data: JSON.stringify({ id, usersId }),
    url: Endpoints.addUsersToGroup,
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status, data } = result;

      if (status === 200) {
        toastr.success(
          "",
          addedUsersCount < 2
            ? "User was successfully added to the group."
            : "Users were successfully added to the group."
        );
        dispatch(addNewGroupMembers(data));
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const removeUserFromGroup = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { groupId, userId } = data;

  return axios({
    method: "DELETE",
    params: { id: groupId, userId },
    url: Endpoints.removeUserFromGroup,
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        dispatch(removeUserFromGroupMembersList({ userId }));
        toastr.success("", "User was successfully removed from the group.");
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const leaveGroup = ({ groupId, userId }) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  return axios({
    method: "DELETE",
    url: Endpoints.leaveGroup.replace(":groupId", groupId),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        dispatch(removeUserFromGroupMembersList({ userId }));
        toastr.success("", "Success.");
        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getFollowing = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const { lastUpdatedOn: lastLoadedItem, cancelToken } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getConnectionsPeople.replace(":userId", lastLoadedItem || 0),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      dispatch(addPeople(data));

      return {
        areMore: data.length === 10,
        lastUpdatedOn: data[data.length - 1]?.orderNumber,
      };
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getGroupDetails = ({ id }) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  return axios({
    method: "GET",
    url: Endpoints.getGroupDetails.replace(":groupId", id),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { data, status } = result;

      if (status === 200) {
        dispatch(setGroupDetails(data));
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getGroupMembers = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { id, lastUpdatedOn: memberId, cancelToken } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getGroupMembers
      .replace(":groupId", id)
      .replace(":memberId", memberId || 0),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      dispatch(addGroupMembers(data));

      return {
        areMore: data.length === 10,
        lastUpdatedOn: data[data.length - 1]?.orderNumber,
      };
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getPeopleForGroups = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { value, groupId } = data;

  return axios({
    method: "GET",
    url: Endpoints.getPeopleSuggestionsForGroup
      .replace(":groupId", groupId)
      .replace(":searchText", value),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        return result.data;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
