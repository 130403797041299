import "./FullCalendar.scss";
import "tippy.js/dist/tippy.css";
import React, { useRef } from "react";
import tippy from "tippy.js";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { push, goBack } from "connected-react-router";
import { useDispatch } from "react-redux";
import Paths from "../../../staticData/Paths";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import libraryPageStyle from "../../ArticlesView/LibraryPageStyle";
import PropTypes from "prop-types";
import { getCalendarEvents } from "../../../store/generalActions";

const useStyles = makeStyles(libraryPageStyle);

function eventDataTransform(eventData) {
  const { id, title, startDate, endDate, articleId } = eventData;

  if (!!startDate && !!endDate) {
    const start = moment.utc(startDate).local().format();
    const end = moment.utc(endDate).local().format();

    const eventLengthInDays = moment(end).diff(start, "days");

    return {
      id,
      title,
      start,
      end,
      ...(eventLengthInDays >= 1 && { allDay: true }),
      extendedProps: {
        articleId,
      },
    };
  } else {
    return false;
  }
}

export default function FullCalendarComponent({ endpoint }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const callInProgressRef = useRef(false);
  const calendarRef = useRef();

  const eventClick = (eventClickInfo) => {
    const postId = eventClickInfo.event.extendedProps.articleId;

    dispatch(push(Paths.postDetails.replace(":id", postId)));
  };

  const eventDidMount = ({ el, event: { title } }) => {
    tippy(el, {
      content: title,
    });
  };

  const getEvents = (info, successCallback, failureCallback) => {
    if (callInProgressRef.current === true) {
      return;
    }

    callInProgressRef.current = true;

    dispatch(
      getCalendarEvents({
        start: info.start,
        end: info.end,
        endpoint,
      })
    )
      .then((response) => {
        const { status, data } = response;

        callInProgressRef.current = false;

        if (status === 200) {
          successCallback(data);
        }
      })
      .catch((err) => {
        callInProgressRef.current = false;

        failureCallback(err);
      });
  };

  const renderWeekNumber = ({ date, text }) => {
    const weekStartDay = moment(date).format("YYYY-MM-DD");

    const handleWeekClick = () => {
      calendarRef.current.getApi().changeView("timeGridWeek", weekStartDay);
    };

    return <span onClick={handleWeekClick}>{text}</span>;
  };

  const renderDayNumber = ({ date, dayNumberText }) => {
    const clickedDayDate = moment(date).format("YYYY-MM-DD");

    const handleDayClick = () => {
      calendarRef.current.getApi().changeView("timeGridDay", clickedDayDate);
    };

    return (
      <span onClick={handleDayClick} className={"calendar-day-number"}>
        {dayNumberText}
      </span>
    );
  };

  const customButtons = {
    goBack: {
      text: "Go back",
      click: () => dispatch(goBack()),
    },
  };

  const headerToolbar = {
    left: "goBack",
    center: "title",
    right: "today dayGridMonth,timeGridWeek,timeGridDay prev,next",
  };

  return (
    <div className={classes.container} style={{ padding: "30px 0 30px" }}>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin]}
        initialView={"dayGridMonth"}
        events={getEvents}
        eventDataTransform={eventDataTransform}
        eventClick={eventClick}
        eventDidMount={eventDidMount}
        eventClassNames={"calendar-event"}
        headerToolbar={headerToolbar}
        customButtons={customButtons}
        weekNumbers={true}
        weekText={"week "}
        weekNumberContent={renderWeekNumber}
        weekNumberClassNames={"calendar-week-number"}
        dayCellContent={renderDayNumber}
      />
    </div>
  );
}

FullCalendarComponent.propTypes = {
  endpoint: PropTypes.string.isRequired,
};
