import React from "react";
import { useField } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import { makeStyles } from "@material-ui/core/styles";
import javascriptStyles from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";

const useStyles = makeStyles(javascriptStyles);

const FormikDateTime = (props) => {
  const [field, meta, helpers] = useField(props);
  const classes = useStyles();

  const { placeholder, name } = props;

  return (
    <>
      <FormControl fullWidth>
        <InputLabel className={classes.label} htmlFor={props.id}>
          {placeholder}
        </InputLabel>
        <Input
          value={field.value}
          onChange={(data) => {
            helpers.setValue(data);
          }}
          id={props.id}
          name={name}
          inputComponent={CustomDateTime}
        />
      </FormControl>

      {meta.touched && meta.error ? (
        <p
          style={{
            fontSize: "12px",
            color: "red",
            position: "relative",
          }}
        >
          {meta.error}
        </p>
      ) : null}
    </>
  );
};

export default FormikDateTime;

function CustomDateTime(props) {
  const { value, onChange, inputRef, ...restProps } = props;

  return (
    <Datetime
      value={value}
      inputProps={{ ref: inputRef, ...restProps, autoComplete: "off" }}
      onChange={(date) => onChange(date)}
      className={"react-date-time-wrapper"}
      dateFormat={"LL"}
      timeFormat
    />
  );
}
