import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  connectionsSelectors,
  getFollowing,
  resetPeople,
} from "../../../store/connectionsSlice";
import PersonCard from "../PersonCard/PersonCard";
import CustomInput from "../../../components/CustomInput/CustomInput";
import SizedStackGrid from "../../../components/SizedStackGrid/SizedStackGrid";
import { useInfiniteLoading } from "../../../utils/hooks/infiniteLoading";

export default function ConnectionsPeople() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");

  const people = useSelector(
    connectionsSelectors.getFilteredPeople(searchText)
  );

  useEffect(() => {
    return () => dispatch(resetPeople());
  }, [dispatch]);

  const apiCall = useCallback((data) => getFollowing(data), []);

  const { bottomBoundaryNode } = useInfiniteLoading({ apiCall });

  return (
    <div style={{ padding: "1rem 0" }}>
      <div>
        <CustomInput
          id={"usersSearchTextInput"}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            placeholder: "Search...",
            onChange: (e) => setSearchText(e.target.value),
          }}
        />
      </div>
      <SizedStackGrid>
        {people.map(({ id, userName, profilePicture }) => (
          <PersonCard key={id} person={{ id, userName, profilePicture }} />
        ))}
      </SizedStackGrid>
      {!people.length ? (
        <div style={{ display: "grid", placeItems: "center" }}>
          <p>Please interact with another user in order to see them here.</p>
        </div>
      ) : null}
      {bottomBoundaryNode}
    </div>
  );
}
