import React from "react";
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import commentsAttachmentsStyles from "./CommentsAttachments.styles";
import { makeStyles } from "@material-ui/core/styles";
import { FileIcon, defaultStyles } from "react-file-icon";
import { uploadFile } from "../../../store/generalPostsSlice";
import { openFilePreviewDialog } from "../../../store/dialogsSlice";
import { getFileType } from "utils/files";
import Loading from "../../../components/Loading/Loading";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import download from "downloadjs";

const useStyles = makeStyles(commentsAttachmentsStyles);

function CommentAttachments({
  attachments,
  setAttachments,
  openFilePreviewDialog,
  displayMode,
  label,
}) {
  const classes = useStyles();

  let fileInput = React.createRef();

  function handleFileChange(e) {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    if (!file) return;

    const fileType = getFileType(file.name).toLowerCase();
    const isFileTypeSupported = supportedFileTypes.includes(fileType);

    if (isFileTypeSupported) {
      reader.onloadend = () => {
        setAttachments((state) => [...state, { file, dataUrl: reader.result }]);
      };

      reader.readAsDataURL(file);
    } else {
      toastr.error("", "This file type is not supported.");
    }
  }

  function removeAttachment(attachment) {
    const { file, url } = attachment;
    setAttachments((files) =>
      files.filter((x) => (file?.name ? x.file !== file : x.url !== url))
    );
  }

  const handleThumbnailOnClick = (file) => () => {
    if (displayMode === "edit") return;

    const fileType = getFileType(file);

    if (["doc", "docx", "ppt", "pptx", "xls", "xlsx"].includes(fileType)) {
      download(file);
      return;
    }

    openFilePreviewDialog({ file, attachments });
  };

  const supportedFileTypes = [
    "jpg",
    "png",
    "jpeg",

    "pdf",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "xls",
    "xlsx",

    "mp4",
    "webm",
    "ogg",
  ];

  const acceptedFileTypes = supportedFileTypes.reduce((acc, elem, index) => {
    const extension = `.${elem}`;

    return acc.concat(index === 0 ? extension : `,${extension}`);
  }, "");

  function renderDeleteAttachmentButton(attachment) {
    if (displayMode === "view") return;

    return (
      <Button
        round
        className={classes.deleteBtn}
        size={"sm"}
        color={"danger"}
        onClick={() => removeAttachment(attachment)}
      >
        <Close />
        Delete
      </Button>
    );
  }

  function renderAttachmentsThumbnails() {
    return attachments?.map((attachment, index) => {
      const { file, dataUrl, url } = attachment;
      const src = file ? dataUrl : url;

      const fileType = getFileType(
        attachment?.file?.name || attachment?.url
      )?.toLowerCase();

      if (
        [
          "pdf",

          "mp4",
          "webm",
          "ogg",

          "doc",
          "docx",
          "ppt",
          "pptx",
          "xls",
          "xlsx",
        ].includes(fileType)
      ) {
        return (
          <div key={url} className={classes.container}>
            <Loading isLoading={false} size={"sm"}>
              <div
                className={classes.generalExtensions}
                onClick={handleThumbnailOnClick(src)}
              >
                <FileIcon extension={fileType} {...defaultStyles[fileType]} />
              </div>
            </Loading>
            {renderDeleteAttachmentButton(attachment)}
          </div>
        );
      }

      if (["jpg", "png", "jpeg"].includes(fileType)) {
        return (
          <div key={`${src}-${index}`} className={classes.container}>
            <img
              src={src}
              onClick={handleThumbnailOnClick(src)}
              alt={"comment attachment"}
              className={classes.imgAttachment}
            />
            {renderDeleteAttachmentButton(attachment)}
          </div>
        );
      }

      return null;
    });
  }

  return (
    <div>
      {displayMode === "edit" && (
        <div>
          <input
            type={"file"}
            ref={fileInput}
            onChange={handleFileChange}
            accept={acceptedFileTypes}
            style={{ display: "none" }}
          />
          <Button
            round
            size={"sm"}
            color={"primary"}
            onClick={(e) => fileInput.current.click(e)}
          >
            Add Attachment
          </Button>
        </div>
      )}
      {attachments?.length > 0 && <p className={classes.label}>{label}</p>}
      <div className={classes.wrapper}>{renderAttachmentsThumbnails()}</div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authTokens: state.authentication.authTokens,
});

const mapDispatchToProps = {
  uploadFile,
  openFilePreviewDialog,
};

CommentAttachments.propTypes = {
  displayMode: PropTypes.oneOf(["view", "edit"]),
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentAttachments);
