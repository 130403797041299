import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Endpoints from "../staticData/Endpoints";
import { toastr } from "react-redux-toastr";
import { createSelector } from "reselect/src";

const dialogsSlice = createSlice({
  name: "dialogs",
  initialState: {
    contextItem: {},
    contextBoard: {},

    deleteArticleDialog: {
      isOpen: false,
      data: {},
    },
    sharePostDialog: {
      isOpen: false,
    },
    createArticleDialog: {
      isOpen: false,
      data: {},
    },
    editArticleDialog: {
      isOpen: false,
    },
    saveArticleDialog: {
      isOpen: false,
    },
    removeArticleFromBoardDialog: {
      isOpen: false,
    },
    filePreviewDialog: {
      isOpen: false,
      data: {},
    },
    copyPostDialog: {
      isOpen: false,
    },
    endChallengeDialog: {
      isOpen: false,
      data: {},
    },
    deleteAccountDialog: {
      isOpen: false,
      data: {},
    },

    deleteBoardDialog: {
      isOpen: false,
    },
    unfollowBoardDialog: {
      isOpen: false,
    },
    unfollowTagDialog: {
      isOpen: false,
    },
    editBoardDialog: {
      isOpen: false,
    },
    createBoardDialog: {
      isOpen: false,
    },
    shareBoardDialog: {
      isOpen: false,
    },

    createGroupDialog: {
      isOpen: false,
    },
    deleteGroupDialog: {
      isOpen: false,
      data: {
        id: null,
      },
    },
    editGroupDialog: {
      isOpen: false,
      data: {
        groupName: "",
        id: null,
      },
    },
    addUsersToGroupDialog: {
      isOpen: false,
      data: {
        id: null,
      },
    },
    removeUserFromGroupDialog: {
      isOpen: false,
      data: {},
    },
    leaveGroupDialog: {
      isOpen: false,
      data: {},
    },

    removeFavoritePostDialog: {
      isOpen: false,
      data: {
        id: null,
      },
    },
    guestCodeDialog: {
      isOpen: false,
    },
  },
  reducers: {
    setContextItem(state, action) {
      state.contextItem = action.payload;
    },
    setContextBoard(state, action) {
      state.contextBoard = action.payload;
    },

    openDeleteArticleDialog(state, { payload = {} }) {
      const { fromDetails } = payload;

      state.deleteArticleDialog.isOpen = true;
      state.deleteArticleDialog.data = { fromDetails };
    },
    closeDeleteArticleDialog(state) {
      state.deleteArticleDialog.isOpen = false;
      state.deleteArticleDialog.data = {};
      state.contextItem = {};
    },
    openShareArticleDialog(state) {
      state.sharePostDialog.isOpen = true;
    },
    closeShareArticleDialog(state) {
      state.sharePostDialog.isOpen = false;
      state.contextItem = {};
    },
    openCreateArticleDialog(state, { payload = {} }) {
      state.createArticleDialog.isOpen = true;
      state.createArticleDialog.data = payload;
    },
    closeCreateArticleDialog(state) {
      state.createArticleDialog.isOpen = false;
      state.contextItem = {};
    },
    openEditArticleDialog(state) {
      state.editArticleDialog.isOpen = true;
    },
    closeEditArticleDialog(state) {
      state.editArticleDialog.isOpen = false;
      state.contextItem = {};
    },
    openSaveArticleDialog(state) {
      state.saveArticleDialog.isOpen = true;
    },
    closeSaveArticleDialog(state) {
      state.saveArticleDialog.isOpen = false;
      state.contextItem = {};
    },
    openRemoveArticleFromBoardDialog(state) {
      state.removeArticleFromBoardDialog.isOpen = true;
    },
    closeRemoveArticleFromBoardDialog(state) {
      state.removeArticleFromBoardDialog.isOpen = false;
      state.contextItem = {};
    },
    openFilePreviewDialog(state, { payload: { file, attachments } }) {
      state.filePreviewDialog.isOpen = true;
      state.filePreviewDialog.data = { file, attachments };
    },
    closeFilePreviewDialog(state) {
      state.filePreviewDialog.isOpen = false;
      state.filePreviewDialog.data = {};
      state.contextItem = {};
    },
    openCopyPostDialog(state) {
      state.copyPostDialog.isOpen = true;
    },
    closeCopyPostDialog(state) {
      state.copyPostDialog.isOpen = false;
    },
    openEndChallengeDialog(state) {
      state.endChallengeDialog.isOpen = true;
    },
    closeEndChallengeDialog(state) {
      state.endChallengeDialog.isOpen = false;
    },
    openDeleteAccountDialog(state) {
      state.deleteAccountDialog.isOpen = true;
    },
    closeDeleteAccountDialog(state) {
      state.deleteAccountDialog.isOpen = false;
    },

    openDeleteBoardDialog(state) {
      state.deleteBoardDialog.isOpen = true;
    },
    closeDeleteBoardDialog(state) {
      state.deleteBoardDialog.isOpen = false;
      state.contextBoard = {};
    },

    openUnfollowBoardDialog(state) {
      state.unfollowBoardDialog.isOpen = true;
    },
    closeUnfollowBoardDialog(state) {
      state.unfollowBoardDialog.isOpen = false;
      state.contextBoard = {};
    },
    openUnfollowTagDialog(state) {
      state.unfollowTagDialog.isOpen = true;
    },
    closeUnfollowTagDialog(state) {
      state.unfollowTagDialog.isOpen = false;
      state.contextBoard = {};
    },
    openEditBoardDialog(state) {
      state.editBoardDialog.isOpen = true;
    },
    closeEditBoardDialog(state) {
      state.editBoardDialog.isOpen = false;
      state.contextBoard = {};
    },
    openCreateBoardDialog(state) {
      state.createBoardDialog.isOpen = true;
    },
    closeCreateBoardDialog(state) {
      state.createBoardDialog.isOpen = false;
      state.contextBoard = {};
    },
    openShareBoardDialog(state) {
      state.shareBoardDialog.isOpen = true;
    },
    closeShareBoardDialog(state) {
      state.shareBoardDialog.isOpen = false;
      state.contextBoard = {};
    },

    openCreateGroupDialog(state) {
      state.createGroupDialog.isOpen = true;
    },
    closeCreateGroupDialog(state) {
      state.createGroupDialog.isOpen = false;
    },
    openDeleteGroupDialog(state, { payload: { id } }) {
      state.deleteGroupDialog.isOpen = true;
      state.deleteGroupDialog.data.id = id;
    },
    closeDeleteGroupDialog(state) {
      state.deleteGroupDialog.isOpen = false;
      state.deleteGroupDialog.data = {};
    },
    openEditGroupDialog(state, { payload: { groupName, id } }) {
      state.editGroupDialog.isOpen = true;
      state.editGroupDialog.data.groupName = groupName;
      state.editGroupDialog.data.id = id;
    },
    closeEditGroupDialog(state) {
      state.editGroupDialog.isOpen = false;
      state.editGroupDialog.data = {};
    },
    openAddUsersToGroupDialog(state, { payload: { id } }) {
      state.addUsersToGroupDialog.isOpen = true;
      state.addUsersToGroupDialog.data.id = id;
    },
    closeAddUsersToGroupDialog(state) {
      state.addUsersToGroupDialog.isOpen = false;
      state.addUsersToGroupDialog.data = {};
    },
    openRemoveUserFromGroupDialog(state, { payload }) {
      const { groupId, userId, userName } = payload;

      state.removeUserFromGroupDialog.isOpen = true;
      state.removeUserFromGroupDialog.data.groupId = groupId;
      state.removeUserFromGroupDialog.data.userId = userId;
      state.removeUserFromGroupDialog.data.userName = userName;
    },
    closeRemoveUserFromGroupDialog(state) {
      state.removeUserFromGroupDialog.isOpen = false;
      state.removeUserFromGroupDialog.data = {};
    },
    openLeaveGroupDialog(state, { payload }) {
      const { groupId, userId, groupName } = payload;

      state.leaveGroupDialog.isOpen = true;
      state.leaveGroupDialog.data.groupId = groupId;
      state.leaveGroupDialog.data.userId = userId;
      state.leaveGroupDialog.data.groupName = groupName;
    },
    closeLeaveGroupDialog(state) {
      state.leaveGroupDialog.isOpen = false;
      state.leaveGroupDialog.data = {};
    },

    openRemoveFavoritePostDialog(state, { payload: { id } }) {
      state.removeFavoritePostDialog.isOpen = true;
      state.removeFavoritePostDialog.data.id = id;
    },
    closeRemoveFavoritePostDialog(state) {
      state.removeFavoritePostDialog.isOpen = false;
      state.removeFavoritePostDialog.data = {};
    },

    openGuestCodeDialog(state) {
      state.guestCodeDialog.isOpen = true;
    },
    closeGuestCodeDialog(state) {
      state.guestCodeDialog.isOpen = false;
    },
  },
});

export const {
  setContextItem,
  openDeleteArticleDialog,
  closeDeleteArticleDialog,
  openUnfollowBoardDialog,
  closeUnfollowBoardDialog,
  openUnfollowTagDialog,
  closeUnfollowTagDialog,
  openShareArticleDialog,
  closeShareArticleDialog,
  openSaveArticleDialog,
  closeSaveArticleDialog,
  openCreateArticleDialog,
  closeCreateArticleDialog,
  openEditArticleDialog,
  closeEditArticleDialog,
  openEndChallengeDialog,
  closeEndChallengeDialog,
  openRemoveArticleFromBoardDialog,
  closeRemoveArticleFromBoardDialog,
  openFilePreviewDialog,
  closeFilePreviewDialog,
  openCopyPostDialog,
  closeCopyPostDialog,
  openDeleteAccountDialog,
  closeDeleteAccountDialog,

  setContextBoard,
  openDeleteBoardDialog,
  closeDeleteBoardDialog,
  openCreateBoardDialog,
  closeCreateBoardDialog,
  openEditBoardDialog,
  closeEditBoardDialog,
  openShareBoardDialog,
  closeShareBoardDialog,

  openCreateGroupDialog,
  openEditGroupDialog,
  openDeleteGroupDialog,
  closeDeleteGroupDialog,
  closeCreateGroupDialog,
  closeEditGroupDialog,
  openAddUsersToGroupDialog,
  closeAddUsersToGroupDialog,
  openRemoveUserFromGroupDialog,
  closeRemoveUserFromGroupDialog,
  openLeaveGroupDialog,
  closeLeaveGroupDialog,

  openRemoveFavoritePostDialog,
  closeRemoveFavoritePostDialog,

  openGuestCodeDialog,
  closeGuestCodeDialog,
} = dialogsSlice.actions;

export const dialogsSelectors = {
  getContextBoard: createSelector(
    (state) => state.dialogs.contextBoard,
    (data) => data
  ),
  getContextItem: createSelector(
    (state) => state.dialogs.contextItem,
    (data) => data
  ),
  getCreateGroupDialogState: createSelector(
    (state) => state.dialogs.createGroupDialog,
    (data) => data
  ),
  getEditGroupDialogState: createSelector(
    (state) => state.dialogs.editGroupDialog,
    (data) => data
  ),
  getDeleteGroupDialogState: createSelector(
    (state) => state.dialogs.deleteGroupDialog,
    (data) => data
  ),
  getAddUsersToGroupDialogState: createSelector(
    (state) => state.dialogs.addUsersToGroupDialog,
    (data) => data
  ),
  getRemoveUserFromGroupDialogState: createSelector(
    (state) => state.dialogs.removeUserFromGroupDialog,
    (data) => data
  ),
  getLeaveGroupDialogState: createSelector(
    (state) => state.dialogs.leaveGroupDialog,
    (data) => data
  ),
  getDeleteBoardDialogState: createSelector(
    (state) => state.dialogs.deleteBoardDialog,
    (data) => data
  ),
  getEditBoardDialogState: createSelector(
    (state) => state.dialogs.editBoardDialog,
    (data) => data
  ),
  getCreateBoardDialogState: createSelector(
    (state) => state.dialogs.createBoardDialog,
    (data) => data
  ),
  getUnfollowBoardDialogState: createSelector(
    (state) => state.dialogs.unfollowBoardDialog,
    (data) => data
  ),
  getUnfollowTagDialogState: createSelector(
    (state) => state.dialogs.unfollowTagDialog,
    (data) => data
  ),
  getShareBoardDialogState: createSelector(
    (state) => state.dialogs.shareBoardDialog,
    (data) => data
  ),
  getDeleteAccountDialogState: createSelector(
    (state) => state.dialogs.deleteAccountDialog,
    (data) => data
  ),
  getEndChallengeDialogState: createSelector(
    (state) => state.dialogs.endChallengeDialog,
    (data) => data
  ),
  getRemoveFavoritePostDialogState: createSelector(
    (state) => state.dialogs.removeFavoritePostDialog,
    (data) => data
  ),
  getCopyPostDialogState: createSelector(
    (state) => state.dialogs.copyPostDialog,
    (data) => data
  ),
  getEditPostDialogState: createSelector(
    (state) => state.dialogs.editArticleDialog,
    (data) => data
  ),
  getCreatePostDialogState: createSelector(
    (state) => state.dialogs.createArticleDialog,
    (data) => data
  ),
  getSharePostDialogState: createSelector(
    (state) => state.dialogs.sharePostDialog,
    (data) => data
  ),
  getRemoveArticleFromBoardDialogState: createSelector(
    (state) => state.dialogs.removeArticleFromBoardDialog,
    (data) => data
  ),
  getFilePreviewDialogState: createSelector(
    (state) => state.dialogs.filePreviewDialog,
    (data) => data
  ),
  getGuestCodeDialogState: createSelector(
    (state) => state.dialogs.guestCodeDialog,
    (data) => data
  ),
};

export default dialogsSlice.reducer;

export const savePostToBoard = (data) => (dispatch, getState) => {
  const state = getState();

  const { boards: boardId } = data;
  const { authTokens } = state.authentication;
  const {
    contextItem: { id: postId },
  } = state.dialogs;

  axios({
    method: "POST",
    url: Endpoints.addToBoard,
    data: JSON.stringify({ boardId, postId }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((response) => {
      if (response.data) {
        toastr.success("", "Post was saved successfuly.");
        dispatch(closeSaveArticleDialog());
      }
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        toastr.error("", error.response?.data);
      }
      console.log(error);
    });
};
