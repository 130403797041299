import CustomInput from "./CustomInput";
import React from "react";
import { useField } from "formik";

const FormikInput = (props) => {
  const [field, meta] = useField(props);
  const { labelText, id, ...restProps } = props;

  return (
    <>
      <div style={{ display: "flex", flex: "1" }}>
        <CustomInput
          id={id}
          labelText={labelText}
          formControlProps={{ fullWidth: true }}
          inputProps={{ ...field, ...restProps }}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.button ? props.button() : null}
        </div>
      </div>

      {meta.touched && meta.error ? (
        <p
          style={{
            fontSize: "12px",
            color: "red",
            position: "relative",
            top: "-15px",
          }}
        >
          {meta.error}
        </p>
      ) : null}
    </>
  );
};

export default FormikInput;
