import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  favoritesSelectors,
  clearFavoritePosts,
} from "../../../../store/favouritesSlice";
import SizedStackGrid from "components/SizedStackGrid/SizedStackGrid";
import { useInfiniteLoading } from "utils/hooks/infiniteLoading";
import { getFavoritesPosts } from "../../../../store/generalPostsSlice";
import {
  generalPostActionTypes,
  postActionTypes,
  usePostCard,
} from "../../../../utils/hooks/postCardOptions";
import { routerSelectors } from "../../../../store/routerActions";

export default function FavoritesPostsView() {
  const dispatch = useDispatch();

  const locationKey = useSelector(routerSelectors.getLocationKey);
  const posts = useSelector(favoritesSelectors.posts);

  const apiCall = useCallback((data) => getFavoritesPosts(data), []);

  const {
    bottomBoundaryNode,
    isFetching,
    resetPagination,
    cancelOngoingCall,
  } = useInfiniteLoading({ apiCall });

  useEffect(() => {
    dispatch(clearFavoritePosts());
    resetPagination();

    return () => cancelOngoingCall();
  }, [dispatch, resetPagination, cancelOngoingCall, locationKey]);

  const favoritesPostActionTypes = useMemo(() => {
    return generalPostActionTypes
      .filter((type) => type !== postActionTypes.addToFavourites)
      .concat([postActionTypes.removeFromFavorites]);
  }, []);

  const { postCardRenderer } = usePostCard(favoritesPostActionTypes);

  return (
    <div style={{ padding: "1rem 0" }}>
      <SizedStackGrid>{posts.map(postCardRenderer)}</SizedStackGrid>
      {bottomBoundaryNode}

      <div style={{ display: "grid", placeItems: "center" }}>
        <p>
          {!posts.length && !isFetching
            ? "Please add a post to Favorites in order to see it here."
            : ""}
        </p>
      </div>
    </div>
  );
}
