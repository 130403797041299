import React, { useState } from "react";
import PropTypes from "prop-types";
import defaultAvatar from "../../../assets/img/placeholder.jpg";
import Paths from "../../../staticData/Paths";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import CancelIcon from "@material-ui/icons/Cancel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { removeUserShareAccess } from "../../../store/generalActions";
import Button from "../../../components/CustomButtons/Button";
import Dialog from "../../../components/Dialog/Dialog";

function UserShared({
  user,
  onUserClick,
  entityId,
  entityType,
  onRemoveShareAccess,
}) {
  const dispatch = useDispatch();

  const { id, userName, profilePicture, email } = user;
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const userDetailsPath = Paths.otherUserAccount.replace(":id", id);

  const handleOnClick = () => {
    onUserClick();
    dispatch(push(userDetailsPath));
  };

  const handleShareAccessRemoval = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const success = await dispatch(
      removeUserShareAccess({ email, entityType, entityId })
    );

    setIsLoading(false);

    if (success) {
      onRemoveShareAccess(id);
    }
  };

  const avatar = (
    <Avatar alt={userName} src={profilePicture || defaultAvatar} />
  );

  const deleteIcon = (
    <Tooltip title={"Remove share access"} placement={"top"}>
      {isLoading ? <CircularProgress size={20} /> : <CancelIcon />}
    </Tooltip>
  );

  return (
    <div
      style={{
        margin: "0.4rem",
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Chip
        variant={"outlined"}
        avatar={avatar}
        label={userName}
        clickable
        onClick={handleOnClick}
        deleteIcon={deleteIcon}
        onDelete={() => setIsConfirmDialogOpen(true)}
      />
      <Dialog
        title={"Remove Share Access"}
        isLoading={isLoading}
        isOpen={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        actions={() => (
          <Button
            round
            color="success"
            style={{ marginLeft: "1rem" }}
            onClick={handleShareAccessRemoval}
          >
            Confirm
          </Button>
        )}
      >
        <p>
          Are you sure you want to remove share access for user{" "}
          <strong>{userName}</strong>?
        </p>
      </Dialog>
    </div>
  );
}

function SharedToUsers({ users, setUsers, ...restProps }) {
  function onRemoveShareAccess(id) {
    setUsers((data) => data.filter((x) => x.id !== id));
  }

  if (users.length) {
    return (
      <div>
        <div style={{ marginTop: "2rem", fontWeight: "bold" }}>
          Shared with users:
        </div>
        {users.map((user) => (
          <UserShared
            key={user.id}
            user={user}
            onRemoveShareAccess={onRemoveShareAccess}
            {...restProps}
          />
        ))}
      </div>
    );
  } else {
    return null;
  }
}

export default SharedToUsers;

const entityIdProps = PropTypes.number.isRequired;
const userProps = PropTypes.shape({
  id: PropTypes.string,
  userName: PropTypes.string,
  profilePicture: PropTypes.string,
});

UserShared.propTypes = {
  user: userProps.isRequired,
  entityId: entityIdProps,
  entityType: PropTypes.oneOf(["post", "board"]).isRequired,
  onUserClick: PropTypes.func.isRequired,
  onRemoveShareAccess: PropTypes.func.isRequired,
};

SharedToUsers.propTypes = {
  users: PropTypes.arrayOf(userProps).isRequired,
  setUsers: PropTypes.func,
  entityId: entityIdProps,
  entityType: PropTypes.oneOf(["post", "board"]).isRequired,
  onUserClick: PropTypes.func,
};

SharedToUsers.defaultProps = {
  users: [],
  setUsers: () => {},
  onUserClick: () => {},
  entityId: 0,
};
