import React from "react";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { useDispatch } from "react-redux";
import Add from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import { useCreatePostOptions } from "utils/hooks/createPostOptions";
import { openCreateArticleDialog } from "../../../store/dialogsSlice";

export default function CreatePostButton() {
  const dispatch = useDispatch();

  const handleOnClick = (option) => {
    dispatch(openCreateArticleDialog({ postType: option.id }));
  };

  const { options } = useCreatePostOptions();

  return (
    <Tooltip placement={"left"} title={"Create"}>
      <div>
        <CustomDropdown
          caret={false}
          hoverColor="primary"
          buttonIcon={Add}
          onClick={handleOnClick}
          dropPlacement={"top-end"}
          buttonProps={{
            color: "primary",
            justIcon: true,
            round: true,
            size: "lg",
          }}
          dropdownList={options}
        />
      </div>
    </Tooltip>
  );
}
