import React from "react";
import PropTypes from "prop-types";
import { FileIcon as OFileIcon, defaultStyles } from "react-file-icon";

const FileIcon = ({ extension }) => {
  return <OFileIcon extension={extension} {...defaultStyles[extension]} />;
};
FileIcon.propTypes = {
  extension: PropTypes.string,
};

export default FileIcon;
