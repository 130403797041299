import { createSlice } from "@reduxjs/toolkit";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import axios from "axios";
import Endpoints from "../staticData/Endpoints";
import { toastr } from "react-redux-toastr";
import { displayValidationMessage } from "../utils/validationDisplayHelper";
import { createSelector } from "reselect";
import { addFollowingBoards } from "./followingSlice";
import { setContextBoard } from "./dialogsSlice";
import { setOtherUserBoards } from "./otherUserSlice";
import { setMyAccountBoards } from "./myAccountSlice";
import { setBoards } from "./boardsSlice";

const generalBoardsSlice = createSlice({
  name: "generalBoards",
  initialState: {
    data: [],
  },
  reducers: {
    addBoards(state, action) {
      action.payload.forEach((board) => {
        state.data.push(board);
      });
    },
    removeBoard(state, action) {
      state.data = state.data.filter((board) => board.id !== action.payload);
    },
    clearBoards(state) {
      state.data = [];
    },
  },
});

export const {
  addBoards,
  removeBoard,
  clearBoards,
} = generalBoardsSlice.actions;

export default generalBoardsSlice.reducer;

export const generalBoardsSelectors = {
  boards: createSelector(
    (state) => state.generalBoards.data,
    (data) => data
  ),
};

export const createBoard = ({
  formValues,
  editorState,
  postId,
  backgroundImage,
  withNotify,
}) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const formData = new FormData();
  formData.append("isPrivate", formValues.isPrivate);
  formData.append("title", formValues.title);
  postId && formData.append("fromEntityId", postId);
  backgroundImage && formData.append("backgroundImage", backgroundImage);
  formData.append(
    "description",
    draftToHtml(convertToRaw(editorState.getCurrentContent()))
  );

  return axios({
    method: "POST",
    url: withNotify ? Endpoints.createBoardAndNotify : Endpoints.createBoard,
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        toastr.success("Board was created successfully.");

        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const createBoardAndNotify = (data) => (dispatch) => {
  return dispatch(createBoard({ ...data, withNotify: true }));
};

export const editBoard = ({ id, formValues, editorState, backgroundImage }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const formData = new FormData();
  formData.append("id", id);
  formData.append("private", formValues.isPrivate);
  formData.append("title", formValues.title);
  formData.append(
    "description",
    draftToHtml(convertToRaw(editorState.getCurrentContent()))
  );

  if (backgroundImage.uploadedFiles.length) {
    formData.append("url", backgroundImage.uploadedFiles[0]);
  } else if (backgroundImage.backgroundImage) {
    formData.append("backgroundImage", backgroundImage.backgroundImage);
  }

  return axios({
    method: "PUT",
    url: Endpoints.editBoard,
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        toastr.success("Board was edited successfully.");

        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const deleteBoard = ({ id }) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  return axios({
    method: "DELETE",
    url: Endpoints.deleteBoard,
    params: { id },
    headers: { authorization: `Bearer ${authTokens}` },
  })
    .then((result) => {
      if (result.status === 200) {
        dispatch(removeBoard(id));
        toastr.success("", "Board was deleted successfully.");
        return true;
      }
    })
    .catch((error) => {
      displayValidationMessage(error, dispatch);
      return false;
    });
};

export const shareBoard = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { boardId: entityId, formValues } = data;
  const { usersAndGroups, message } = formValues;

  const { shareEntities, groupIds } = usersAndGroups.reduce(
    (acc, elem) => {
      elem.groupId
        ? acc.groupIds.push(elem.groupId)
        : acc.shareEntities.push({ email: elem.email, userId: elem.userId });

      return acc;
    },
    {
      shareEntities: [],
      groupIds: [],
    }
  );

  return axios({
    method: "POST",
    url: Endpoints.shareBoard,
    data: JSON.stringify({ entityId, shareEntities, groupIds, message }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status } = result;

      if (status === 200) {
        toastr.success("", "Board was successfully shared.");
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const getSharedForBoards = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { boardId } = data;

  return axios({
    method: "GET",
    url: Endpoints.getShared
      .replace(":shareEntity", "board")
      .replace(":entityId", boardId),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status, data } = result;

      if (status === 200) {
        return data;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const getShareSuggestionsForBoards = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { key, boardId } = data;

  return axios({
    method: "GET",
    url: Endpoints.getShareSuggestions
      .replace(":shareEntity", "board")
      .replace(":entityId", boardId)
      .replace(":searchText", key),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        return result.data;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const followBoard = ({ id }) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  return axios({
    method: "POST",
    url: Endpoints.followBoard,
    data: JSON.stringify({ boardId: id }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        toastr.success("", "Board was successfully followed.");
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const unfollowBoard = ({ id }) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  return axios({
    method: "DELETE",
    url: Endpoints.unfollowBoard,
    params: { id },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        toastr.success("", "Board was successfully unfollowed.");
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const getBoardDetails = ({ id }) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const locationState = state.router.location.state;
  const guestCode = locationState?.guestCode;

  return axios({
    method: "GET",
    url: Endpoints.getBoardDetails,
    params: { id, ...(guestCode && { code: guestCode }) },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        dispatch(setContextBoard(result.data));

        return result.data;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const activateGuestCodeForBoard = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { boardId } = data;

  return axios({
    method: "GET",
    url: Endpoints.activateGuestCodeForBoard,
    params: { id: boardId },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { data, status } = result;

      if (status === 200) {
        return data;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const deactivateGuestCodeForBoard = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { boardId } = data;

  return axios({
    method: "DELETE",
    url: Endpoints.deactivateGuestCodeForBoard,
    params: { id: boardId },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { data, status } = result;

      if (status === 200) {
        return data;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const removePostFromBoard = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const { postId, boardId } = data;

  return axios({
    method: "DELETE",
    url: Endpoints.removePostFromBoard,
    params: { postId, boardId },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  });
};

// GET BOARDS ACTIONS

export const getBoardsByKeyword = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const { searchText, cancelToken, lastUpdatedOn } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.searchBoardsByKeyword,
    params: {
      ...(lastUpdatedOn && { lastUpdatedOn }),
      key: searchText,
    },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      dispatch(addBoards(data));

      return {
        areMore: data.length === 10,
        lastUpdatedOn: data[data.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getBoardsByTag = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  const { lastUpdatedOn, cancelToken, tagList } = data;

  return axios({
    method: "POST",
    cancelToken: cancelToken.token,
    url: Endpoints.searchBoardsByTag,
    data: JSON.stringify({
      ...(lastUpdatedOn && { lastUpdatedOn }),
      tags: tagList,
    }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      dispatch(addBoards(data));

      return {
        areMore: data.length === 10,
        lastUpdatedOn: data[data.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getOtherUserBoards = (data) => (dispatch, getState) => {
  const { authTokens } = getState().authentication;
  const { lastUpdatedOn, cancelToken, userProfileId } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getUsersAccountBoards,
    params: {
      lastUpdatedOn,
      userId: userProfileId,
    },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      dispatch(setOtherUserBoards(data));

      return {
        areMore: data.length === 10,
        lastUpdatedOn: data[data.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getMyAccountBoards = (data) => (dispatch, getState) => {
  const { authTokens } = getState().authentication;
  const { lastUpdatedOn, cancelToken } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getBoards,
    params: {
      lastUpdatedOn,
      self: true,
    },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      dispatch(setMyAccountBoards(data));

      return {
        areMore: data.length === 10,
        lastUpdatedOn: data[data.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getBoardsItems = (data) => (dispatch, getState) => {
  const { authTokens } = getState().authentication;
  const { lastUpdatedOn, cancelToken } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getBoards,
    params: {
      lastUpdatedOn,
    },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      dispatch(setBoards(data));

      return {
        areMore: data.length === 10,
        lastUpdatedOn: data[data.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getFollowingBoards = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { lastUpdatedOn, cancelToken } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getFollowingBoards,
    params: {
      lastUpdatedOn,
    },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      dispatch(addFollowingBoards(data));

      return {
        areMore: data.length === 10,
        lastUpdatedOn: data[data.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getPublicBoards = (data) => (dispatch, getState) => {
  const { authTokens } = getState().authentication;
  const { lastUpdatedOn, cancelToken } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getPublicBoards,
    params: {
      lastUpdatedOn,
    },
    headers: {
      "content-type": "application/json",
      ...(authTokens && { authorization: `Bearer ${authTokens}` }),
    },
  })
    .then(({ data }) => {
      dispatch(addBoards(data));

      return {
        areMore: data.length === 10,
        lastUpdatedOn: data[data.length - 1]?.lastUpdatedOn,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};
