import { createSelector } from "reselect/src";

export const routerSelectors = {
  getLocationState: createSelector(
    (state) => state.router.location.state,
    (data) => data
  ),

  getLocationKey: createSelector(
    (state) => state.router.location.key,
    (data) => data
  ),

  getQueryParamsByName: (paramName) => (state) =>
    createSelector(
      (state) => state.router.location.query,
      (_, paramName) => paramName,
      (locationQuery, paramName) => locationQuery[paramName]
    )(state, paramName),

  getQueryTagParams: (state) =>
    createSelector(
      routerSelectors.getQueryParamsByName("tag"),
      (tags) => tags
    )(state),

  getQueryKeyParams: (state) =>
    createSelector(
      routerSelectors.getQueryParamsByName("key"),
      (tags) => tags
    )(state),

  getQueryRedirectParams: (state) =>
    createSelector(
      routerSelectors.getQueryParamsByName("redirect"),
      (tags) => tags
    )(state),
};
