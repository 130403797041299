import React from "react";
import StandardPage from "components/StandardPage/StandardPage";
import SearchPagePostsView from "../../newViews/posts/SearchPagePostsView/SearchPagePostsView";
import SearchPageBoardsView from "../../newViews/boards/SearchPageBoardsView/SearchPageBoardsView";
import NavPills from "components/NavPills/NavPills";
import Description from "@material-ui/icons/Description";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import FollowUnfollowTag from "../../organisms/FollowUnfollowTag/FollowUnfollowTag";
import TagList from "../../molecules/TagList/TagList";

export default function SearchPage() {
  return (
    <StandardPage title={"Search"}>
      <FollowUnfollowTag />
      <TagList />

      <NavPills
        alignCenter
        color="primary"
        tabs={[
          {
            tabButton: "Posts",
            tabIcon: Description,
            tabContent: <SearchPagePostsView />,
          },
          {
            tabButton: "Boards",
            tabIcon: DeveloperBoardIcon,
            tabContent: <SearchPageBoardsView />,
          },
        ]}
      />
    </StandardPage>
  );
}
