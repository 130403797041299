import React from "react";
import { useDispatch } from "react-redux";
import Add from "@material-ui/icons/Add";
import { openCreateGroupDialog } from "../../../store/dialogsSlice";
import Button from "../../../components/CustomButtons/Button";
import Tooltip from "@material-ui/core/Tooltip";

function CreateGroupButton() {
  const dispatch = useDispatch();

  const handleOnClick = () => dispatch(openCreateGroupDialog());

  return (
    <Tooltip title={"Create Group"} placement={"left"}>
      <div>
        <Button
          round
          justIcon
          size={"lg"}
          color={"primary"}
          onClick={handleOnClick}
        >
          <Add />
        </Button>
      </div>
    </Tooltip>
  );
}

export default CreateGroupButton;
