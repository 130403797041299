import React, { useCallback, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import libraryPageStyle from "../../../ArticlesView/LibraryPageStyle";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBoards,
  generalBoardsSelectors,
  getBoardsByKeyword,
  getBoardsByTag,
} from "store/generalBoardsSlice";
import { routerSelectors } from "store/routerActions";
import SizedStackGrid from "components/SizedStackGrid/SizedStackGrid";
import { useInfiniteLoading } from "utils/hooks/infiniteLoading";
import {
  generalBoardActionTypes,
  useBoardCard,
} from "utils/hooks/boardCardOptions";

const useStyles = makeStyles(libraryPageStyle);

export default function SearchPageBoardsView() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const locationKey = useSelector(routerSelectors.getLocationKey);
  const tag = useSelector(routerSelectors.getQueryTagParams);
  const key = useSelector(routerSelectors.getQueryKeyParams);
  const boards = useSelector(generalBoardsSelectors.boards);

  const tagList = useMemo(() => tag?.split(",").map((tag) => `#${tag}`), [tag]);

  const apiCall = useCallback(
    (data) => {
      if (tagList) {
        return getBoardsByTag({ ...data, tagList });
      } else {
        return getBoardsByKeyword({ ...data, searchText: key });
      }
    },
    [tagList, key]
  );

  const {
    isFetching,
    resetPagination,
    cancelOngoingCall,
    bottomBoundaryNode,
  } = useInfiniteLoading({ apiCall });

  useEffect(() => {
    dispatch(clearBoards());
    resetPagination();

    return () => cancelOngoingCall();
  }, [dispatch, tag, key, resetPagination, cancelOngoingCall, locationKey]);

  const { boardCardRenderer } = useBoardCard(generalBoardActionTypes);

  return (
    <div className={classes.container} style={{ paddingTop: "30px" }}>
      <SizedStackGrid>{boards.map(boardCardRenderer)}</SizedStackGrid>
      {bottomBoundaryNode}

      <div style={{ textAlign: "center", padding: "2rem" }}>
        {!boards.length && !isFetching ? "No Boards found." : ""}
      </div>
    </div>
  );
}
