import React, { useCallback, useEffect } from "react";
import SizedStackGrid from "../../../../components/SizedStackGrid/SizedStackGrid";
import { makeStyles } from "@material-ui/core/styles";
import libraryPageStyle from "../../../ArticlesView/LibraryPageStyle";
import { useDispatch, useSelector } from "react-redux";
import { useInfiniteLoading } from "../../../../utils/hooks/infiniteLoading";
import { getBoardsItems } from "../../../../store/generalBoardsSlice";
import {
  generalBoardActionTypes,
  useBoardCard,
} from "../../../../utils/hooks/boardCardOptions";
import { routerSelectors } from "../../../../store/routerActions";
import { boardsSelectors, clearBoards } from "../../../../store/boardsSlice";

const useStyles = makeStyles(libraryPageStyle);

export default function BoardsListView() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const locationKey = useSelector(routerSelectors.getLocationKey);
  const boards = useSelector(boardsSelectors.boards);

  const apiCall = useCallback((data) => getBoardsItems(data), []);

  const {
    isFetching,
    resetPagination,
    cancelOngoingCall,
    bottomBoundaryNode,
  } = useInfiniteLoading({ apiCall });

  useEffect(() => {
    return () => {
      resetPagination();
      cancelOngoingCall();
      dispatch(clearBoards());
    };
  }, [dispatch, resetPagination, cancelOngoingCall, locationKey]);

  const { boardCardRenderer } = useBoardCard(generalBoardActionTypes);

  return (
    <div className={classes.container} style={{ paddingTop: "30px" }}>
      <SizedStackGrid>{boards.map(boardCardRenderer)}</SizedStackGrid>
      {bottomBoundaryNode}

      <div style={{ height: "6rem", textAlign: "center", padding: "2rem" }}>
        {!boards.length && !isFetching
          ? "Create a board in order to see it here."
          : ""}
      </div>
    </div>
  );
}
