import React, { useEffect } from "react";
import Header from "components/Header/Header.js";
import UserMenu from "../../molecules/UserMenu/UserMenu";
import sanoboardLogo from "../../../assets/svg/sanoboard-logo.svg";
import defaultAvatar from "assets/img/placeholder.jpg";
import defaultImage from "assets/img/image_placeholder.jpg";
import ReduxToastr from "react-redux-toastr/lib/ReduxToastr";
import DeletePostDialog from "../../newViews/dialogs/DeletePostDialog/DeletePostDialog";
import DeleteBoardDialog from "../../newViews/dialogs/DeleteBoardDialog/DeleteBoardDialog";
import UnfollowBoardDialog from "../../newViews/dialogs/UnfollowBoardDialog/UnfollowBoardDialog";
import EndChallengeDialog from "../../newViews/dialogs/EndChallengeDialog/EndChallengeDialog";
import EditPostDialog from "views/newViews/dialogs/EditPostDialog/EditPost";
import CreatePost from "../../newViews/dialogs/CreatePostDialog/CreatePostDialog";
import EditBoardDialog from "../../newViews/dialogs/EditBoardDialog/EditBoard";
import SharePostDialog from "../../newViews/dialogs/SharePostDialog/SharePostDialog";
import SavePostDialog from "../../newViews/dialogs/SavePostDialog/SavePostDialog";
import ShareBoardDialog from "../../newViews/dialogs/ShareBoardDialog/ShareBoardDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  authenticationSelectors,
  getMyAccount,
} from "store/authenticationSlice";
import RemovePostFromBoardDialog from "../../newViews/dialogs/RemovePostFromBoardDialog/RemovePostFromBoardDialog";
import FilePreviewDialog from "../../newViews/dialogs/FilePreviewDialog/FilePreviewDialog";
import CopyPostDialog from "../../newViews/dialogs/CopyPostDialog/CopyPost";
import CreateGroupDialog from "../../newViews/dialogs/CreateGroupDialog/CreateGroup";
import DeleteGroupDialog from "../../newViews/dialogs/DeleteGroupDialog/DeleteGroup";
import EditGroupDialog from "../../newViews/dialogs/EditGroupDialog/EditGroup";
import AddUsersToGroupDialog from "../../newViews/dialogs/AddUsersToGroupDialog/AddUsersToGroup";
import RemoveUserFromGroupDialog from "../../newViews/dialogs/RemoveUserFromGroupDialog/RemoveUserFromGroup";
import LeaveGroupDialog from "../../newViews/dialogs/LeaveGroupDialog/LeaveGroup";
import RemoveFavoritePost from "../../newViews/dialogs/RemoveFavoritePostDialog/RemoveFavoritePostDialog";
import GuestCodeDialog from "../../newViews/dialogs/GuestCodeDialog/GuestCodeDialog";
import UnfollowTagDialog from "../../newViews/dialogs/UnfollowTagDialog/UnfollowTagDialog";
import useGeneralTagRedirect from "../../../utils/hooks/generalTagRedirect";
import CreateBoardDialog from "../../newViews/dialogs/CreateBoardDialog/CreateBoardDialog";

export default function MainLayout({ children }) {
  const dispatch = useDispatch();
  const authTokens = useSelector(authenticationSelectors.authTokens);

  useGeneralTagRedirect();

  useEffect(() => {
    if (authTokens) {
      dispatch(getMyAccount());
    }
  }, [dispatch, authTokens]);

  //TODO Find a fix for this workaround that
  // preloads the defaultAvatar and defaultImage
  return (
    <div>
      {/*<div className="cd-section">*/}
      <img src={defaultAvatar} style={{ display: "none" }} alt={"asd"} />
      <img src={defaultImage} style={{ display: "none" }} alt={"asd"} />

      <Header
        absolute
        fixed
        brand={
          <img
            src={sanoboardLogo}
            alt={"Sanoboard Logo"}
            style={{ width: "200px" }}
          />
        }
        color="primary"
        links={<UserMenu />}
      />

      {children}

      <CreatePost />
      <SavePostDialog />
      <SharePostDialog />
      <EditPostDialog />
      <DeletePostDialog />
      <RemovePostFromBoardDialog />
      <FilePreviewDialog />
      <CopyPostDialog />
      <EndChallengeDialog />

      <ShareBoardDialog />
      <CreateBoardDialog />
      <EditBoardDialog />
      <DeleteBoardDialog />
      <UnfollowBoardDialog />
      <UnfollowTagDialog />

      <CreateGroupDialog />
      <EditGroupDialog />
      <DeleteGroupDialog />
      <AddUsersToGroupDialog />
      <RemoveUserFromGroupDialog />
      <LeaveGroupDialog />

      <RemoveFavoritePost />
      <GuestCodeDialog />

      <ReduxToastr
        timeOut={10000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-center"
        getState={(state) => state.toastr}
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        closeOnToastrClick
      />
    </div>
  );
}
