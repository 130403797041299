import { EditorState } from "draft-js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTagSuggestions } from "store/generalPostsSlice";

export function validateEditorTextLength({ state, maxLength, minLength = 0 }) {
  const contentLength = state.getCurrentContent().getPlainText().length;

  if (maxLength) {
    return contentLength >= minLength && contentLength <= maxLength;
  }

  return contentLength >= minLength;
}

export function useEditorState() {
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);

  const constructTagOptions = (tags) =>
    tags?.map((tag) => ({
      text: tag,
      value: tag.slice(1),
      url: "",
    }));

  useEffect(() => {
    (async () => {
      const tagsData = await dispatch(getTagSuggestions());

      !!tagsData && setTags(tagsData);
    })();
  }, [dispatch]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  const clearEditor = () => setEditorState(EditorState.createEmpty());

  const tagOptions = constructTagOptions(tags);

  return {
    tagOptions,
    editorState,
    handleEditorChange,
    clearEditor,
    setEditorState,
  };
}
