import React from "react";
import { Route, Switch } from "react-router";
import StandardPage from "components/StandardPage/StandardPage";
import Paths from "../../../staticData/Paths";
import FullCalendar from "../../organisms/FullCalendar/FullCalendar";
import Endpoints from "../../../staticData/Endpoints";
import LibraryPostsView from "../../newViews/posts/LibraryPostsView/LibraryPostsView";

export default function LibraryPage() {
  return (
    <StandardPage title={"Library"}>
      <Switch>
        <Route
          path={Paths.libraryCalendar}
          component={() => (
            <FullCalendar endpoint={Endpoints.libraryCalendar} />
          )}
        />

        <Route path={Paths.library} component={LibraryPostsView} />
      </Switch>
    </StandardPage>
  );
}
