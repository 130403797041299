import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import libraryPageStyle from "../../../Library/LibraryPageStyle";
import { closeFilePreviewDialog, dialogsSelectors } from "store/dialogsSlice";
import PostAttachmentsCarousel from "../../../organisms/PostAttachmentsCarousel/PostAttachmentsCarousel";

const useStyles = makeStyles(libraryPageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function FilePreviewDialog() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    isOpen,
    data: { file: url, attachments },
  } = useSelector(dialogsSelectors.getFilePreviewDialogState);

  function handleCancel() {
    dispatch(closeFilePreviewDialog());
  }

  const selectedSlide = attachments?.findIndex((x) => x.url === url);

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {}}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <div className={classes.container}>
        <DialogContent>
          <GridContainer justify="center">
            <GridItem sm={12}>
              <PostAttachmentsCarousel
                attachments={attachments}
                selectedSlide={selectedSlide}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer justify={"center"}>
            <GridItem xs={12}>
              <div>
                <Button
                  round
                  onClick={handleCancel}
                  color="primary"
                  style={{
                    margin: "1rem 0 0 1rem",
                  }}
                >
                  Close
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </div>
    </Dialog>
  );
}
