import React, { useState } from "react";
import Button from "../../../../components/CustomButtons/Button";
import Dialog from "../../../../components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { removePostFromFavorites } from "../../../../store/favouritesSlice";
import {
  closeRemoveFavoritePostDialog,
  dialogsSelectors,
} from "../../../../store/dialogsSlice";

function RemoveFavoritePost() {
  const dispatch = useDispatch();
  const {
    isOpen,
    data: { id },
  } = useSelector(dialogsSelectors.getRemoveFavoritePostDialogState);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => dispatch(closeRemoveFavoritePostDialog());

  async function handleRemoveFavoritePost() {
    setIsLoading(true);
    const success = await dispatch(removePostFromFavorites({ id }));
    setIsLoading(false);

    if (success) {
      handleClose();
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      isLoading={isLoading}
      title={"Remove post from Favorites"}
      onClose={handleClose}
      actions={() => (
        <Button
          round
          color="danger"
          style={{ marginLeft: "1rem" }}
          onClick={handleRemoveFavoritePost}
        >
          Remove
        </Button>
      )}
    >
      <p>Are you sure you want to remove this post from your Favorites?</p>
      <hr />
    </Dialog>
  );
}

export default RemoveFavoritePost;
