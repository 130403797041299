import * as Yup from "yup";
import moment from "moment";

const postValidationSchema = Yup.object({
  isPrivate: Yup.boolean().required("Visibility is required."),
  type: Yup.string().required("Type is required."),
  boards: Yup.mixed().isType("number").nullable,
  eventTitle: Yup.string().test(
    "eventTitle",
    "Event Title is required.",
    (value, context) => {
      const postIsEvent = context.parent.type === "event";

      if (!postIsEvent) return true;

      if (value === undefined) return false;

      return !!value.trim();
    }
  ),
  webinarLink: Yup.string().url("Please insert a valid URL."),
  location: Yup.object({
    address: Yup.string(),
    latitude: Yup.number().nullable(true),
    longitude: Yup.number().nullable(true),
  }).test("location", "Please select a location.", (value, context) => {
    const location = context.parent.location;
    const { address, latitude, longitude } = location;

    return (
      address === undefined ||
      (!!address && latitude !== null && longitude !== null)
    );
  }),
  startTime: Yup.object()
    .test("startTime", "Start Time is required.", (value, context) => {
      const postIsEvent = context.parent.type === "event";

      if (!postIsEvent) return true;

      return !!value;
    })
    .test(
      "startTime",
      "Start Time can not be after End Time.",
      (value, context) => {
        const startTimeIsValid = moment.isMoment(context.parent.startTime);
        const endTimeIsValid = moment.isMoment(context.parent.endTime);
        const startTimeIsAfterEndTime = moment(
          context.parent.startTime
        ).isSameOrAfter(context.parent.endTime, "minute");
        const postIsEvent = context.parent.type === "event";

        return !(
          postIsEvent &&
          startTimeIsValid &&
          endTimeIsValid &&
          startTimeIsAfterEndTime
        );
      }
    ),
  endTime: Yup.object()
    .test("endTime", "End Time is required.", (value, context) => {
      const postIsEvent = context.parent.type === "event";

      if (!postIsEvent) return true;

      return !!value;
    })
    .test(
      "startTime",
      "End Time can not be before Start Time.",
      (value, context) => {
        const startTimeIsValid = moment.isMoment(context.parent.startTime);
        const endTimeIsValid = moment.isMoment(context.parent.endTime);
        const endTimeIsBeforeStartTime = moment(
          context.parent.endTime
        ).isSameOrBefore(context.parent.startTime, "minute");
        const postIsEvent = context.parent.type === "event";

        return !(
          postIsEvent &&
          startTimeIsValid &&
          endTimeIsValid &&
          endTimeIsBeforeStartTime
        );
      }
    ),
});

export { postValidationSchema };
