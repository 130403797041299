import React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../../components/CustomInput/FormikInput";
import InputAdornmentIcon from "../../../components/InputAdornments/InputAdornmentIcon";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import FormikCustomPasswordInput from "../../../components/CustomInput/FormikCustomPasswordInput";
import FormikCustomCheckboxInput from "../../../components/CustomInput/FormikCustomCheckboxInput";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import javascriptStyles from "../../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";

const useStyles = makeStyles(javascriptStyles);

function RegisterForm({ onSubmit, submitButton }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        username: "",
        email: "",
        password: "",
        termsAndConditions: false,
      }}
      validationSchema={Yup.object({
        username: Yup.string().required("Username is required."),
        email: Yup.string()
          .email("Please use a valid email format.")
          .required("Email is required."),
        password: Yup.string()
          .required("Password is required")
          .test(
            "password",
            "Password must contain at least one lowercase letter.",
            (val) => /[a-z]/.test(val)
          )
          .test(
            "password",
            "Password must contain at least one uppercase letter.",
            (val) => /[A-Z]/.test(val)
          )
          .test(
            "password",
            "Password must contain at least one number.",
            (val) => /[\d]/.test(val)
          )
          .test(
            "password",
            "Password must contain at least one non-alphanumeric character.",
            (val) => /[\W]/.test(val)
          )
          .min(8, "Password must have at least 8 characters"),
        termsAndConditions: Yup.boolean(),
      })}
      onSubmit={onSubmit}
    >
      <Form>
        <FormikInput
          id={"register-username-input"}
          name={"username"}
          type={"text"}
          placeholder="Username"
          endAdornment={<InputAdornmentIcon icon={Face} />}
        />
        <FormikInput
          id={"register-email-input"}
          name={"email"}
          type={"text"}
          placeholder="Email"
          endAdornment={<InputAdornmentIcon icon={Email} />}
        />
        <FormikCustomPasswordInput
          id={"register-password-input"}
          name={"password"}
          labelText={"Password"}
        />
        <FormikCustomCheckboxInput
          id={"register-termsAndConditions-input"}
          name={"termsAndConditions"}
          label={
            <span>
              I agree to the <a href={'/#'}>terms and conditions</a>.
            </span>
          }
        />
        <div className={classes.textCenter}>{submitButton()}</div>
      </Form>
    </Formik>
  );
}

RegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitButton: PropTypes.func.isRequired,
};

export default RegisterForm;
