import React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../../components/CustomInput/FormikInput";
import InputAdornmentIcon from "../../../components/InputAdornments/InputAdornmentIcon";
import Face from "@material-ui/icons/Face";
import FormikCustomPasswordInput from "../../../components/CustomInput/FormikCustomPasswordInput";
import FormikCustomCheckboxInput from "../../../components/CustomInput/FormikCustomCheckboxInput";
import { makeStyles } from "@material-ui/core/styles";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import { Link } from "react-router-dom";
import Paths from "../../../staticData/Paths";
import PropTypes from "prop-types";

const useStyles = makeStyles(javascriptStyles);

function LoginForm({ onSubmit, submitButton }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        userNameOrEmail: "",
        password: "",
        rememberMe: false,
      }}
      validationSchema={Yup.object({
        userNameOrEmail: Yup.string().required(
          "Username or email is required."
        ),
        password: Yup.string().required("Password is required"),
        rememberMe: Yup.boolean().required("Required"),
      })}
      onSubmit={onSubmit}
    >
      <Form>
        <FormikInput
          id={"login-userNameOrEmail-input"}
          name={"userNameOrEmail"}
          type={"text"}
          placeholder="Username or email..."
          endAdornment={<InputAdornmentIcon icon={Face} />}
        />
        <FormikCustomPasswordInput
          id={"login-password-input"}
          name={"password"}
          labelText={"Password"}
        />
        <FormikCustomCheckboxInput
          id={"login-rememberMe-input"}
          name={"rememberMe"}
          label={"Remember Me"}
        />
        <Link to={Paths.recoverPassword}>Recover Password</Link>
        <div className={classes.textCenter}>{submitButton()}</div>
      </Form>
    </Formik>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitButton: PropTypes.func.isRequired,
};

export default LoginForm;
