import React, { useEffect, useState } from "react";
import Button from "../../../../components/CustomButtons/Button";
import Dialog from "../../../../components/Dialog/Dialog";
import InputAdornmentIcon from "../../../../components/InputAdornments/InputAdornmentIcon";
import Code from "@material-ui/icons/Code";
import FormikInput from "../../../../components/CustomInput/FormikInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import {
  dialogsSelectors,
  closeGuestCodeDialog,
} from "../../../../store/dialogsSlice";
import { getEntityByGuestCode } from "../../../../store/generalActions";
import Paths from "../../../../staticData/Paths";

GuestCodeDialog.propTypes = {};

function GuestCodeDialog() {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(dialogsSelectors.getGuestCodeDialogState);

  const [guestCode, setGuestCode] = useState("");

  const handleSubmit = async () => {
    const data = await dispatch(getEntityByGuestCode({ token: guestCode }));

    if (data) {
      const { post, board } = data;

      if (post) {
        const postId = post.id;

        const locationObject = {
          pathname: Paths.postDetails.replace(":id", postId),
          state: { guestCode },
        };

        dispatch(push(locationObject));
      }

      if (board) {
        const boardId = board.id;

        const locationObject = {
          pathname: Paths.boardDetails.replace(":id", boardId),
          state: { guestCode },
        };

        dispatch(push(locationObject));
      }

      handleClose();
    }
  };

  const handleClose = () => dispatch(closeGuestCodeDialog());

  useEffect(() => {
    return () => {
      !isOpen && setGuestCode("");
    };
  }, [isOpen]);

  return (
    <Dialog
      title={"Guest Code"}
      isLoading={false}
      isOpen={isOpen}
      onClose={handleClose}
      actions={() => (
        <Button
          round
          color="success"
          style={{ marginLeft: "1rem" }}
          onClick={handleSubmit}
        >
          Access Now
        </Button>
      )}
    >
      <span>Please enter guest code to view article</span>
      <Formik
        enableReinitialize
        initialValues={{ code: guestCode }}
        validationSchema={Yup.object({
          code: Yup.string().required("Guest code is required."),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormikInput
            id={"guestCode-code-input"}
            name={"code"}
            type={"text"}
            placeholder="Guest Code"
            endAdornment={<InputAdornmentIcon icon={Code} />}
            onChange={(e) => setGuestCode(e.target.value)}
          />
        </Form>
      </Formik>
    </Dialog>
  );
}

export default GuestCodeDialog;
