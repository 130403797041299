import React, { useEffect, useMemo, useState } from "react";
import FollowUnfollow from "../../molecules/FollowUnfollow/FollowUnfollow";
import { openUnfollowTagDialog } from "../../../store/dialogsSlice";
import {
  followingSelectors,
  followTag,
  getTagFollowStatus,
  setIsFollowing,
} from "../../../store/followingSlice";
import { useDispatch, useSelector } from "react-redux";
import { routerSelectors } from "../../../store/routerActions";
import { makeStyles } from "@material-ui/core/styles";
import libraryPageStyle from "../../ArticlesView/LibraryPageStyle";

const useStyles = makeStyles(libraryPageStyle);

export default function FollowUnfollowTag() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const isFollowingTag = useSelector(followingSelectors.isFollowingTag);
  const tagParams = useSelector(routerSelectors.getQueryTagParams);
  const tags = useMemo(() => (tagParams?.length ? tagParams.split(",") : []), [
    tagParams,
  ]);

  const isOneTagSelected = tags.length === 1;
  const [isLoading, setIsLoading] = useState(false);

  async function handleFollow() {
    const tagName = `#${tags[0]}`;

    setIsLoading(true);
    const success = await dispatch(followTag({ tagName }));
    setIsLoading(false);

    if (success) {
      dispatch(setIsFollowing(true));
    }
  }

  useEffect(() => {
    if (isOneTagSelected) {
      (async () => {
        const tag = `#${tags[0]}`;

        const tagIsFollowed = await dispatch(getTagFollowStatus({ tag }));

        dispatch(setIsFollowing(tagIsFollowed));
      })();

      return () => {
        dispatch(setIsFollowing(false));
      };
    }
  }, [isOneTagSelected, tags, dispatch]);

  return isOneTagSelected ? (
    <div className={classes.follow} style={{ position: "relative" }}>
      <div
        className={classes.container}
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "row-reverse",
          top: "-25px",
        }}
      >
        <FollowUnfollow
          isLoading={isLoading}
          isFollowing={isFollowingTag}
          tooltipMessages={{
            forFollow: "Follow Tag",
            forUnfollow: "Unfollow tag",
          }}
          onFollowClick={handleFollow}
          onUnfollowClick={() => dispatch(openUnfollowTagDialog())}
        />
      </div>
    </div>
  ) : null;
}
