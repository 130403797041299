import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  followingSelectors,
  resetFollowingBoards,
} from "../../../../store/followingSlice";
import SizedStackGrid from "../../../../components/SizedStackGrid/SizedStackGrid";
import { useInfiniteLoading } from "../../../../utils/hooks/infiniteLoading";
import { getFollowingBoards } from "../../../../store/generalBoardsSlice";
import {
  boardActionTypes,
  generalBoardActionTypes,
  useBoardCard,
} from "../../../../utils/hooks/boardCardOptions";
import { routerSelectors } from "../../../../store/routerActions";

export default function FollowingBoards() {
  const dispatch = useDispatch();

  const locationKey = useSelector(routerSelectors.getLocationKey);
  const boards = useSelector(followingSelectors.boards);

  const apiCall = useCallback((data) => getFollowingBoards(data), []);

  const {
    isFetching,
    resetPagination,
    cancelOngoingCall,
    bottomBoundaryNode,
  } = useInfiniteLoading({ apiCall });

  useEffect(() => {
    dispatch(resetFollowingBoards());
    resetPagination();

    return () => cancelOngoingCall();
  }, [dispatch, resetPagination, cancelOngoingCall, locationKey]);

  const { boardCardRenderer } = useBoardCard([
    ...generalBoardActionTypes,
    boardActionTypes.unfollowBoard,
  ]);

  return (
    <div style={{ padding: "1rem 0" }}>
      <SizedStackGrid>{boards.map(boardCardRenderer)}</SizedStackGrid>
      {bottomBoundaryNode}

      <div style={{ display: "grid", placeItems: "center" }}>
        {!boards.length && !isFetching
          ? "Please follow a board in order to see it here."
          : ""}
      </div>
    </div>
  );
}
