import React, { useEffect, useState } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { authenticationSelectors } from "../../store/authenticationSlice";

export default function Cookies() {
  const authToken = useSelector(authenticationSelectors.authTokens);

  const [cookiesAccepted, setCookiesAccepted] = useState(
    getCookieConsentValue() === "true" || !!authToken
  );

  useEffect(() => {
    !!authToken && setCookiesAccepted(true);
  }, [authToken]);

  useEffect(() => {
    if (cookiesAccepted) {
      ReactGA.initialize("G-FT83QY3VWJ");
    }
  }, [cookiesAccepted]);

  function handleCookieAccept() {
    setCookiesAccepted(true);
  }

  return (
    !authToken && (
      <CookieConsent
        location={"bottom"}
        onAccept={handleCookieAccept}
        expires={7}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    )
  );
}
