import React, { useState } from "react";
import defaultAvatar from "../../../assets/img/placeholder.jpg";
import { Editor } from "react-draft-wysiwyg";
import CommentAttachments from "../CommentAttachments/CommentAttachments";
import Button from "../../../components/CustomButtons/Button";
import Reply from "@material-ui/icons/Reply";
import Media from "../../../components/Media/Media";
import { makeStyles } from "@material-ui/core/styles";
import postCommentsStyles from "../PostComments/PostComments.style";
import PropTypes from "prop-types";
import { submitComment } from "../../../store/generalPostsSlice";
import { connect, useDispatch } from "react-redux";
import Card from "../../../components/Card/Card";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardHeader from "../../../components/Card/CardHeader";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import CardBody from "../../../components/Card/CardBody";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikInput from "../../../components/CustomInput/FormikInput";
import InputAdornmentIcon from "../../../components/InputAdornments/InputAdornmentIcon";
import Mail from "@material-ui/icons/Mail";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import javascriptStyles from "../../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";
import Loading from "../../../components/Loading/Loading";
import { useEditorState } from "utils/editor";
import { push } from "connected-react-router";
import Paths from "../../../staticData/Paths";
import { loginUser, registerUser } from "../../../store/authenticationSlice";
import { toastr } from "react-redux-toastr";
import LoginForm from "../../molecules/LoginForm/LoginForm";
import RegisterForm from "../../molecules/RegisterForm/RegisterForm";

const useStyles = makeStyles(postCommentsStyles);
const useDialogStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function CommentCreate({
  authTokens,
  postId,
  userData,
  submitComment,
  parentId,
  closeCommentCreate,
}) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const dialogClasses = useDialogStyles();
  const {
    tagOptions,
    handleEditorChange,
    editorState,
    clearEditor,
  } = useEditorState();

  const [attachments, setAttachments] = useState([]);
  const [isConfirmUserDialogOpen, setIsConfirmUserDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiCallOngoing, setApiCallOngoing] = useState(false);
  const [loginToPost, setLoginToPost] = useState(false);
  const [registerToPost, setRegisterToPost] = useState(false);

  const handleDialogClose = () => {
    setIsLoading(false);
    setLoginToPost(false);
    setRegisterToPost(false);
    setIsConfirmUserDialogOpen(false);
  };

  const cleanupCreateComment = () => {
    setAttachments([]);
    clearEditor();
    closeCommentCreate();
  };

  const handleSubmitComment = async () => {
    let success;
    setIsLoading(true);

    if (!authTokens) {
      setIsConfirmUserDialogOpen(true);
    } else {
      success = await submitComment({
        editorState,
        postId,
        parentId,
        attachments,
      });

      setIsLoading(false);
    }

    if (success === true) {
      cleanupCreateComment();
    }
  };

  const handleDialogSubmitComment = async (values) => {
    setApiCallOngoing(true);

    const success = await submitComment({
      email: values.commentEmail,
      editorState,
      postId,
      parentId,
      attachments,
    });

    if (success === true) {
      setIsLoading(false);

      handleDialogClose();
      clearEditor();
      setAttachments([]);
      closeCommentCreate();
    }

    setApiCallOngoing(false);
  };

  async function handleLoginToComment(data) {
    setApiCallOngoing(true);

    const loginSuccess = await dispatch(loginUser(data));

    const commentPostSuccess =
      loginSuccess &&
      (await submitComment({
        editorState,
        postId,
        parentId,
        attachments,
      }));

    if (commentPostSuccess) {
      handleDialogClose();
      cleanupCreateComment();

      dispatch(push(Paths.postDetails.replace(":id", postId)));
      toastr.success("Comment created successfully.");
    }

    setApiCallOngoing(false);
  }

  async function handleRegisterToComment(data) {
    setApiCallOngoing(true);

    const registerSuccess = await dispatch(registerUser(data));

    const commentPostSuccess =
      registerSuccess &&
      (await submitComment({
        editorState,
        postId,
        parentId,
        attachments,
      }));

    if (commentPostSuccess) {
      handleDialogClose();
      cleanupCreateComment();

      dispatch(push(Paths.postDetails.replace(":id", postId)));
      toastr.success("Comment created successfully.");
    }

    setApiCallOngoing(false);
  }

  function navigateToUserProfile(event) {
    event.preventDefault();

    userData?.userId &&
      dispatch(push(Paths.otherUserAccount.replace(":id", userData.userId)));
  }

  return (
    <>
      <Media
        avatar={userData.profilePicture || defaultAvatar}
        avatarOnClick={navigateToUserProfile}
        body={
          <div>
            <Loading isLoading={isLoading}>
              <Editor
                editorState={editorState}
                stripPastedStyles
                toolbarHidden
                onEditorStateChange={handleEditorChange}
                editorStyle={{
                  minHeight: "25vh",
                  border: `1px solid rgba(0,0,0,0.1)`,
                  padding: `5px`,
                }}
                toolbar={{}}
                mention={{
                  separator: " ",
                  trigger: "#",
                  suggestions: tagOptions,
                }}
                hashtag={{
                  separator: " ",
                  trigger: "#",
                }}
              />
              <CommentAttachments
                attachments={attachments}
                setAttachments={setAttachments}
                displayMode={"edit"}
              />
              <div className={classes.actionsContainer}>
                <Button color="primary" onClick={handleSubmitComment}>
                  <Reply /> Reply
                </Button>
              </div>
            </Loading>
          </div>
        }
      />

      <Dialog
        classes={{
          root: dialogClasses.modalRoot,
          paper: dialogClasses.modal + " " + dialogClasses.modalLogin,
        }}
        open={isConfirmUserDialogOpen}
        TransitionComponent={Transition}
        onClose={handleDialogClose}
        aria-labelledby="login-modal-slide-title"
        aria-describedby="login-modal-slide-description"
      >
        <Loading isLoading={apiCallOngoing}>
          <Card plain className={dialogClasses.modalLoginCard}>
            <DialogTitle
              id="login-modal-slide-title"
              disableTypography
              className={dialogClasses.modalHeader}
            >
              <CardHeader
                plain
                color="primary"
                className={
                  dialogClasses.textCenter + " " + dialogClasses.cardLoginHeader
                }
              >
                <Button
                  simple
                  className={dialogClasses.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={handleDialogClose}
                >
                  {" "}
                  <Close className={dialogClasses.modalClose} />
                </Button>
                <h3 className={dialogClasses.cardTitleWhite}>
                  {loginToPost
                    ? "Login"
                    : registerToPost
                    ? "Register"
                    : "Confirm User"}
                </h3>
              </CardHeader>
            </DialogTitle>
            <DialogContent
              id="login-modal-slide-description"
              className={dialogClasses.modalBody}
            >
              <CardBody className={dialogClasses.cardLoginBody}>
                <br />
                {!loginToPost && !registerToPost && (
                  <Formik
                    initialValues={{
                      commentEmail: "",
                    }}
                    validationSchema={Yup.object({
                      commentEmail: Yup.string()
                        .email("Please use a valid email format.")
                        .required("Email address is required."),
                    })}
                    onSubmit={handleDialogSubmitComment}
                  >
                    <Form>
                      <p>Post this comment using a email address:</p>
                      <FormikInput
                        id={"login-commentEmail-input"}
                        name={"commentEmail"}
                        type={"text"}
                        labelText="Email"
                        endAdornment={<InputAdornmentIcon icon={Mail} />}
                      />
                      <p>
                        <span
                          style={{
                            cursor: "pointer",
                            color: "blue",
                          }}
                          onClick={() => setLoginToPost(true)}
                        >
                          LOGIN
                        </span>{" "}
                        or{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            color: "blue",
                          }}
                          onClick={() => setRegisterToPost(true)}
                        >
                          REGISTER
                        </span>{" "}
                        in order to post with your account.
                      </p>
                      <div
                        className={dialogClasses.textCenter}
                        style={{ margin: "1rem" }}
                      >
                        <Button
                          round
                          color="primary"
                          onClick={handleDialogClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          style={{ marginLeft: "1rem" }}
                          round
                          color="primary"
                          type={"submit"}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </Formik>
                )}
                {loginToPost && (
                  <LoginForm
                    onSubmit={handleLoginToComment}
                    submitButton={() => (
                      <Button round type={"submit"} color="primary">
                        Login and post comment
                      </Button>
                    )}
                  />
                )}
                {registerToPost && (
                  <RegisterForm
                    onSubmit={handleRegisterToComment}
                    submitButton={() => (
                      <Button round type={"submit"} color="primary">
                        Register and post comment
                      </Button>
                    )}
                  />
                )}
              </CardBody>
            </DialogContent>
          </Card>
        </Loading>
      </Dialog>
    </>
  );
}

CommentCreate.propTypes = {
  authTokens: PropTypes.string,
  postId: PropTypes.number,
  parentId: PropTypes.number,
  userId: PropTypes.string,
  getTagSuggestions: PropTypes.func,
  submitComment: PropTypes.func,
  userData: PropTypes.object,
  closeCommentCreate: PropTypes.func,
  openConfirmUserDialog: PropTypes.func,
};

CommentCreate.defaultProps = {
  closeCommentCreate: () => {},
};

const mapStateToProps = (state) => ({
  authTokens: state.authentication.authTokens,
  userId: state.authentication.userData.userId,
  userData: state.authentication.userData,
});

const mapDispatchToProps = { submitComment };

export default connect(mapStateToProps, mapDispatchToProps)(CommentCreate);
