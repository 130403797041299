import React from "react";
import ReactPlayer from "react-player";
import "./VideoPlayer.scss";
import PropTypes from "prop-types";

function VideoPlayer({ url, autoPlay }) {
  return (
    <div className={"player-wrapper"}>
      <ReactPlayer
        url={url}
        className={"react-player"}
        width={"100%"}
        height={"100%"}
        config={{
          file: {
            attributes: {
              autoPlay: autoPlay,
              muted: true,
              loop: true,
              controls: true,
              disablePictureInPicture: true,
              controlsList: "nodownload noremoteplayback",
            },
          },
          vimeo: {
            playerOptions: {
              controls: true,
              muted: false,
            },
          },
          youtube: {
            playerVars: {
              controls: 1,
              modestbranding: 1,
            },
          },
        }}
      />
    </div>
  );
}

VideoPlayer.defaultProps = {
  autoPlay: false,
};

VideoPlayer.propTypes = {
  autoPlay: PropTypes.bool,
  url: PropTypes.string,
};

export default VideoPlayer;
