import React from "react";
import { Route } from "react-router";
import Paths from "../../../staticData/Paths";
import CreatePostButton from "../../molecules/CreatePostButton/CreatePostButton";
import CreatePostFromBoardButton from "../../molecules/CreatePostFromBoardButton/CreatePostFromBoardButton";
import CreateBoardButton from "../../molecules/CreateBoardButton/CreateBoardButton";
import CreateGroupButton from "../../molecules/CreateGroupButton/CreateGroupButton";
import AddToGroupButton from "../../molecules/AddToGroupButton/AddToGroupButton";
import LibraryCalendarButton from "../../molecules/LibraryCalendarButton/LibraryCalendarButton";
import ExploreCalendarButton from "../../molecules/ExploreCalendarButton/ExploreCalendarButton";

export default function ButtonsContainer() {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "60px",
        right: "20px",
        zIndex: "2",
      }}
    >
      <Route exact path={Paths.library} component={LibraryCalendarButton} />
      <Route exact path={Paths.explore} component={ExploreCalendarButton} />
      <Route exact path={Paths.root} component={ExploreCalendarButton} />
      <Route exact path={Paths.library} component={CreatePostButton} />
      <Route exact path={Paths.boards} component={CreateBoardButton} />
      <Route exact path={Paths.groupDetails} component={AddToGroupButton} />
      <Route
        exact
        path={Paths.boardDetails}
        component={CreatePostFromBoardButton}
      />
      <Route
        exact
        path={Paths.connections.groups}
        component={CreateGroupButton}
      />
    </div>
  );
}
