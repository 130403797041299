import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Description from "@material-ui/icons/Description";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import Person from "@material-ui/icons/Person";
import NavPills from "components/NavPills/NavPills.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import { useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import Paths from "../../../staticData/Paths";
import {
  getOtherUserProfile,
  clearOtherUserProfile,
} from "../../../store/otherUserSlice";
import OtherUserProfileIcon from "../../templates/otherUser/OtherUserProfileIcon/OtherUserProfileIcon";
import OtherUserPostsView from "../../newViews/posts/OtherUserPostsView/OtherUserPostsView";
import OtherUserBoardsView from "../../newViews/boards/OtherUserBoardsView/OtherUserBoardsView";
import OtherUserProfileView from "../../templates/otherUser/OtherUserProfileView/OtherUserProfileView";

const useStyles = makeStyles(profilePageStyle);

export default function OtherUserPage() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const userId = useRouteMatch(Paths.otherUserAccount).params?.id;

  useEffect(() => {
    dispatch(getOtherUserProfile({ userId }));

    return () => dispatch(clearOtherUserProfile());
  }, [dispatch, userId]);

  return (
    <div style={{ paddingTop: "250px" }}>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <OtherUserProfileIcon />

          <div className={classes.profileTabs}>
            <NavPills
              alignCenter
              color="primary"
              tabs={[
                {
                  tabButton: "Posts",
                  tabIcon: Description,
                  tabContent: <OtherUserPostsView />,
                },
                {
                  tabButton: "Boards",
                  tabIcon: DeveloperBoardIcon,
                  tabContent: <OtherUserBoardsView />,
                },
                {
                  tabButton: "Profile",
                  tabIcon: Person,
                  tabContent: <OtherUserProfileView />,
                },
              ]}
            />
          </div>
          <Clearfix />
        </div>
      </div>
    </div>
  );
}
