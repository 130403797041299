import { useEffect } from "react";
import { push } from "connected-react-router";
import Paths from "../../staticData/Paths";
import { useDispatch, useSelector } from "react-redux";
import { routerSelectors } from "../../store/routerActions";
import { useRouteMatch } from "react-router-dom";

export default function useGeneralTagRedirect() {
  const dispatch = useDispatch();

  const searchRoute = useRouteMatch(Paths.search);
  const isSearchRoute = searchRoute?.isExact;

  const tagParam = useSelector(routerSelectors.getQueryTagParams);

  useEffect(() => {
    window.redirectFromHashtag = (tag) => {
      /* eslint-disable */
      event.preventDefault();
      event.stopPropagation();
      /* eslint-enable */

      const strippedTag = tag.replace("#", "");
      const isIncluded = tagParam?.split(",").includes(strippedTag);

      if (isSearchRoute && isIncluded) {
        dispatch(push(Paths.searchByTag.replace(":tagParam", tagParam)));
      } else if (isSearchRoute && !isIncluded) {
        dispatch(
          push(
            Paths.searchByTag.replace(":tagParam", `${strippedTag},${tagParam}`)
          )
        );
      } else {
        dispatch(push(Paths.searchByTag.replace(":tagParam", strippedTag)));
      }
    };
  }, [dispatch, isSearchRoute, tagParam]);
}
