import React from "react";
import { useField } from "formik";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(javascriptStyles);

const FormikCustomEmailInput = ({
  label,
  labelPlacement,
  onChange,
  ...restProps
}) => {
  const classes = useStyles();

  const [field, meta] = useField({ ...restProps, type: "checkbox" });

  const checkboxOnChange = (e) => {
    const { onChange: formikOnChange } = field;
    formikOnChange(e);
    onChange && onChange(e);
  };

  return (
    <>
      <FormControlLabel
        classes={{
          label: classes.label,
        }}
        labelPlacement={labelPlacement}
        control={
          <Checkbox
            {...field}
            {...restProps}
            onChange={checkboxOnChange}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        label={<span>{label || ""}</span>}
      />
      {meta.touched && meta.error ? (
        <p
          style={{
            fontSize: "12px",
            color: "red",
            position: "relative",
            top: "-15px",
          }}
        >
          {meta.error}
        </p>
      ) : null}
    </>
  );
};

export default FormikCustomEmailInput;
