import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import StandardPage from "../../../components/StandardPage/StandardPage";
import { useDispatch, useSelector } from "react-redux";
import {
  connectionsSelectors,
  getGroupDetails,
  getGroupMembers,
  resetGroupDetails,
  resetGroupMembers,
} from "../../../store/connectionsSlice";
import {
  openRemoveUserFromGroupDialog,
  openLeaveGroupDialog,
} from "../../../store/dialogsSlice";
import SizedStackGrid from "../../../components/SizedStackGrid/SizedStackGrid";
import PersonCard from "../../organisms/PersonCard/PersonCard";
import { useInfiniteLoading } from "../../../utils/hooks/infiniteLoading";
import { authenticationSelectors } from "../../../store/authenticationSlice";

function MemberCard({
  user,
  isHimself,
  isAdmin,
  handleActions: _handleActions,
}) {
  const { id, userName } = user;

  const handleActions = _handleActions({
    userId: id,
    userName: userName,
  });

  const groupAdminOptionsList = ["Remove from Group"];
  const userCardOptions = ["Leave Group"];

  if (isAdmin && isHimself) {
    return <PersonCard person={user} />;
  }

  if (isAdmin && !isHimself) {
    return (
      <PersonCard
        person={user}
        options={{ handleActions, optionsList: groupAdminOptionsList }}
      />
    );
  }

  if (isHimself && !isAdmin) {
    return (
      <PersonCard
        person={user}
        options={{ handleActions, optionsList: userCardOptions }}
      />
    );
  }

  return <PersonCard person={user} />;
}

export default function GroupDetailsPage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { userId } = useSelector(authenticationSelectors.userData);
  const { groupName, adminId } = useSelector(connectionsSelectors.groupDetails);
  const members = useSelector(connectionsSelectors.groupMembers);

  useEffect(() => {
    dispatch(getGroupDetails({ id }));

    return () => {
      dispatch(resetGroupMembers());
      dispatch(resetGroupDetails());
    };
  }, [dispatch, id]);

  const userIsHimself = (id) => id === userId;
  const userIsGroupAdmin = adminId === userId;

  const handleActions = ({ userId, userName }) => ({ option }) => {
    switch (option) {
      case "Remove from Group": {
        dispatch(
          openRemoveUserFromGroupDialog({ groupId: id, userId, userName })
        );
        break;
      }
      case "Leave Group": {
        dispatch(openLeaveGroupDialog({ groupId: id, userId, groupName }));
        break;
      }
      default: {
        console.log(option);
      }
    }
  };

  const apiCall = useCallback((data) => getGroupMembers({ ...data, id }), [id]);

  const { bottomBoundaryNode } = useInfiniteLoading({ apiCall });

  return (
    <StandardPage title={groupName}>
      <div style={{ padding: "1rem 0" }}>
        <SizedStackGrid>
          {members?.map((member) => (
            <MemberCard
              key={member.id}
              user={member}
              isAdmin={userIsGroupAdmin}
              isHimself={userIsHimself(member.id)}
              handleActions={handleActions}
            />
          ))}
        </SizedStackGrid>
        {bottomBoundaryNode}
      </div>
    </StandardPage>
  );
}
