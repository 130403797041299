export default {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    padding: "1rem",
    justifyContent: "space-between",
  },
  deleteBtn: {
    marginTop: "10px",
  },
  generalExtensions: {
    width: "65px",
    height: "75px",
    cursor: "pointer",

    "&:hover": {
      position: "relative",
      top: "-1px",
    },
  },
  imgAttachment: {
    width: "auto",
    maxHeight: "75px",
    boxShadow: "2px 2px 5px #2A3B57",
    cursor: "pointer",

    "&:hover": {
      boxShadow: "3px 3px 6px #2A3B57",
      position: "relative",
      top: "-1px",
    },
  },
  label: {
    margin: "0.5rem 0 0",
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
};
