import React, { useState } from "react";
import Dialog from "../../../../components/Dialog/Dialog";
import Button from "../../../../components/CustomButtons/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  dialogsSelectors,
  closeLeaveGroupDialog,
} from "../../../../store/dialogsSlice";
import { leaveGroup } from "../../../../store/connectionsSlice";
import { goBack } from "connected-react-router";

function LeaveGroupDialog() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {
    isOpen,
    data: { groupId, userId, groupName },
  } = useSelector(dialogsSelectors.getLeaveGroupDialogState);

  const handleClose = () => dispatch(closeLeaveGroupDialog());

  async function handleDeleteGroup() {
    setIsLoading(true);
    const success = await dispatch(leaveGroup({ groupId, userId }));
    setIsLoading(false);

    if (success) {
      handleClose();
      dispatch(goBack());
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      isLoading={isLoading}
      title={"Leave Group"}
      actions={() => (
        <Button
          round
          color="danger"
          style={{ marginLeft: "1rem" }}
          onClick={handleDeleteGroup}
        >
          Leave
        </Button>
      )}
      onClose={handleClose}
    >
      <p>
        Are you sure you want to leave group <b>{groupName}</b>?
      </p>
      <hr />
    </Dialog>
  );
}

export default LeaveGroupDialog;
