import React, { useState } from "react";
import Button from "../../../../components/CustomButtons/Button";
import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "../../../../components/Dialog/Dialog";
import {
  openDeleteAccountDialog,
  closeDeleteAccountDialog,
  dialogsSelectors,
} from "../../../../store/dialogsSlice";
import { deleteAccount } from "../../../../store/authenticationSlice";

const useStyles = makeStyles(javascriptStyles);

export default function MyAccountOptionsView() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { isOpen } = useSelector(dialogsSelectors.getDeleteAccountDialogState);

  const [isLoading, setIsLoading] = useState(false);

  async function handleDeleteAccount() {
    setIsLoading(true);

    await dispatch(deleteAccount());

    setIsLoading(false);
  }

  const handleCloseDialog = () => dispatch(closeDeleteAccountDialog());

  const handleOpenDialog = () => dispatch(openDeleteAccountDialog());

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer justify="center">
            <div className={classes.textCenter}>
              <Button
                round
                onClick={handleOpenDialog}
                color="danger"
                disabled={isLoading}
              >
                Delete Account
              </Button>
            </div>
          </GridContainer>
        </GridItem>
      </GridContainer>

      <Dialog
        isOpen={isOpen}
        isLoading={isLoading}
        title={"Confirm Delete Account"}
        onClose={handleCloseDialog}
        actions={() => (
          <Button
            round
            color="danger"
            style={{ marginLeft: "1rem" }}
            onClick={handleDeleteAccount}
          >
            Delete Account
          </Button>
        )}
      >
        <p>This action is irreversible!</p>
        <hr />
      </Dialog>
    </div>
  );
}
