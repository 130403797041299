import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../components/CustomButtons/Button";
import Dialog from "../../../../components/Dialog/Dialog";
import {
  closeShareArticleDialog,
  dialogsSelectors,
} from "../../../../store/dialogsSlice";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../../../components/CustomInput/FormikInput";
import InputAdornmentIcon from "../../../../components/InputAdornments/InputAdornmentIcon";
import FormikUsersMultiselect from "../../../../components/CustomInput/FormikUsersMultiselect";
import {
  activateGuestCodeForPost,
  deactivateGuestCodeForPost,
  getSharedForPost,
  getShareSuggestionsForPosts,
  sharePost,
} from "../../../../store/generalPostsSlice";
import SharedToUsers from "../../../organisms/SharedToUsers/SharedToUsers";
import SharedToGroups from "../../../organisms/SharedToGroups/SharedToGroups";
import FormikCustomCheckboxInput from "../../../../components/CustomInput/FormikCustomCheckboxInput";
import { authenticationSelectors } from "../../../../store/authenticationSlice";

export default function SharePostDialog() {
  const dispatch = useDispatch();

  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const { isOpen } = useSelector(dialogsSelectors.getSharePostDialogState);
  const { userId } = useSelector(authenticationSelectors.userData);
  const { id: postId, user } = useSelector(dialogsSelectors.getContextItem);

  const currentUserIsOwner = () => {
    const postOwnerId = user?.id;

    return userId && userId === postOwnerId;
  };

  const handleClose = () => dispatch(closeShareArticleDialog());

  const [sharedWithUsers, setSharedWithUsers] = useState([]);
  const [sharedWithGroups, setSharedWithGroups] = useState([]);
  const [guestCode, setGuestCode] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setSharedWithUsers([]);
      setSharedWithGroups([]);
      setGuestCode("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (postId && isOpen) {
      (async () => {
        setIsLoading(true);
        const data = await dispatch(getSharedForPost({ postId }));
        setIsLoading(false);

        if (data) {
          const { users, groups, publicToken } = data;

          setSharedWithUsers(users);
          setSharedWithGroups(groups);
          setGuestCode(publicToken || "");
        }
      })();
    }
  }, [postId, isOpen, dispatch]);

  const handleSharePost = async (values) => {
    const { usersAndGroups, message } = values;

    setIsLoading(true);
    const success = await dispatch(
      sharePost({ entityId: postId, message, usersAndGroups })
    );
    setIsLoading(false);

    if (success) {
      handleClose();
    }
  };

  const submitSharePost = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const [usersOptions, setUsersOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = async (event, value, reason) => {
    if (reason === "input" && value?.trim().length) {
      const key = value.trim().replace(" ", "&nbsp;");

      setLoading(true);
      const data = await dispatch(getShareSuggestionsForPosts({ postId, key }));
      setLoading(false);

      if (data) {
        const convertedData = data.map((x) => ({
          ...x,
          userName: x.name,
          profilePicture: x.profilePictureUrl,
        }));

        setUsersOptions(convertedData);
      }
    }
  };

  const handleGuestCodeToggle = (event) => {
    const isChecked = event.target.checked;

    (async () => {
      let success;

      setIsLoading(true);
      if (isChecked) {
        success = await dispatch(activateGuestCodeForPost({ postId }));
      } else {
        success = await dispatch(deactivateGuestCodeForPost({ postId }));
      }
      setIsLoading(false);

      if (success !== undefined) {
        setGuestCode(success);
      }
    })();
  };

  return (
    <Dialog
      isOpen={isOpen}
      isLoading={isLoading}
      title={"Share Post"}
      onClose={handleClose}
      actions={() => (
        <Button
          round
          color="primary"
          style={{ marginLeft: "1rem" }}
          onClick={submitSharePost}
        >
          Share
        </Button>
      )}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          usersAndGroups: [],
          message: "",
          shareTokenToggle: !!guestCode,
          tokenValue: guestCode,
        }}
        validationSchema={Yup.object({
          usersAndGroups: Yup.array()
            .of(Yup.object())
            .min(1, "Please select at least one user or group."),
          message: Yup.string(),
        })}
        onSubmit={handleSharePost}
      >
        <Form>
          <FormikUsersMultiselect
            id={"share-users-multiselect"}
            name={"usersAndGroups"}
            placeholder={"Users and Groups"}
            handleInputChange={handleInputChange}
            options={usersOptions}
            isLoading={loading}
          />
          <FormikInput
            id={"share-message-input"}
            name={"message"}
            type={"text"}
            placeholder="Message"
            multiline
            rowsMax={10}
            endAdornment={<InputAdornmentIcon icon={"message"} />}
          />
          {currentUserIsOwner() && (
            <p>
              <span>
                <FormikCustomCheckboxInput
                  id={"share-tokenToggle-checkbox"}
                  name={"shareTokenToggle"}
                  label={!guestCode ? "Guest Code" : "Guest Code:"}
                  onChange={handleGuestCodeToggle}
                />
              </span>
              <span>
                <b>{guestCode ?? ""}</b>
              </span>
            </p>
          )}

          <SharedToUsers
            users={sharedWithUsers}
            setUsers={setSharedWithUsers}
            onUserClick={handleClose}
            entityId={postId}
            entityType={"post"}
          />

          <SharedToGroups
            groups={sharedWithGroups}
            setGroups={setSharedWithGroups}
            onGroupClick={handleClose}
            entityId={postId}
            entityType={"post"}
          />
        </Form>
      </Formik>
    </Dialog>
  );
}
