import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import libraryPageStyle from "../../../Library/LibraryPageStyle";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import { postValidationSchema } from "../../../../utils/PostValidationSchema";
import { Form, Formik } from "formik";
import FormikSelect from "../../../../components/CustomInput/FormikSelect";
import { useDispatch, useSelector } from "react-redux";
import PostAttachments from "../../../organisms/PostAttachments/PostAttachments";
import {
  createPost,
  cancelCreatePost,
  saveAndNotifyPost,
  getTagSuggestions,
  getBoardSuggestions,
} from "../../../../store/generalPostsSlice";
import Loading from "../../../../components/Loading/Loading";
import { toastr } from "react-redux-toastr";
import { validateEditorTextLength } from "../../../../utils/editor";
import { postTypes, postPrivacyTypes } from "staticData/Posts";
import { postIsQuestion, postIsEvent } from "utils/posts";
import FormikInput from "../../../../components/CustomInput/FormikInput";
import FormikDateTime from "../../../../components/CustomInput/FormikDateTime";
import FormikLocationSearch from "../../../../components/CustomInput/FormikGoogleLocationSearch";
import {
  closeCreateArticleDialog,
  dialogsSelectors,
} from "../../../../store/dialogsSlice";
import { getBoardDetails } from "../../../../store/generalBoardsSlice";
import { replace } from "connected-react-router";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(libraryPageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function CreatePostDialog() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const location = useLocation();
  const [boardData, setBoardData] = useState({});
  const [saveWithNotify, setSaveWithNotify] = useState(false);

  const {
    isOpen,
    data: { postType, boardId },
  } = useSelector(dialogsSelectors.getCreatePostDialogState);

  const handleGetBoardDetails = useCallback(() => {
    setIsLoading(true);

    dispatch(getBoardDetails({ id: boardId })).then((data) => {
      setIsLoading(false);

      setBoardData(data);
    });
  }, [dispatch, boardId]);

  useEffect(() => {
    if (boardId) {
      handleGetBoardDetails();
    }
  }, [handleGetBoardDetails, boardId]);

  const [tags, setTags] = useState([]);
  const [boards, setBoards] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isPostPrivate, setIsPostPrivate] = useState();
  const filesWithConversionInProgress = useMemo(() => {
    return attachments
      ?.filter((file) => file?.conversionInProgress)
      .map((file) => file.url);
  }, [attachments]);

    const clearEditor = () => setEditorState(EditorState.createEmpty());

    console.log({ isUploading });
  const hasProcessingUploads = filesWithConversionInProgress?.length > 0 || isUploading;

  useEffect(() => {
    return () => {
      if (!isOpen) setAttachments([]);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);

      dispatch(getTagSuggestions()).then((data) => {
        setIsLoading(false);

        setTags(data);
      });
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);

      dispatch(getBoardSuggestions({ isPostPrivate })).then((result) => {
        setIsLoading(false);

        setBoards(constructBoardOptions(result.data));
      });
    }
  }, [dispatch, isPostPrivate, isOpen]);

  const tagOptions = tags.map((tag) => ({
    text: tag,
    value: tag.slice(1),
    url: "",
  }));

  const constructBoardOptions = (data) => {
    const myBoards = data.filter((elem) => elem.isMine);
    const sharedBoards = data.filter((elem) => !elem.isMine);

    let response = [];

    if (myBoards.length) {
      response = [
        { header: true, name: "My Boards", id: "myBoards" },
        ...myBoards,
      ];
    }

    if (sharedBoards.length) {
      response = [
        ...response,
        { header: true, name: "Shared", id: "shared" },
        ...sharedBoards,
      ];
    }

    return response;
  };

  const handleCancel = () => dispatch(cancelCreatePost());

  const getStateWithMaxLengthEnsured = (newState, maxLength) => {
    const contentState = newState.getCurrentContent();
    const oldContent = editorState.getCurrentContent();
    if (
      contentState === oldContent ||
      contentState.getPlainText().length <= maxLength
    ) {
      return newState;
    } else {
      return EditorState.undo(
        EditorState.push(
          editorState,
          ContentState.createFromText(oldContent.getPlainText()),
          "delete-character"
        )
      );
    }
  };

  const onEditorStateChange = (postType) => (editorState) => {
    if (postIsQuestion(postType)) {
      setEditorState(getStateWithMaxLengthEnsured(editorState, 300));
    } else {
      setEditorState(editorState);
    }
  };

  const handleCreatePost = async (formValues) => {
    const editorHasContent = validateEditorTextLength({
      state: editorState,
      minLength: 1,
    });

    if (!editorHasContent) {
      toastr.error("", "Post content can not be empty.");
      return;
    }

    let success;
    setIsLoading(true);

    if (saveWithNotify) {
      success = await dispatch(
        saveAndNotifyPost({ formValues, attachments, editorState })
      );
    } else {
      success = await dispatch(
        createPost({ formValues, attachments, editorState })
      );
    }

    setSaveWithNotify(false);
    setIsLoading(false);

    if (success) {
      clearEditor();
      dispatch(closeCreateArticleDialog());
      dispatch(replace(`${location.pathname}${location.search}`));
    }
  };

  const handleFormikChange = (values) => {
    if (values.isPrivate !== isPostPrivate) {
      setIsPostPrivate(values.isPrivate);
    }
  };

  return (
    <Dialog
      classes={{ root: classes.modalRoot, paper: classes.modal }}
      open={isOpen}
      fullWidth
      TransitionComponent={Transition}
      onClose={() => {}}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <Loading isLoading={isLoading}>
        <div className={classes.container}>
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={handleCancel}
              disabled={hasProcessingUploads}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h3
              className={classes.modalTitle}
              style={{
                fontWeight: "bold",
              }}
            >
              Create Post
            </h3>
            <hr />
          </DialogTitle>

          <Formik
            enableReinitialize
            initialValues={{
              isPrivate: boardData.private === false ? false : false,
              type: postType || postTypes[0].id,
              boards: boardId || "",
              eventTitle: "",
              location: {
                address: "",
                latitude: null,
                longitude: null,
              },
              startTime: "",
              endTime: "",
              phoneNumber: "",
              webinarLink: "",
            }}
            validationSchema={postValidationSchema}
            onSubmit={handleCreatePost}
            validate={handleFormikChange}
          >
            {(props) => (
              <Form>
                <DialogContent style={{ overflowX: "auto" }}>
                  <GridContainer justify="center">
                    <GridItem sm={12} md={6}>
                      <FormikSelect
                        id={"createPost-isPrivate-select"}
                        name={"isPrivate"}
                        placeholder={"Visibility"}
                        disabled={boardData.private === false}
                        options={postPrivacyTypes}
                      />
                    </GridItem>
                    <GridItem sm={12} md={6}>
                      <FormikSelect
                        id={"createPost-type-select"}
                        name={"type"}
                        placeholder={"Type"}
                        options={postTypes}
                        disabled
                      />
                    </GridItem>
                    <GridItem>
                      <FormikSelect
                        id={"createPost-boards-select"}
                        name={"boards"}
                        disabled={!!boardId}
                        placeholder={"Save to Board"}
                        options={boards}
                      />
                    </GridItem>
                    {postIsEvent(props.values.type) && (
                      <>
                        <GridItem>
                          <FormikInput
                            id={"createPost-title-input"}
                            name={"eventTitle"}
                            type={"text"}
                            labelText="Event Title"
                            maxLength={100}
                            autoComplete={"off"}
                          />
                        </GridItem>
                        <GridItem sm={12} md={6}>
                          <FormikDateTime
                            id={"createPost-startTime-picker"}
                            name={"startTime"}
                            placeholder={"Start Time"}
                          />
                        </GridItem>
                        <GridItem sm={12} md={6}>
                          <FormikDateTime
                            id={"createPost-endTime-picker"}
                            name={"endTime"}
                            placeholder={"End Time"}
                          />
                        </GridItem>
                        <GridItem sm={12} md={6}>
                          <FormikInput
                            id={"createPost-phoneNumber-picker"}
                            name={"phoneNumber"}
                            type={"text"}
                            labelText="Phone Number"
                            maxLength={100}
                            autoComplete={"off"}
                          />
                        </GridItem>
                        <GridItem sm={12} md={6}>
                          <FormikInput
                            id={"createPost-webinarLink-input"}
                            name={"webinarLink"}
                            type={"text"}
                            labelText="Webinar Link"
                            maxLength={100}
                            autoComplete={"off"}
                          />
                        </GridItem>
                        <GridItem>
                          <FormikLocationSearch
                            id={"createPost-location-input"}
                            name={"location"}
                          />
                        </GridItem>
                      </>
                    )}
                  </GridContainer>
                  <Editor
                    editorState={editorState}
                    stripPastedStyles
                    toolbarStyle={{ backgroundColor: "#eeeeee" }}
                    onEditorStateChange={onEditorStateChange(props.values.type)}
                    wrapperClassName={classes.wrapperClassName}
                    editorClassName={classes.editorClassName}
                    editorStyle={
                      postIsQuestion(props.values.type)
                        ? { minHeight: "15vh" }
                        : {}
                    }
                    toolbar={{
                      options: ["inline", "list"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered", "ordered"],
                      },
                    }}
                    mention={{
                      separator: " ",
                      trigger: "#",
                      suggestions: tagOptions,
                    }}
                    hashtag={{
                      separator: " ",
                      trigger: "#",
                    }}
                  />
                  <br />
                  <PostAttachments
                    attachments={attachments}
                    setAttachments={setAttachments}
                    setUploading={setIsUploading}
                    displayMode={"edit"}
                  />
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                  <GridContainer justify={"center"}>
                    <GridItem xs={12}>
                      <div>
                        <Button
                          round
                          onClick={handleCancel}
                          color="primary"
                          style={{
                            margin: "1rem 0 0 1rem",
                          }}
                          disabled={hasProcessingUploads}
                        >
                          Cancel
                        </Button>
                        <Button
                          round
                          type={"submit"}
                          color="success"
                          style={{
                            margin: "1rem 0 0 1rem",
                          }}
                          disabled={hasProcessingUploads}
                        >
                          Submit
                        </Button>
                        <Button
                          round
                          type={"submit"}
                          onClick={() => setSaveWithNotify(true)}
                          color="success"
                          style={{
                            margin: "1rem 0 0 1rem",
                          }}
                          disabled={hasProcessingUploads}
                        >
                          Submit Notify
                        </Button>
                      </div>
                    </GridItem>
                  </GridContainer>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </div>
      </Loading>
    </Dialog>
  );
}
