import Button from "../../../../components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import Card from "../../../../components/Card/Card";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardHeader from "../../../../components/Card/CardHeader";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import CardBody from "../../../../components/Card/CardBody";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import Slide from "@material-ui/core/Slide";
import { replace } from "connected-react-router";
import { connect } from "react-redux";
import { removePost, deletePost } from "../../../../store/generalPostsSlice";
import { closeDeleteArticleDialog } from "../../../../store/dialogsSlice";
import Paths from "../../../../staticData/Paths";

const useStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

function DeletePostDialog({
  contextItem,
  dialogState,
  closeDeleteArticleDialog,
  removePost,
  deletePost,
  replace,
}) {
  const {
    isOpen,
    data: { fromDetails },
  } = dialogState;

  const handleClose = () => {
    closeDeleteArticleDialog();
  };

  const handleSubmit = async () => {
    const success = await deletePost({ id: contextItem.id });

    if (success) {
      closeDeleteArticleDialog();
      removePost(contextItem.id);

      if (fromDetails) {
        replace(Paths.library);
      }
    }
  };

  const classes = useStyles();

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal + " " + classes.modalLogin,
      }}
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="login-modal-slide-title"
      aria-describedby="login-modal-slide-description"
    >
      <Card plain className={classes.modalLoginCard}>
        <DialogTitle
          id="login-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <CardHeader
            plain
            color="primary"
            className={classes.textCenter + " " + classes.cardLoginHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={handleClose}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h3 className={classes.cardTitleWhite}>Confirm Delete</h3>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="login-modal-slide-description"
          className={classes.modalBody}
        >
          <CardBody className={classes.cardLoginBody}>
            <br />
            <span>Are you sure you want to delete this post?</span>
            <br />
            <div className={classes.textCenter} style={{ margin: "1rem" }}>
              <Button
                round
                color="primary"
                onClick={() => closeDeleteArticleDialog()}
              >
                Cancel
              </Button>
              <Button
                style={{ marginLeft: "1rem" }}
                round
                color="danger"
                onClick={handleSubmit}
              >
                Delete
              </Button>
            </div>
          </CardBody>
        </DialogContent>
      </Card>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  contextItem: state.dialogs.contextItem,
  dialogState: state.dialogs.deleteArticleDialog,
});

const mapDispatchToProps = {
  removePost,
  deletePost,
  closeDeleteArticleDialog,
  replace,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletePostDialog);
