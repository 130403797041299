import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import axios from "axios";
import Endpoints from "../staticData/Endpoints";
import { displayValidationMessage } from "../utils/validationDisplayHelper";
import { toastr } from "react-redux-toastr";

const followingSlice = createSlice({
  name: "following",
  initialState: {
    users: [],
    boards: [],
    tags: [],
    isFollowingTag: false,
  },
  reducers: {
    addFollowingUsers(state, action) {
      action.payload.forEach((post) => {
        state.users.push(post);
      });
    },
    removeFollowingUser(state, action) {
      state.users = state.users.filter((post) => post.id !== action.payload);
    },
    resetFollowingUsers(state) {
      state.users = [];
    },
    addFollowingBoards(state, action) {
      action.payload.forEach((board) => {
        state.boards.push(board);
      });
    },
    removeFollowingBoard(state, action) {
      state.boards = state.boards.filter(
        (board) => board.id !== action.payload
      );
    },
    resetFollowingBoards(state) {
      state.boards = [];
    },
    addFollowingTags(state, action) {
      action.payload.forEach((tag) => {
        state.tags.push(tag);
      });
    },
    removeFollowingTag(state, action) {
      state.tags = state.tags.filter((tag) => tag !== action.payload);
    },
    resetFollowingTags(state) {
      state.tags = [];
    },
    setIsFollowing(state, action) {
      state.isFollowingTag = action.payload;
    },
  },
});

export const {
  addFollowingUsers,
  addFollowingBoards,
  addFollowingTags,
  removeFollowingUser,
  removeFollowingBoard,
  removeFollowingTag,
  resetFollowingUsers,
  resetFollowingBoards,
  resetFollowingTags,
  setIsFollowing,
} = followingSlice.actions;

export default followingSlice.reducer;

export const followingSelectors = {
  users: createSelector(
    (state) => state.following.users,
    (users) => users
  ),
  boards: createSelector(
    (state) => state.following.boards,
    (boards) => boards
  ),
  tags: createSelector(
    (state) => state.following.tags,
    (tags) => tags
  ),
  isFollowingTag: createSelector(
    (state) => state.following.isFollowingTag,
    (tags) => tags
  ),
};

export const getFollowingUsers = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { lastUpdatedOn: lastLoadedItem, cancelToken } = data;

  return axios({
    method: "GET",
    cancelToken: cancelToken.token,
    url: Endpoints.getFollowingUsers,
    params: { orderNumber: lastLoadedItem || 0 },
    headers: {
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then(({ data }) => {
      dispatch(addFollowingUsers(data));

      return {
        areMore: data.length === 10,
        lastUpdatedOn: data[data.length - 1]?.orderNumber,
      };
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getFollowingTags = () => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;

  return axios({
    method: "GET",
    url: Endpoints.getFollowingTags,
    headers: {
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { data, status } = result;

      if (status === 200) {
        dispatch(addFollowingTags(data));
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const getTagFollowStatus = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { tag } = data;

  return axios({
    method: "GET",
    url: Endpoints.getTagFollowingStatus,
    params: { tag },
    headers: {
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((response) => {
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return false;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const removePostFromFavorites = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { id } = data;

  return axios({
    method: "DELETE",
    url: Endpoints.removePostFromFavorites,
    params: { id },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      if (result.status === 200) {
        // dispatch(removeFavouritePost(id));
        toastr.success("", "Post successfully removed from Favorites.");

        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const unfollowUser = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { userId } = data;

  return axios({
    method: "DELETE",
    url: Endpoints.unfollowUser,
    params: { userId },
    headers: { authorization: `Bearer ${authTokens}` },
  })
    .then((result) => {
      if (result.status === 200) {
        toastr.success("", "User was successfully unfollowed.");
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const followUser = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { userId } = data;

  return axios({
    method: "POST",
    url: Endpoints.followUser,
    data: JSON.stringify({ userId }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status } = result;

      if (status === 200) {
        toastr.success("", "User was successfully followed.");
        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const addToPremium = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { userId } = data;

  return axios({
    method: "GET",
    url: Endpoints.addToPremium,
    params: { userId },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status } = result;

      if (status === 200) {
        toastr.success("", "User was successfully upgraded to Premium.");

        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const removeFromPremium = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { userId } = data;

  return axios({
    method: "DELETE",
    url: Endpoints.removeFromPremium,
    params: { userId },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status } = result;

      if (status === 200) {
        toastr.success("", "User was successfully downgraded from Premium.");

        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const addToAdmin = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { userId } = data;

  return axios({
    method: "GET",
    url: Endpoints.addToAdmin,
    params: { userId },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status } = result;

      if (status === 200) {
        toastr.success("", "User was successfully upgraded to Admin.");

        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const removeFromAdmin = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { userId } = data;

  return axios({
    method: "DELETE",
    url: Endpoints.removeFromAdmin,
    params: { userId },
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status } = result;

      if (status === 200) {
        toastr.success("", "User was successfully downgraded from Admin.");

        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const followTag = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { tagName } = data;

  return axios({
    method: "POST",
    url: Endpoints.followTag,
    data: JSON.stringify({ tagName }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authTokens}`,
    },
  })
    .then((result) => {
      const { status } = result;

      if (status === 200) {
        toastr.success("", "Tag was successfully followed.");
        return true;
      }
    })
    .catch((error) => displayValidationMessage(error, dispatch));
};

export const unfollowTag = (data) => (dispatch, getState) => {
  const state = getState();
  const { authTokens } = state.authentication;
  const { tagName } = data;

  return axios({
    method: "DELETE",
    url: Endpoints.unfollowTag,
    params: { tagName },
    headers: { authorization: `Bearer ${authTokens}` },
  })
    .then((result) => {
      if (result.status === 200) {
        toastr.success("", "Tag was successfully unfollowed.");
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
