import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "../../../components/CustomButtons/Button";
import LockOutlined from "@material-ui/icons/LockOutlined";

export default function PrivateBoardIcon(props) {
  return (
    <>
      {props.boardData.private && (
        <Tooltip
          id="tooltip-top"
          title={"This Board is private"}
          placement="top"
          classes={{ tooltip: props.classes.tooltip }}
        >
          <div>
            <Button
              round
              justIcon
              disabled
              color={"primary"}
              className={props.classes.followButton}
            >
              <LockOutlined className={props.classes.followIcon} />
            </Button>
          </div>
        </Tooltip>
      )}
    </>
  );
}
