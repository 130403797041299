import React from "react";
import StandardPage from "../../../components/StandardPage/StandardPage";
import FavoritesPostsView from "../../newViews/posts/FavoritesPostsView/FavoritesPostsView";

export default function FavoritesPage() {
  return (
    <StandardPage title={"My Favorites"}>
      <FavoritesPostsView />
    </StandardPage>
  );
}
