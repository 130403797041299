import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import postCommentsStyles from "./PostComments.style";
import { useSelector } from "react-redux";
import { generalPostsSelectors } from "../../../store/generalPostsSlice";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import CommentComponent from "../CommentDetails/CommentDetails";
import CommentCreate from "../CommentCreate/CommentCreate";

const useStyles = makeStyles(postCommentsStyles);

export default function PostComments() {
  const classes = useStyles();
  const { id: postId, comments = [] } = useSelector(generalPostsSelectors.postDetails);

  const renderCommentsTitle = () => {
    if (comments?.length) {
      return (
        <h3 className={classes.title + " " + classes.textCenter}>
          {`${comments.length} ${
            comments.length === 1 ? "Comment" : "Comments"
          }`}
        </h3>
      );
    }

    return (
      <h5 className={classes.textCenter}>
        This post does not have any comments.
      </h5>
    );
  };

  const renderComments = () => {
    return comments.map((comment, index) => {
      return (
        <div key={`${comment.id}`}>
          {index > 0 && <hr />}
          <CommentComponent comment={comment} postId={postId} />
        </div>
      );
    });
  };

  return (
    <div>
      <div className={classes.title}>
        <h4>Leave a comment</h4>
      </div>
      <CommentCreate postId={postId} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} className={classes.mlAuto}>
          {renderCommentsTitle()}
        </GridItem>
        <GridItem xs={12} sm={12} md={12} className={classes.mlAuto}>
          <div>{renderComments()}</div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
