import React, { useCallback, useEffect } from "react";
import SizedStackGrid from "../../../../components/SizedStackGrid/SizedStackGrid";
import { makeStyles } from "@material-ui/core/styles";
import libraryPageStyle from "../../../ArticlesView/LibraryPageStyle";
import { useDispatch, useSelector } from "react-redux";
import { useInfiniteLoading } from "../../../../utils/hooks/infiniteLoading";
import {
  generalBoardActionTypes,
  useBoardCard,
} from "../../../../utils/hooks/boardCardOptions";
import { useRouteMatch } from "react-router-dom";
import Paths from "../../../../staticData/Paths";
import { getOtherUserBoards } from "../../../../store/generalBoardsSlice";
import { routerSelectors } from "../../../../store/routerActions";
import {
  clearOtherUserBoards,
  otherUserSelectors,
} from "../../../../store/otherUserSlice";

const useStyles = makeStyles(libraryPageStyle);

export default function OtherUserBoardsView() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const locationKey = useSelector(routerSelectors.getLocationKey);
  const boards = useSelector(otherUserSelectors.boards);

  const userProfileId = useRouteMatch(Paths.otherUserAccount)?.params.id;

  const apiCall = useCallback(
    (data) => getOtherUserBoards({ ...data, userProfileId }),
    [userProfileId]
  );

  const {
    isFetching,
    resetPagination,
    cancelOngoingCall,
    bottomBoundaryNode,
  } = useInfiniteLoading({ apiCall });

  useEffect(() => {
    return () => {
      resetPagination();
      cancelOngoingCall();
      dispatch(clearOtherUserBoards());
    };
  }, [dispatch, resetPagination, cancelOngoingCall, locationKey]);

  const { boardCardRenderer } = useBoardCard(generalBoardActionTypes);

  return (
    <div className={classes.container} style={{ paddingTop: "30px" }}>
      <SizedStackGrid>{boards.map(boardCardRenderer)}</SizedStackGrid>
      {bottomBoundaryNode}

      <div style={{ height: "6rem", textAlign: "center", padding: "2rem" }}>
        {!boards.length && !isFetching
          ? "User doesn't have any boards created."
          : ""}
      </div>
    </div>
  );
}
