import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Add from "@material-ui/icons/Add";
import { openAddUsersToGroupDialog } from "../../../store/dialogsSlice";
import { connectionsSelectors } from "../../../store/connectionsSlice";
import { authenticationSelectors } from "../../../store/authenticationSlice";
import Button from "../../../components/CustomButtons/Button";
import Tooltip from "@material-ui/core/Tooltip";

export default function AddToGroupButton() {
  const dispatch = useDispatch();

  const { id, adminId } = useSelector(connectionsSelectors.groupDetails);
  const { userId } = useSelector(authenticationSelectors.userData);

  const userIsGroupAdmin = adminId === userId;

  const handleOnClick = () => dispatch(openAddUsersToGroupDialog({ id }));

  if (userIsGroupAdmin) {
    return (
      <div>
        <Tooltip title={"Add users to group"} placement={"left"}>
          <Button
            round
            justIcon
            size={"lg"}
            color={"primary"}
            onClick={handleOnClick}
          >
            <Add />
          </Button>
        </Tooltip>
      </div>
    );
  } else {
    return null;
  }
}
