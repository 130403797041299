import { createSelector, createSlice } from "@reduxjs/toolkit";

const boardsSlice = createSlice({
  name: "boards",
  initialState: {
    boards: [],
  },
  reducers: {
    setBoards(state, action) {
      action.payload.forEach((post) => {
        state.boards.push(post);
      });
    },
    clearBoards(state) {
      state.boards = [];
    },
  },
});

export const { setBoards, clearBoards } = boardsSlice.actions;

export default boardsSlice.reducer;

export const boardsSelectors = {
  boards: createSelector(
    (state) => state.boards.boards,
    (boards) => boards
  ),
};
