import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authenticationSelectors } from "store/authenticationSlice";
import { toastr } from "react-redux-toastr";
import Paths from "staticData/Paths";
import { push } from "connected-react-router";
import {
  setContextItem,
  openDeleteArticleDialog,
  openShareArticleDialog,
  openSaveArticleDialog,
  openEditArticleDialog,
  openEndChallengeDialog,
  openRemoveArticleFromBoardDialog,
  openRemoveFavoritePostDialog,
} from "store/dialogsSlice";
import { copyPost, addPostToFavorites } from "store/generalPostsSlice";
import { adminRemoveContent } from "store/generalActions";
import { removePost } from "store/generalPostsSlice";
import PostCard from "../../views/organisms/PostsCard/PostCard";
import * as React from "react";

export const postActionTypes = {
  savePost: "Save",
  sharePost: "Share",
  copyPost: "Copy",
  addToFavourites: "Add to Favorites",
  editPost: "Edit",
  deletePost: "Delete",
  endChallenge: "End Challenge",
  removeFromBoard: "Remove from Board",
  removeFromFavorites: "Remove from Favorites",
  removeContent: "Remove Content",
};

export const generalPostActionTypes = [
  postActionTypes.savePost,
  postActionTypes.sharePost,
  postActionTypes.copyPost,
  postActionTypes.addToFavourites,
  postActionTypes.editPost,
  postActionTypes.deletePost,
];

export function usePostCard(opts = [], fromDetails = false, boardOwnerId = "") {
  const dispatch = useDispatch();

  const authTokens = useSelector(authenticationSelectors.authTokens);
  const { userId } = useSelector(authenticationSelectors.userData);
  const userRole = useSelector(authenticationSelectors.userRole);

  const userIsAdmin = userRole === "Admin";
  const userIsSuperAdmin = userRole === "SuperAdmin";

  const getOptionsList = useCallback(
    (post) => {
      let options = [];

      if (opts) {
        options = options.concat(opts);
      }

      const currentUserIsOwner = post?.user?.id === userId;
      const currentUserIsBoardOwner = boardOwnerId === userId;

      if (currentUserIsOwner && post.type === "challenge" && post.isActive) {
        options.push(postActionTypes.endChallenge);
      }

      if (!currentUserIsOwner) {
        options = options.filter(
          (x) =>
            ![
              postActionTypes.deletePost,
              postActionTypes.editPost,
              postActionTypes.copyPost,
            ].includes(x)
        );
      }

      if (!currentUserIsOwner && !currentUserIsBoardOwner) {
        options = options.filter(
          (x) => ![postActionTypes.removeFromBoard].includes(x)
        );
      }

      if (userIsAdmin || userIsSuperAdmin) {
        options.push(postActionTypes.removeContent);
      }

      return options;
    },
    [userId, userIsAdmin, userIsSuperAdmin, opts, boardOwnerId]
  );

  const handleAdminRemoveContent = async (id) => {
    const success = await dispatch(
      adminRemoveContent({ contentType: "post", id })
    );

    if (success) {
      dispatch(removePost(id));
      toastr.success("", "Post was successfully removed.");
    }
  };

  const handleActions = ({ post }) => ({ option }) => {
    const { id } = post;

    if (!authTokens) {
      dispatch(push(Paths.login));
      return;
    }

    dispatch(setContextItem(post));

    switch (option) {
      case postActionTypes.deletePost: {
        dispatch(
          openDeleteArticleDialog({ ...(fromDetails && { fromDetails }) })
        );
        break;
      }
      case postActionTypes.sharePost: {
        dispatch(openShareArticleDialog());
        break;
      }
      case postActionTypes.editPost: {
        dispatch(openEditArticleDialog());
        break;
      }
      case postActionTypes.savePost: {
        dispatch(openSaveArticleDialog());
        break;
      }
      case postActionTypes.copyPost: {
        dispatch(copyPost(id));
        break;
      }
      case postActionTypes.endChallenge: {
        dispatch(openEndChallengeDialog());
        break;
      }
      case postActionTypes.addToFavourites: {
        dispatch(addPostToFavorites({ id }));
        break;
      }
      case postActionTypes.removeFromBoard: {
        dispatch(openRemoveArticleFromBoardDialog());
        break;
      }
      case postActionTypes.removeFromFavorites: {
        dispatch(openRemoveFavoritePostDialog({ id }));
        break;
      }
      case postActionTypes.removeContent: {
        handleAdminRemoveContent(id);
        break;
      }
      default: {
        console.log(option);
      }
    }
  };

  const postCardRenderer = (post) => {
    return (
      <PostCard
        key={post.id}
        post={post}
        handleActions={handleActions({ post })}
        optionsList={getOptionsList(post)}
      />
    );
  };

  return { postCardRenderer, handleActions, getOptionsList };
}
