import React from "react";
import PropTypes from "prop-types";
// import FileViewer from "react-file-viewer";
import FileIcon from "components/atoms/FileIcon";
import { getFileType } from "utils/files";

import "./AttachmentPreview.scss";
import { defaultStyles } from "react-file-icon";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";
import ReactPlayer from "react-player";

const Content = ({
  attachment: { type, url, description, imageUrl },
  previewOnly,
}) => {
  const fileType = type === "file" ? getFileType(url) : "html";

  // TODO: Fix the display of pdf files inside the app instead of
  //  displaying the preview image and downloading them for full view
  // // PDF
  // if (["pdf", "PDF"].includes(fileType))
  //   return (
  //     <div className={`previewContainer ${previewOnly ? "previewOnly" : ""}`}>
  //       <FileViewer filePath={url} fileType={fileType} onError={console.warn} />
  //     </div>
  //   );

  // IMAGE
  if (["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"].includes(fileType)) {
    return <img src={url} alt="post attachment" className="img" />;
  }

  //Embedded videos
  if (ReactPlayer.canPlay(url) && type === "link") {
    return <VideoPlayer url={url} />;
  }

  //Video files
  if (ReactPlayer.canPlay(url)) {
    if (previewOnly) {
      return <VideoPlayer url={url} autoPlay={false} />;
    }
    return <VideoPlayer url={url} autoPlay={true} />;
  }

  if (imageUrl) {
    return (
      <div className={"link-img-container"}>
        <a href={url} target={"_blank"} rel={"noopener noreferrer"}>
          <img src={imageUrl} alt={"link attachment"} />
          <span>{url}</span>
        </a>

        <span>{description}</span>
      </div>
    );
  }

  // Other file types
  if (["doc", "docx", "ppt", "pptx", "xls", "xlsx"].includes(fileType)) {
    return (
      <a href={url} download={url}>
        <FileIcon extension={fileType} {...defaultStyles[fileType]} />
      </a>
    );
  }

  // LINK or other file type
  if (url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
        <div className="file">
          <FileIcon extension={fileType} {...defaultStyles[fileType]} />
        </div>
      </a>
    );
  }

  return null;
};

const AttachmentPreview = (props) => {
  const {
    previewOnly,
    attachment: { url },
    openFilePreview,
  } = props;

  return (
    <div className="container">
      <Content {...props} />
      {previewOnly && (
        <div
          className={"previewWrapper"}
          onClick={() => openFilePreview(url)}
        />
      )}
    </div>
  );
};

Content.defaultProps = {
  previewOnly: false,
  openFilePreview: () => {},
};

const attachmentPropType = PropTypes.shape({
  type: PropTypes.oneOf(["link", "file"]),
  id: PropTypes.number,
  description: PropTypes.string,
  url: PropTypes.string,
  imageUrl: PropTypes.string,
});

const commonPropTypes = {
  attachment: attachmentPropType,
  previewOnly: PropTypes.bool,
  openFilePreview: PropTypes.func,
};

Content.propTypes = commonPropTypes;

AttachmentPreview.propTypes = commonPropTypes;

export default AttachmentPreview;
