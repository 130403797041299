import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import { blackColor, hexToRgb, title } from "assets/jss/material-kit-pro-react";
import { openDeleteGroupDialog, openEditGroupDialog } from "store/dialogsSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";
import { MoreVert } from "@material-ui/icons";
import { push } from "connected-react-router";
import Paths from "../../../staticData/Paths";
import { authenticationSelectors } from "store/authenticationSlice";

GroupCard.propTypes = {
  group: PropTypes.shape({
    adminId: PropTypes.string,
    adminName: PropTypes.string,
    adminProfilePicture: PropTypes.string,
    groupName: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

const useStyles = makeStyles({
  userAvatar: {
    boxShadow:
      "0 5px 15px -8px rgba(" +
      hexToRgb(blackColor) +
      ", 0.24), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    borderRadius: "50% !important",
    maxWidth: "100%",
    height: "100px",
    width: "100px",
    minWidth: "100px",
    cursor: "pointer",
  },
  title: {
    ...title,
    minHeight: "unset",
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
  imageDropdownButton: {
    padding: "0px",
    borderRadius: "50%",
    marginLeft: "5px",
  },
  superCentered: {
    display: "grid",
    placeItems: "center",
  },
  dropdownIcons: {
    width: "24px",
    height: "24px",
    marginRight: "5px",
    opacity: "0.7",
    marginTop: "-4px",
    verticalAlign: "middle",
    fontSize: "24px",
    position: "relative",
  },
  cardOptions: {
    position: "absolute",
    top: "-10px",
    right: "-10px",
    justifyContent: "flex-end",
    display: "flex",
    flex: "1",
  },
  userContentContainer: {
    display: "grid",
    gridGap: "1rem",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  cardContainer: {
    display: "grid",
    position: "relative",
    gridTemplateColumns: "1fr",
  },
});

function GroupCard({ group: { groupName, id, adminId } }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { userId } = useSelector(authenticationSelectors.userData);

  const userIsGroupAdmin = adminId === userId;

  const handleActions = ({ option, event }) => {
    event.stopPropagation();

    switch (option) {
      case "Edit": {
        dispatch(openEditGroupDialog({ id, groupName }));
        break;
      }
      case "Delete": {
        dispatch(openDeleteGroupDialog({ id }));
        break;
      }
      default: {
        console.log(option);
      }
    }
  };

  const getDropdownList = useCallback(() => {
    return ["Edit", "Delete"];
  }, []);

  function handleNavigationToGroupDetails() {
    dispatch(push(Paths.groupDetails.replace(":id", id)));
  }

  return (
    <Card blog style={{ margin: "0" }}>
      <CardBody>
        <div
          className={classes.cardContainer}
          style={{ cursor: "pointer" }}
          onClick={handleNavigationToGroupDetails}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={classes.superCentered}>
              <h4 className={classes.title}>{groupName}</h4>
            </div>
          </div>

          {userIsGroupAdmin && (
            <div className={classes.cardOptions}>
              <CustomDropdown
                caret={false}
                hoverColor="primary"
                buttonText={<MoreVert />}
                onClick={(option, event) => handleActions({ option, event })}
                dropPlacement={"bottom-end"}
                buttonProps={{
                  className: classes.imageDropdownButton,
                  color: "transparent",
                  justIcon: true,
                }}
                dropdownList={getDropdownList()}
              />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default GroupCard;
